import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col } from 'reactstrap'; // Make sure to include necessary imports

interface FormRadioProps {
  name: string;
  label?: string;
  optionLabel?: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  radioSize?: string; 
  defaultChecked?: boolean;
}

const FormRadio: React.FC<FormRadioProps> = ({
  name,
  label,
  optionLabel,
  value,
  required = false,
  disabled = false,
  className = "",
  radioSize,
  defaultChecked
}) => {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name];

  return (
    <>
      <div className="preview-block">
        <span className="preview-title overline-title">{label}</span>
        <div className={`custom-control custom-radio ${ radioSize=='small'?'custom-control-sm': radioSize=='medium'?'custom-control-md':radioSize=='large'?'custom-control-lg':''}`}>
          <input
            type="radio"
            id={`${name}-${value}`}
            value={value}
            {...register(name, {
              required: required ? 'This selection is required' : undefined
            })}
            disabled={disabled}
            defaultChecked={defaultChecked} 
            className={`custom-control-input ${error ? 'is-invalid' : ''}`}
          />
          <label className="custom-control-label" htmlFor={`${name}-${value}`}>
            {optionLabel}
          </label>
        </div>
        {error && <div className="invalid-feedback">{error.message as string}</div>}
      </div>
      </>
  );
};

export default FormRadio;
