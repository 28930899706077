import { message, Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";

const { Dragger } = Upload;

const FormatCurrency = (
  number: any,
  currencyFormat: any,
  currencySymbol: any = "₹"
) => {
  // Define regex patterns to extract formatting details
  const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
  const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
  let locale = "en-US"; // Default locale
  let symbolPosition = "before"; // Default to symbol before the number
  let minimumFractionDigits = 2; // Default to 2 decimal places
  // Check for symbol position
  let matchBefore = currencyFormat?.match(symbolBeforePattern);
  let matchAfter = currencyFormat?.match(symbolAfterPattern);
  if (matchBefore) {
    symbolPosition = "before";
  } else if (matchAfter) {
    symbolPosition = "after";
  }
  // Check for fraction digits
  let fractionMatch = currencyFormat?.match(/[.,]0*$/);
  if (fractionMatch) {
    minimumFractionDigits = fractionMatch[0].length - 1;
  }
  // Create a new Intl.NumberFormat object
  let formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: minimumFractionDigits,
  });
  // Format the number
  let formattedNumber = formatter.format(number);
  // Add the currency symbol in the correct position
  if (symbolPosition === "before") {
    return currencySymbol + formattedNumber;
  } else {
    return formattedNumber + currencySymbol;
  }
};

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.type || "");
    if (!isAllowedType) {
      message.error(
        "You can only upload PDF, JPG, JPEG, PNG, GIF, DOC, DOCX files!"
      );
      return Upload.LIST_IGNORE;
    }
    if (file.size && file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

const Misc = { FormatCurrency,beforeUpload };

export default Misc;
