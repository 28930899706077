import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Col, Row } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PreviewAltCard
} from "../../components/Component";
import BudgetAside from "./BudgetAside";
import BudgetVsSpendAnalysis from "./analytics/BudgetVsSpendAnalysis";
import MaterialWiseSpendAnalysis from "./analytics/MaterialWiseSpendAnalysis";
import MoreAnalyticsOnBudget from "./analytics/MoreAnalyticsOnBudget";
import { Select, DatePicker, Flex, Spin } from "antd";
import moment, { Moment } from "moment";
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import budgetApis from "../../api/master/budget";
import supplierApis from "../../api/master/supplier";
import Swal from "sweetalert2";

const { Option } = Select;
const { RangePicker } = DatePicker;

const BudgetAnalytics: FC = () => {
  const {
    data: currentUser,
    loading,
    error,
  } = useSelector((state: RootState) => state.currentUser);
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const [site, setSite] = useState("");
  const [siteOptions, setSiteOptions] = useState<any>([]);

  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState<any>([]);

  const [dateRange, setDateRange] = useState("Month to Date");
  const [customDateRange, setCustomDateRange] = useState<any>();

  const [budgetVsSpendChartData, setBudgetVsSpendChartData] = useState<any>([]);
  const [materialWiseSpendAnalysis, setMaterialWiseSpendAnalysis] = useState<any>([]);
  const [materialWiseBudgetOverrunbyCategory, setMaterialWiseBudgetOverrunbyCategory] = useState<any>([]);
  const [HeatMapwithSitewiseOverrun, setHeatMapwithSitewiseOverrun] = useState<any>([]);
  const [loadingCount, setLoadingCount] = useState(3);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const allSites: any = [];
        const _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setSite(allSites.join(","));
        setSiteOptions([
          {
            label: "All Sites",
            value: allSites?.join(","),
          },
          ..._siteOptions,
        ]);
      }
    }
  }, [currentUser]);


  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          console.log("data", item);
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const convertToDates = (dateType: string) => {
    switch (dateType) {
      case "Month to Date":
        return {
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Quarter to Date":
        return {
          from: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Year to Date":
        return {
          from: dayjs().startOf("year").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {
          from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
          to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
        };
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document.getElementsByClassName("nk-header")[0]?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document.getElementsByClassName("nk-header")[0]?.removeEventListener("click", headerClick);
    };
  }, []);

  const handleSiteChange = (value: string) => {
    setSite(value.toString());
  };

  const fetchBudgetvsSpendAnalysis = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        categoryId: category,
        startDate: from,
        endDate: to,
      }
      const res =
        await budgetApis.budgetSpendAnalysis(formData);
      const { response, status, message } = res;
      if (status) {
        setBudgetVsSpendChartData(response);
        setLoadingCount((pre: any) => --pre);
      }
      else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchMaterialWiseSpendAnalysis = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        categoryId: category,
        startDate: from,
        endDate: to,
      }
      const res =
        await budgetApis.materialWiseSpendAnalysis(formData);
      const { response, status } = res;
      if (status) {
        setMaterialWiseSpendAnalysis(response.materialWiseList);
        setLoadingCount((pre: any) => --pre);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const fetchMaterialWiseBudgetOverRun = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        categoryId: category,
        startDate: from,
        endDate: to,
      }
      const res =
        await budgetApis.materialWiseBudgetOverRun(formData);
      const { response, status } = res;
      if (status) {
        setMaterialWiseBudgetOverrunbyCategory(response);
        setLoadingCount((pre: any) => --pre);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null);
    }
  };

  const handleCustomDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([moment(dates[0].toDate()), moment(dates[1].toDate())]);
    } else {
      setCustomDateRange(null);
    }
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
  };



  useEffect(() => {
    // if (site) {
      fetchMaterialWiseSpendAnalysis();
      fetchMaterialWiseBudgetOverRun();
      fetchBudgetvsSpendAnalysis();
    // }
  }, [site, category, dateRange, customDateRange]);

  return (
    <>
      <Head title="Budget Analytics" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <BudgetAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Budget Analytics</BlockTitle>
                    <BlockDes>
                      <p>Integrated Dashboard for Budget and Expense Analysis</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="d-flex align-items-center">
                    <div style={{ marginRight: 16 }}>
                      <label style={{ fontWeight: "bold", marginRight: 8 }}>
                        Filter by Site:
                      </label>
                      <Select
                        defaultValue="ALL"
                        style={{ width: 150 }}
                        onChange={handleSiteChange}
                      >
                        {siteOptions?.map((item: any) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                      </Select>
                    </div>
                    <div style={{ marginRight: 16 }}>
                      <label style={{ fontWeight: "bold", marginRight: 8 }}>
                        Filter by Category:
                      </label>
                      <Select
                        defaultValue="ALL"
                        style={{ width: 150 }}
                        onChange={handleCategoryChange}
                      >
                        {categoryOptions?.map((item: any) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <label style={{ fontWeight: 'bold', marginRight: 8 }}>Filter by Date Range:</label>
                      <Select
                        defaultValue="Month to Date"
                        style={{ width: 200, marginRight: 16 }}
                        onChange={handleDateRangeChange}
                      >
                        <Option value="Month to Date">Month to Date</Option>
                        <Option value="Quarter to Date">Quarter to Date</Option>
                        <Option value="Year to Date">Year to Date</Option>
                        <Option value="Custom Date">Custom Date</Option>
                      </Select>
                      {dateRange === "Custom Date" && (
                        <RangePicker
                          value={
                            customDateRange
                              ? [dayjs(customDateRange[0].toDate()), dayjs(customDateRange[1].toDate())]
                              : null
                          }
                          onChange={(dates, dateStrings) => handleCustomDateChange(dates)}
                          style={{ width: 300 }}
                        />
                      )}
                    </div>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              {loadingCount > 0 ? (
                <>
                  <Flex align="center" gap="middle" className="pageLoader">
                    <Spin size="large" className="m-auto" />
                  </Flex>
                </>
              ) : (
                <Block>
                  <Row className="g-gs">
                    <Col lg='12'>
                      <PreviewAltCard className="card-full" bodyClass={undefined}>
                        <BudgetVsSpendAnalysis budgetVsSpendData={budgetVsSpendChartData} />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                  <Row className="g-gs mt-1">
                    <Col lg='8'>
                      <PreviewAltCard className="card-full" bodyClass={undefined}>
                        <MaterialWiseSpendAnalysis materialWiseSpendAnalysisData={materialWiseSpendAnalysis}></MaterialWiseSpendAnalysis>
                      </PreviewAltCard>
                    </Col>
                    <Col lg='4'>
                      {
                        materialWiseBudgetOverrunbyCategory &&
                        <MoreAnalyticsOnBudget materialWiseBudgetOverrunbyCategoryData={materialWiseBudgetOverrunbyCategory}></MoreAnalyticsOnBudget>
                      }
                    </Col>
                  </Row>
                </Block>
              )}
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default BudgetAnalytics;
