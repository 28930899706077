import React, { FC, useState, useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import { Select, Drawer, Table, Empty } from "antd";
import ReactECharts from "echarts-for-react";
import { formatCurrency, colors } from "../../../../utils/Utils";
import UserLabel from "../../../../utils/UserLabel";
import dayjs from "dayjs";
import { render } from "@testing-library/react";
const { Option } = Select;

interface PriceTrend {
  versionNumber: number;
  price: number;
  updatedDate: string;
}

interface Supplier {
  supplierName: string;
  supplierID: string;
  priceTrends: PriceTrend[];
  initialQuote: string;
  finalQuote: string;
  savings: string;
  creditDays: string;
  creditPercentage: string;
  quotationDate: string;
  currency: string;
}

interface FormattedData {
  version: string;
  price: number;
  updatedDate: string;
}

const QuotationComparisonLine: React.FC<{ graphData: Supplier[] }> = ({
  graphData,
}) => {
  console.log("graphDATA::::", graphData);
  const [data, setData] = useState<any>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<any>();
  const [formattedData, setFormattedData] = useState<FormattedData[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [supplierColorMap, setSupplierColorMap] = useState<any>({})
  // const data = graphData;

  useEffect(() => {
    setData(graphData)
    const map: { [key: string]: string } = {};
    let colorIndex = 0;

    graphData?.forEach((record: any) => {
      if (!map[record.supplierName]) {
        map[record.supplierName] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });
    setSupplierColorMap(map)
    setSelectedSupplier(graphData[0]?.supplierID)
  }, [graphData])
  // const data  = [
  //   {
  //       "supplierQuoteId": 23,
  //       "supplierID": 40,
  //       "supplierName": "UltraTech Cement TMT",
  //       "paymentTerms": "CREDIT",
  //       "creditDays": 10,
  //       "creditPercentage": "15.00",
  //       "initialGrossQuote": "120.50",
  //       "initialQuote": "100.50",
  //       "finalGrossQuote": "120.50",
  //       "finalQuote": "1350.50",
  //       "savings": -1250,
  //       "status": "QUOTED",
  //       "validityDate": "2024-07-29T07:32:53.000Z",
  //       "negotiable": "Y",
  //       "quotationDate": "2024-08-05T10:46:47.000Z",
  //       "priceTrends": [
  //           {
  //               "versionNumber": 1,
  //               "price": 100.5,
  //               "updatedDate": "2024-08-05T10:46:47.000Z"
  //           },
  //           {
  //               "versionNumber": 2,
  //               "price": 1350.5,
  //               "updatedDate": "2024-08-05T10:46:47.000Z"
  //           }
  //       ]
  //   },
  //   {
  //       "supplierQuoteId": 25,
  //       "supplierID": 41,
  //       "supplierName": "GoldMedal ultra",
  //       "paymentTerms": "CREDIT",
  //       "creditDays": 30,
  //       "creditPercentage": "20.00",
  //       "initialGrossQuote": "5940.00",
  //       "initialQuote": "5000.00",
  //       "finalGrossQuote": "5940.00",
  //       "finalQuote": "5000.00",
  //       "savings": 0,
  //       "status": "QUOTED",
  //       "validityDate": "2024-08-08T00:00:00.000Z",
  //       "negotiable": "Y",
  //       "quotationDate": "2024-08-09T16:02:52.000Z",
  //       "priceTrends": [
  //           {
  //               "versionNumber": 1,
  //               "price": 5000,
  //               "updatedDate": "2024-08-09T16:02:52.000Z"
  //           }
  //       ]
  //   }
  // ];



  // Memoized supplier-color map to ensure the same color for the same supplier
  // const supplierColorMap = React.useMemo(() => {
  //   const map: { [key: string]: string } = {};
  //   let colorIndex = 0;

  //   data?.forEach((record:any) => {
  //     if (!map[record.supplierName]) {
  //       map[record.supplierName] = colors[colorIndex % colors.length];
  //       colorIndex++;
  //     }
  //   });

  //   return map;
  // }, [data]);

  useEffect(() => {
    const selectedData = data?.find(
      (supplier: any) => supplier.supplierID === selectedSupplier
    );
    const formatted =
      selectedData?.priceTrends.map((trend: any) => ({
        version: `Version ${trend.versionNumber}`,
        price: trend.price,
        updatedDate: trend.updatedDate,
      })) || [];
    setFormattedData(formatted);
  }, [selectedSupplier]);

  const handleSupplierChange = (value: any) => {
    setSelectedSupplier(value);
  };

  const formatNumber = (value: number) => {
    if (value >= 10000000) return `${(value / 100000).toFixed(0)}L`;
    if (value >= 100000) return `${(value / 100000).toFixed(0)}L`;
    if (value >= 1000) return `${(value / 1000).toFixed(0)}K`;
    return value.toString();
  };

  const getSupplierLabel = (name: string) => {
    // const words = name?.split(" ");
    // if (words?.length === 1) {
    //   return words[0].substring(0, 2).toUpperCase();
    // }
    // return (words[0][0] + words[1][0]).toUpperCase();
    if (!name) return ""; // Handle empty or undefined names

    const words = name.trim().split(" ");

    if (words.length === 1) {
      return words[0]?.substring(0, 2).toUpperCase();
    } else if (words.length > 1) {
      return (words[0][0] + words[1][0]).toUpperCase();
    } else {
      return ""; // Handle any unexpected case
    }
  };

  const selectedData = data.find(
    (supplier: any) => supplier.supplierID === selectedSupplier
  );
  const yMax = Math.max(...formattedData.map((d) => d.price));
  const yMin = Math.min(...formattedData.map((d) => d.price)) * 1.25;

  const chartOptions = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      left: "0%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: formattedData.map((d) => d.version),
    },
    yAxis: {
      type: "value",
      // min: yMin/2,
      max: yMax * 1.5,
      axisLabel: {
        formatter: (value: number) => formatNumber(value),
      },
    },
    series: [
      {
        name: selectedData?.supplierName || "",
        type: "line",
        stack: "Total",
        smooth: true,
        data: formattedData.map((d) => d.price),
      },
    ],
  };

  const columns = [
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      render: (text: string, record: any, index: number) => (
        <UserLabel
          supplierName={record.supplierName}
          color={supplierColorMap[record.supplierName]}
          text={text}
        />
      ),
    },
    {
      title: "Initial Quote",
      dataIndex: "initialQuote",
      key: "initialQuote",
      render: (text: any, record: any) => (
        <>{formatCurrency(text, 'INR')}</>
      ),
    },
    {
      title: "Final Quote",
      dataIndex: "finalQuote",
      key: "finalQuote",
      render: (text: any, record: any) => (
        <>{formatCurrency(text, 'INR')}</>
      ),
    },
    {
      title: "Savings",
      dataIndex: "savings",
      key: "savings",
      render: (text: any, record: any) => (
        <>{formatCurrency(text, 'INR')}</>
      ),
    },
    {
      title: "Credit Days",
      dataIndex: "creditDays",
      key: "creditDays",
      render: (text: any) => text || 'NA'
    },
    // {
    //   title: "Credit Percentage",
    //   dataIndex: "creditPercentage",
    //   key: "creditPercentage",
    //   render: (text: any, record: any) => (
    //     <>{text} %</>
    //   ),
    // },
    {
      title: "Quotation Date",
      dataIndex: "quotationDate",
      key: "quotationDate",
      render: (text: any, record: any) => (
        <>{dayjs(text).format("DD-MMM-YYYY")}</>
      ),
    },
  ];

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <div>
      {data.length === 0 ? (
        // <div
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     height: "100%",
        //     fontSize: "20px",
        //   }}
        // >
        //   There are no quotations received for this RFQ ID.
        // </div>
        <>
          <h6 style={{ flex: 1 }} className="mb-2">
            Best Savings
          </h6>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>

            <Empty description="There are no quotations received for this RFQ ID." />
          </div>
        </>

      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <h6 style={{ flex: 1 }} className="mb-2">
              Best Savings
            </h6>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {data?.slice(0, 4)?.map((supplier: any, index: any) => (
                  <div
                    key={supplier.supplierID}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: colors[index % colors.length],
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "12px",
                      marginLeft: index > 0 ? "-10px" : "0",
                      zIndex: data.length - index,
                    }}
                  >
                    {getSupplierLabel(supplier.supplierName)}
                  </div>
                ))}
                {data.length > 4 && (
                  <div style={{ marginLeft: "10px", fontSize: "14px" }}>
                    +{data.length - 4} Suppliers
                  </div>
                )}
              </div>
            </div>
            <div>
              <span
                onClick={showDrawer}
                style={{ marginLeft: "20px", marginTop: 5, cursor: "pointer" }}
              >
                View More Details
              </span>
            </div>
          </div>
          {selectedData && (
            <div
              className="analytic-data-group analytic-ov-group g-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                zIndex: 1000,
              }}
            >
              <div>
                <span>
                  <strong>Filter by Supplier:</strong>{" "}
                </span>
                <Select
                  defaultValue={data[0]?.supplierID}
                  style={{ width: 200, marginBottom: "20px" }}
                  onChange={handleSupplierChange}
                >
                  {data?.map((supplier: any) => (
                    <Option
                      key={supplier.supplierID}
                      value={supplier.supplierID}
                    >
                      {supplier.supplierName}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="analytic-data analytic-ov-data">
                <div className="title">Initial Quoted Price</div>
                <div className="amount">
                  {" "}
                  ₹{formatNumber(selectedData.priceTrends[0]?.price)}
                </div>
              </div>
              <div className="analytic-data analytic-ov-data">
                <div className="title">Savings</div>
                <div className="amount">
                  ₹
                  {formatNumber(
                    selectedData.priceTrends[0]?.price -
                    selectedData.priceTrends[
                      selectedData.priceTrends.length - 1
                    ]?.price
                  )}
                </div>
              </div>
            </div>
          )}
          <div>
            <div id="chart-container">
              <ReactECharts option={chartOptions} />
            </div>
          </div>
          <Drawer
            title="Supplier Details"
            width={"70%"}
            onClose={closeDrawer}
            open={drawerVisible}
          >
            <Table columns={columns} dataSource={data} rowKey="supplierID" />
          </Drawer>
        </>
      )}
    </div>
  );
};

export default QuotationComparisonLine;
