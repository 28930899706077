import React from "react";
import ErrorImage from "../../images/gfx/error-404.svg";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../components/Component";

const EmailError = () => {
    return (
        <>
            <Block className="nk-block-middle wide-md mx-auto">
                <BlockContent className="nk-error-ld text-center">
                    {/* <img className="nk-error-gfx" src={ErrorImage} alt="error" /> */}
                    <div className="wide-xs mx-auto">
                        <h3 className="nk-error-title">Your email has already been verified.</h3>
                        <p className="nk-error-text">
                            Please return to the login page to access the ProcureX platform. If you need assistance, don't hesitate to contact our support team.
                        </p>
                        <Link to={`/`}>
                            <Button color="primary" size="lg" className="mt-2">
                                Back To Login
                            </Button>
                        </Link>
                    </div>
                </BlockContent>
            </Block>
        </>
    );
};
export default EmailError;
