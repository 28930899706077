import React, { useState, useEffect } from "react";
import { Drawer, Tabs, Select, Empty, Card, Table, Tooltip } from "antd";
const { TabPane } = Tabs;

const TopPurchaseCategoriesDetailsDrawer: React.FC<any> = ({
  selectedSite,
  visible,
  siteOptions,
  onClose,
}) => {
  return (
    <>
      <Drawer
        title="Purchase Details"
        placement="right"
        onClose={onClose}
        open={visible}
        width={"75%"}
      >
        <Tabs defaultActiveKey="plot">
          <TabPane tab={<span>Purchases By Supplier</span>} key="plot">
            <div>Purchases By Supplier</div>
          </TabPane>
          <TabPane tab={<span>Price Comparision</span>} key="table">
            <div>Price Comparision</div>
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default TopPurchaseCategoriesDetailsDrawer;
