import makeApiCall from "./axiosIndex";
const adminOrgApis = {
  fetchAdminOrg: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/admin/fetchAdminOrg",
    }),
  fetchBuyerOrg: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchOrg",
    }),
  fetchAdminMaterial: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/admin/fetchAdminMaterial",
    }),
  fetchAdminMaterialCategory: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/admin/fetchMaterialCategory",
    }),
  fetchMaterialCategoryById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/admin/fetchMaterialCategoryById",
      params: { id },
    }),
  fetchUom: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/admin/fetchUom",
    }),
};

export default adminOrgApis;
