import React, { useEffect, useState } from "react";
import { Drawer, Alert, Space } from "antd";
import { useForm, Controller } from "react-hook-form";
import { InputNumber } from "antd";
import { Card, Row, Col, Button } from "reactstrap";
import quotationApis from "../../../../api/master/quotations";
interface FormValues {
  materialCoverage: number;
  volumeCoverage: number;
  longCreditDays: number;
  credit30Days: number;
  onTimeDelivery: number;
  quotationValue: number;
  delayPercentage: number;
}

interface ScenarioBuilderDrawerProps {
  scenarioBuilderData: any;
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const ScenarioBuilderDrawer: React.FC<ScenarioBuilderDrawerProps> = ({
  scenarioBuilderData,
  visible,
  onClose,
  onSubmit,
}) => {
  // console.log("10",scenarioBuilderData);
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues: {
      materialCoverage: 0,
      volumeCoverage: 0,
      longCreditDays: 0,
      credit30Days: 0,
      onTimeDelivery: 0,
      quotationValue: 0,
      delayPercentage: 0,
    },
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onFinish = (data: FormValues) => {
    let totalData: any = JSON.parse(JSON.stringify(data));
    delete totalData?.credit30Days;
    delete totalData?.delayPercentage;
    // return
    const total = Object.values(totalData)
      .map(Number)
      .reduce((acc: number, value: number) => acc + value, 0);
    if (total !== 100) {
      setErrorMessage("The total weightage must be exactly 100%.");
    } else {
      setErrorMessage(null);
      onSubmit(data);
      onClose();
      reset();
    }
  };
  const handleReset = () => {
    reset();
    setErrorMessage(null);
  };

  useEffect(() => {
    // console.log("scenarioBuilderData111",scenarioBuilderData)
    if (scenarioBuilderData) {
      console.log("scenarioBuilderData", scenarioBuilderData);
      setValue("materialCoverage", scenarioBuilderData.materialCoverage);
      setValue("volumeCoverage", scenarioBuilderData.volumeCoverage);
      setValue("longCreditDays", scenarioBuilderData.creditCoverage);
      setValue("credit30Days", scenarioBuilderData.credit30Days);
      setValue("onTimeDelivery", scenarioBuilderData.onTimeDeliveryCoverage);
      setValue("quotationValue", scenarioBuilderData.quotationValueCoverage);
      setValue("delayPercentage", scenarioBuilderData.delayPercentage);
    }
  }, [scenarioBuilderData]);

  const getScenarioBuilder = async () => { };

  return (
    <Drawer
      title="Scenario Builder"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={700}
    >
      <form onSubmit={handleSubmit(onFinish)}>
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        <div className="form-item">
          <label>Material Coverage (%)</label>
          <Controller
            name="materialCoverage"
            control={control}
            render={({ field }) => (
              <InputNumber {...field} className="input-box" min={0} max={100} />
            )}
          />
        </div>

        <div className="form-item">
          <label>Volume Coverage (%)</label>
          <Controller
            name="volumeCoverage"
            control={control}
            render={({ field }) => (
              <InputNumber {...field} className="input-box" min={0} max={100} />
            )}
          />
        </div>
        <Row>
          <Col>
            <div className="form-item">
              <label>On Time Delivery (%)</label>
              <Controller
                name="onTimeDelivery"
                control={control}
                render={({ field }) => (
                  <InputNumber {...field} className="input-box" min={0} max={100} />
                )}
              />
            </div>
          </Col>

          <Col>
            <div className="form-item">
              <label>Delivery Delay Percentage (%)</label>
              <Controller
                name="delayPercentage"
                control={control}
                render={({ field }) => (
                  <InputNumber {...field} className="input-box" min={0} max={100} />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-item">
              <label>Long Credit Days (%)</label>
              <Controller
                name="longCreditDays"
                control={control}
                render={({ field }) => (
                  <InputNumber {...field} className="input-box" min={0} max={100} />
                )}
              />
            </div>
          </Col>

          <Col>
            <div className="form-item">
              <label>Credit % For 30 Days (Willing to Pay) (%)</label>
              <Controller
                name="credit30Days"
                control={control}
                render={({ field }) => (
                  <InputNumber {...field} className="input-box" min={0} max={100} />
                )}
              />
            </div>
          </Col>
        </Row>



        <div className="form-item">
          <label>Quotation Value (%)</label>
          <Controller
            name="quotationValue"
            control={control}
            render={({ field }) => (
              <InputNumber {...field} className="input-box" min={0} max={100} />
            )}
          />
        </div>
        {/* <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button type="default" onClick={handleReset}>
                        Reset
                    </Button>
                </div> */}

        <Space>
          <Button color="primary">Submit</Button>
          <Button
            color="secondary"
            onClick={handleReset}
            className="btn-dim btn btn-secondary"
          >
            Reset
          </Button>
        </Space>
      </form>
    </Drawer>
  );
};

export default ScenarioBuilderDrawer;
