import React, { useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Col,
    RSelect,
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    BlockBetween,
    Button,
    Icon
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import BillingandPaymentTerms from "./BillingandPaymentTerms";
import ComplianceandCertifications from "./ComplianceandCertifications";
import ConflictResolution from "./ConflictResolution";
import ContractualAgreements from "./ContractualAgreements";
import EmergencyResponseandContingencyPlans from "./EmergencyResponseandContingencyPlans";
import EnvironmentalandSustainabilityConsiderations from "./EnvironmentalandSustainabilityConsiderations";
import ScopeofWork from "./ScopeofWork";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import ConsultantInformation from "./ConsultantInformation";
import ConsultantProfile from "./ConsultantProfile";
import ProjectExperience from "./ProjectExperience";
import ProjectAccessCoordination from "./ProjectAccessCoordination";

const config = {
    before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const AddConsultants: React.FC = (props: any) => {
    const navigate = useNavigate();
    const formMethods = useForm({
        mode: "onChange"
    });
    useEffect(() => {
    }, []);

    const backList = () => {
        navigate(`/consultants`);

    }

    return (

        <React.Fragment>
            <Head title="Regular Page v-1" />
            <Content>
               
                <BlockHead size="lg">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4">Create Consultants</BlockTitle>
                            <BlockDes>Onboard Consultants platform, collecting essential information to create Consultants profiles.</BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button color="primary" className="btn-dim" onClick={() => backList()}>
                                <Icon name="menu-alt-r"></Icon>
                                <span>List of Consultants</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <PreviewCard className={'cardBox'} bodyClass="card-inner-xl">
                        {/* <div className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix"> */}
                            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix"> 
                            <FormProvider {...formMethods}>
                                <Steps config={config}>
                                    <Step component={ConsultantInformation} />
                                    {/* <Step component={ConsultantProfile} /> */}
                                    <Step component={ComplianceandCertifications} />
                                    {/* <Step component={ScopeofWork} /> */}
                                    {/* <Step component={ProjectExperience}/> */}
                                    {/* <Step component={BillingandPaymentTerms} /> */}
                                    <Step component={ContractualAgreements} />
                                    {/* <Step component={ProjectAccessCoordination} /> */}
                                    <Step component={EnvironmentalandSustainabilityConsiderations} />
                                    {/* <Step component={EmergencyResponseandContingencyPlans} /> */}
                                    {/* <Step component={ConflictResolution} /> */}
                                </Steps>
                            </FormProvider>
                        </div>
                    </PreviewCard>
                </Block>
            </Content>
        </React.Fragment>
    );
};
export default AddConsultants;
