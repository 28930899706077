import React from "react";
import { useFormContext } from "react-hook-form";
import { Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

interface InputSwitchProps {
  label?: string;
  optionLabel?: string;
  name: string;
  defaultChecked?: boolean;
  required?: boolean;
  tooltipText?: string;
  customValidation?: { validate: (value: boolean) => boolean | string };
}

const FormInputSwitch: React.FC<InputSwitchProps> = ({
  label,
  name,
  optionLabel,
  defaultChecked = false,
  required = false,
  tooltipText,
  customValidation,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const fieldValue = watch(name, defaultChecked);

  // Register the switch input to react-hook-form
  React.useEffect(() => {
    register(name, {
      required: required ? "This field is required" : undefined,
      validate: customValidation ? customValidation.validate : undefined,
    });
  }, [register, name, required, customValidation]);

  const handleChange = (checked: boolean) => {
    setValue(name, checked);
  };

  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <div className="form-group">
      <div className="form-label-wrapper">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
            {required && <span className="mandatory">*</span>}
          </label>
        )}
      </div>
      <div className="form-control-wrap">
        <Switch id={name} checked={fieldValue} onChange={handleChange} />
        &nbsp; &nbsp; 
        {optionLabel && (
          <label className="form-label" htmlFor={name}>
            {optionLabel}
          </label>
        )}
      </div>
      {errorMessage && <p className="mandatory">{errorMessage}</p>}
    </div>
  );
};

export default FormInputSwitch;
