import React, { useState } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { useFormContext } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import sites from "../../../api/master/sites";
import FormatDate from "../DateFormater";
import moment from "moment";
import dayjs from "dayjs";

interface SelectOptions {
  label: string;
  value: string;
}
const BlocksInformation: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "Blocks",
  });

  const submitForm = async(data: any) => {
    const siteFormData:any=new FormData();
    if(data?.siteDocuments[0]){
      siteFormData.append("files", data?.siteDocuments[0]);
      siteFormData.append("filesData", JSON.stringify([{
            "indexOfFileLocated": 0,
            "module": "site",
            "purpose": "Site Document",
            "level": "",
            "uniqueId": "",
            "action": "Create"
        }]));
    }
    else{
      siteFormData.append("files", JSON.stringify([]));
      siteFormData.append("filesData", JSON.stringify([]));
    }
    delete data.siteDocuments;
    delete data.siteAddress;
    console.log("submit::DATA",data);
    console.log("dayJS",dayjs(data.plannedStartDate).format("YYYY-MM-DD"));
    data.plannedStartDate = dayjs(data.plannedStartDate).format("YYYY-MM-DD");
    data.plannedEndDate = dayjs(data.plannedEndDate).format("YYYY-MM-DD");
    data.Blocks = data.Blocks.map((block: any) => ({
      ...block,
      startDate: dayjs(block.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(block.endDate).format("YYYY-MM-DD"),
    }));
    siteFormData.append("siteData", JSON.stringify(data));
    const response = await sites.createSite(siteFormData);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Sites has been created", "success");
      navigate(`/sites`);
    } else {
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };
  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          {fields.map((field, index) => (
            <div key={field.id} className="row mb-3">
              <div className="col">
                <FormInput
                  name={`Blocks[${index}].blockName`}
                  label="Block/Tower"
                  required={true}
                  type="text"
                  placeholder="Block/Tower"
                />
              </div>
              <div className="col">
                <FormInput
                  name={`Blocks[${index}].squareFeet`}
                  label="Area (in Sqft)"
                  required={true}
                  type="number"
                  placeholder="Area (in Sq ft)"
                  // pattern={/^\d*/}
                  // onInput={(e: any) => {
                  //   e.preventDefault(); // Prevent default behavior
                  //   e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  // }}
                />
              </div>
              <div className="col">
                <FormDatePicker
                  name={`Blocks[${index}].startDate`}
                  label="Start Date"
                  placeholder="Select Date"
                  required={true}
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                />
              </div>
              <div className="col">
                <FormDatePicker
                  name={`Blocks[${index}].endDate`}
                  label="Planned End Date"
                  required={true}
                  placeholder="Select Date"
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  customValidation={{
                    validate: (selectedDate) => {
                      const startDate = watch(
                        `Blocks[${index}].startDate` as any
                      );
                      return (
                        new Date(selectedDate) > new Date(startDate) ||
                        "End date must be greater than Start Date"
                      );
                    },
                  }}
                />
              </div>
              <div className="col">
                <FormSelect
                  name={`Blocks[${index}].currentStatus`}
                  label="Current Status"
                  required={true}
                  options={[
                    { label: "Select", value: "" },
                    { label: "Planned", value: "Planned" },
                    { label: "In Progress", value: "In Progress" },
                    { label: "On Hold", value: "On Hold" },
                    { label: "Completed", value: "Completed" },
                  ]}
                  placeholder="Select"
                />
              </div>
              <div className="col-md-2">
                <div style={{ marginTop: 30 }}>
                  <button
                    type="button"
                    className="btn btn-success ms-2"
                    onClick={() =>
                      append({
                        currentStatus: "",
                        squareFeet: "",
                        startDate: null,
                        endDate: null,
                        blockName: "",
                      })
                    }
                  >
                    +
                  </button>
                  &nbsp;
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => remove(index)}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default BlocksInformation;
