import makeApiCall from "./axiosIndex";

const costCenterApis= {
    fetchCostCenter: (): Promise<any> => makeApiCall<any>({ method: 'get', url: '/buyer/fetchCostCenter' }),
    deleteCostCenterById: (CostCenterId: string): Promise<any> => makeApiCall<any>({
        method: 'delete',
        url: `/buyer/deleteCostCenterById`,
        params: { id: CostCenterId }
    }),
    createCostCenter: (data: any): Promise<any> => makeApiCall<any>({
        method: 'post',
        url: '/buyer/createCostCenter',
        data
    }),
    updateCostCenterById: (id: any, data: any): Promise<any> => makeApiCall<any>({
        method: 'put',
        url: `/buyer/updateCostCenterById`,
        params: { id: id },
        data
    }),
    fetchCostCenterById: (id: any): Promise<any> => makeApiCall<any>({
        method: 'get',
        url: `/buyer/fetchCostCenterById`,
        params: { id }
    })
};

export default costCenterApis;