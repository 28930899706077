import React, { useState, useEffect } from "react";
import {
  useFormContext,
  FormProvider,
  Controller,
} from "react-hook-form";
import { Button } from "reactstrap";
import { Collapse, Upload, message } from "antd";
import {
  InboxOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { UploadFile } from "antd/es/upload/interface";
import { useFormData } from "./FormDataContext";
import FormTextArea from "../../form-components/TextAreaComponent";

const { Panel } = Collapse;
const { Dragger } = Upload;

const BudgetOthers: React.FC<{ prev: () => void; next: () => void }> = (
  props: any
) => {
  const methods = useFormContext();
  const { setValue, watch } = methods;

  const { updateFormData } = useFormData(); // Use the context to access formData and updateFormData

  let formData: any = {
    attachments: [],
  };

  // let formData: any = {
  //   attachments: [],
  // };

  const [fileList, setFileList] = useState<UploadFile[]>(
    formData.attachments || []
  );
  const attachmentsWatch = watch("attachments");
  useEffect(() => {
    console.log('Previous Data:', formData);
    console.log("attachmentsWatch", attachmentsWatch);
    if (attachmentsWatch) {
      setFileList(attachmentsWatch);
    }
  }, [attachmentsWatch]);

  const submitForm = (data: any) => {
    console.log("data", data)
    // updateFormData("budgetHeader",data)
    updateFormData("termsAndConditions", data.termsAndConditions)
    updateFormData("instructions", data.instructions)
    updateFormData("docKeys", { fileList })
    console.log("Final Submission Data:", formData);
    setValue("attachments", fileList);
    props.next();
  };

  const beforeUpload = (file: UploadFile) => {

    const isAllowedType = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.type || "");
    if (!isAllowedType) {
      message.error(
        "You can only upload PDF, JPG, JPEG, PNG, GIF, DOC, DOCX files!"
      );
      return Upload.LIST_IGNORE;
    }
    if (file.size && file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = (info: { fileList: UploadFile[] }) => {
    setFileList(info.fileList);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="content clearfix"
        onSubmit={methods.handleSubmit(submitForm)}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Instructions (if any)" key="1">
            <FormTextArea
              name="instructions"
              label={undefined}
              placeholder="Enter Instructions"
            />
            {/* <Controller
              name="instructions"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <ReactQuill
                  theme="snow"
                  value={field.value}
                  onChange={field.onChange}
                  className="mb-3"
                />
              )}
            /> */}
          </Panel>
          <Panel header="Terms and Conditions (if any)" key="2">
            <FormTextArea
              name="termsAndConditions"
              label={undefined}
              placeholder="Enter Terms and Conditions"
            />
            {/* <Controller
              name="termsAndConditions"
              control={methods.control}
              defaultValue=""
              render={({ field }) => (
                <ReactQuill
                  theme="snow"
                  value={field.value}
                  onChange={field.onChange}
                  className="mb-3"
                />
              )}
            /> */}
          </Panel>
          <Panel header="Attachments (if any)" key="3">
            <Controller
              name="attachments"
              control={methods.control}
              render={({ field }) => (
                <Dragger
                  beforeUpload={beforeUpload}
                  fileList={fileList}
                  onChange={handleChange}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    {" "}
                    Drag 'n' drop required files here, or click to required
                    files{" "}
                  </p>
                </Dragger>
              )}
            />
          </Panel>
        </Collapse>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </FormProvider>
  );
};

export default React.memo(BudgetOthers);
