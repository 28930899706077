import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Alert, Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
import FormCheckbox from "../../form-components/CheckboxComponent";
import consultantsApis from "../../../api/master/consultants";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const EnvironmentalandSustainabilityConsiderations: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    console.log("Final form data:", data);
    createConsultant(data);
    // Add your API submission logic here
  };

  const createConsultant = async (data: any) => {
    const { status, message } = await consultantsApis.createConsultant(data);
    if (status) {
      Swal.fire("Created!", "Contractor has been created", "success");
      navigate(`/consultants`);
    } else {
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  useEffect(() => {
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  }, []);
  
  return (
    <>
     {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Environmental and Safety Considerations</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormCheckbox
            name="environmentalCompliance"
            optionLabel="Environmental Compliance"
            size={"small"}
          />
        </Col>
        <div className="col-md-6">
          <FormCheckbox
            name="safetyProtocols"
            optionLabel="Safety Protocols"
            size={"small"}
          />
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="docsUpload2"
            label="Docs Upload"
            // disabled={action === "view"}
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Emergency Response and Contingency Plans</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="emergencyContactPerson"
            label="Contact Person"
            type="text"
            placeholder="Enter Contact Person"
            // disabled={action === "view"}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="emergencyContactNumber"
            // disabled={action === "view"}
            label="Emergency Contact Number"
            type="text"
            placeholder="Enter Emergency Contact Information"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="emergencyProcedures"
            label="Emergency Procedures"
            // disabled={action === "view"}
            type="textarea"
            placeholder="Enter Emergency Procedures"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="docsUpload3"
            label="Docs Upload"
            // disabled={action === "view"}
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Conflict Resolution</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="disputeResolutionProcess"
            // disabled={action === "view"}
            label="Dispute Resolution Process"
            type="textarea"
            placeholder="Enter Dispute Resolution Process"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="docsUpload5"
            // disabled={action === "view"}
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Submit Form
            </Button>
          </li>
          <li>
            <Button
              color="primary"
              onClick={props.prev}
              className="btn-dim btn btn-secondary"
            >
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
    </>
  );
};

export default React.memo(EnvironmentalandSustainabilityConsiderations);
