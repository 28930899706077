import makeApiCall from "../master/axiosIndex";

const mainDashboardApis = {
    topWidgetsResponse: (data: any): Promise<any> => {
        return makeApiCall<any>({
            url: "/main-dashboard-buyer/topWidgetsResponse",
            method: "post",
            data: data,
        })
    },
    topSupplierSavings: (data: any): Promise<any> => {
        return makeApiCall<any>({
            url: "/main-dashboard-buyer/topSupplierSavings",
            method: "post",
            data: data,
        })
    },
    budgetOverRunByMaterial: (data: any): Promise<any> => {
        return makeApiCall<any>({
            url: "/main-dashboard-buyer/budgetOverRunByMaterial",
            method: "post",
            data: data,
        })
    },
    topPurchaseCategories: (data: any): Promise<any> => {
        return makeApiCall<any>({
            url: "/main-dashboard-buyer/topPurchaseCategories",
            method: "post",
            data: data,
        })
    },
    purchaseRequestSummary: (data: any): Promise<any> => {
        return makeApiCall<any>({
            url: "/main-dashboard-buyer/purchaseRequestSummary",
            method: "post",
            data: data,
        })
    },
    procureToPayCycle: (data: any): Promise<any> => {
        return makeApiCall<any>({
            url: "/main-dashboard-buyer/procureToPayCycle",
            method: "post",
            data: data,
        })
    },
}

export default mainDashboardApis;
