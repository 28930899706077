import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
const ContractualAgreements: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };
  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Contractual Agreements</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="contractTerms"
            label="Contract Terms"
            type="textarea"
            placeholder="Enter Contract Terms"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="insuranceRequirements"
            label="Insurance Requirements"
            type="textarea"
            placeholder="Enter Insurance Requirements"
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="docsUpload"
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Billing and Payment Terms</h6>
        </div>
        <div className="col-md-4">
          <FormInput
            name="paymentTerms"
            label="Payment Terms"
            type="text"
            required={true}
            placeholder="Enter Payment Terms"
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="billingRates"
            label="Billing Rates"
            type="text"
            placeholder="Enter Billing Rates"
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="docsUpload"
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </div>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Project Access & Coordination</h6>
        </div>
        <div className="col-md-6">
          <FormInput
            name="siteAccessProcedures"
            label="Site Access Procedures"
            type="textarea"
            placeholder="Site Access Procedures"
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="coordinationWithOtherContractors"
            label="Coordination with Other Contractors"
            type="textarea"
            placeholder="Coordination with Other Contractors"
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="contractDocs2"
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </div>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(ContractualAgreements);
