import React, { createContext, useContext, useState, ReactNode } from "react";
import { AddressDetails, ApprovalData, DocumentFormValues } from './types';

interface FormData {
  budgetHeader?: any;
  budgetApprovals?: any;
  supplierDocs?: any;
  materialsData?: any;
  additionalData?: any;
  attachments?: any;
  publishToPrx?: string;
  [key: string]: any;
}

interface FormState {
  isApprovalDrawerOpen: boolean;
  isAskDocumentsDrawerOpen: boolean;
  approvalPopoverVisible: boolean;
  documentsPopoverVisible: boolean;
  selectedBillingAddress: AddressDetails | null;
  selectedDeliveryAddress: AddressDetails | null;
  deliveryAddressVisibility: boolean;
  approvalSubmissionStatus: boolean;
  approvalData: ApprovalData[] | null;
  documentData: DocumentFormValues | null;
  showApprovals: boolean;
  showAskDocs: boolean;
}

interface FormDataContextType {
  formData: FormData;
  formState: FormState;
  updateFormData: (key: string, value: any) => void;
  updateFormState: (newState: Partial<FormState>) => void;
}

const FormDataContext = createContext<FormDataContextType | undefined>(undefined);

export const useFormData = (): FormDataContextType => {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error("useFormData must be used within a FormDataProvider");
  }
  return context;
};

interface FormDataProviderProps {
  children: ReactNode;
}

export const FormDataProvider: React.FC<FormDataProviderProps> = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({});
  const [formState, setFormState] = useState<FormState>({
    isApprovalDrawerOpen: false,
    isAskDocumentsDrawerOpen: false,
    approvalPopoverVisible: false,
    documentsPopoverVisible: false,
    selectedBillingAddress: null,
    selectedDeliveryAddress: null,
    deliveryAddressVisibility: false,
    approvalSubmissionStatus: false,
    approvalData: null,
    documentData: null,
    showApprovals: false,
    showAskDocs: false,
  });

  const updateFormData = (key: string, value: any) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const updateFormState = (newState: Partial<FormState>) => {
    setFormState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <FormDataContext.Provider value={{ formData, formState, updateFormData, updateFormState }}>
      {children}
    </FormDataContext.Provider>
  );
};
