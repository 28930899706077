import React, { useState } from 'react';
import { Card, List, Typography } from 'antd';
import { PieChartOutlined, HeatMapOutlined } from '@ant-design/icons';
import MaterialWiseBudgetOverrunDrawer from './MaterialWiseBudgetOverrunDrawer';
import HeatMapSiteWiseOverrunDrawer from './HeatMapSiteWiseOverrunDrawer';

const { Text, Link } = Typography;

const MoreAnalyticsOnBudget: React.FC<any> = (materialWiseBudgetOverrunbyCategoryData) => {
    const [materialDrawerVisible, setMaterialDrawerVisible] = useState<boolean>(false);
    const [heatMapDrawerVisible, setHeatMapDrawerVisible] = useState<boolean>(false);

    const openMaterialDrawer = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setMaterialDrawerVisible(true);
    };

    const closeMaterialDrawer = () => {
        setMaterialDrawerVisible(false);
    };

    const openHeatMapDrawer = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setHeatMapDrawerVisible(true);
    };

    const closeHeatMapDrawer = () => {
        setHeatMapDrawerVisible(false);
    };

    const analyticsItems = [
        {
            title: 'Material Wise Budget Overrun by Category',
            description: [
                'Analyze the budget overrun on a per-category basis for different materials.',
                'This helps in identifying areas with significant overspend.'
            ],
            icon: <PieChartOutlined style={{ fontSize: '24px', marginRight: 16, color: '#ffffff' }} />,
            onClick: openMaterialDrawer,
        },
        {
            title: 'Heat Map with Site-wise Overrun',
            description: [
                'Visualize site-wise budget overruns across all projects.',
                'Identify critical areas with high overruns for corrective action.'
            ],
            icon: <HeatMapOutlined style={{ fontSize: '24px', marginRight: 16, color: '#ffffff' }} />,
            onClick: openHeatMapDrawer,
        }
    ];

    return (
        <Card
            title="More Analytics on Budget"
            style={{ backgroundColor: '#5470C6', color: '#ffffff', marginTop: 24 }}
            headStyle={{ color: '#ffffff' }}
        >
            <List
                itemLayout="horizontal"
                dataSource={analyticsItems}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={item.icon}
                            title={<Text style={{ color: '#ffffff' }}>{item.title}</Text>}
                            description={
                                <div>
                                    <Text style={{ color: '#ffffff' }}>{item.description[0]}</Text>
                                    <br />
                                    <Text style={{ color: '#ffffff' }}>{item.description[1]}</Text>
                                    <br />
                                    <br />
                                    <Link href="#" onClick={item.onClick} style={{ color: '#00E1A6' }}>View Details</Link>
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
            <MaterialWiseBudgetOverrunDrawer visible={materialDrawerVisible} onClose={closeMaterialDrawer} materialWiseBudgetOverrunbyCategoryData={materialWiseBudgetOverrunbyCategoryData}/>
            <HeatMapSiteWiseOverrunDrawer visible={heatMapDrawerVisible} onClose={closeHeatMapDrawer} />
        </Card>
    );
};

export default MoreAnalyticsOnBudget;
