import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../../layout/content/Content";
import { Card, Badge, Alert } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm, FormProvider } from "react-hook-form";
import { Label, Input, Row, Col } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormTextArea from "../../form-components/TextAreaComponent";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../../components/Component";
import MastersAside from "../MastersAside";
import axios from "../../../axios";
import Swal from "sweetalert2";
import FormCheckbox from "../../form-components/CheckboxComponent";
import rolesApi from "../../../api/master/roles";
import ApprovalHeirarchyApis from "../../../api/master/approvalHeirarchy";
import usersApis from "../../../api/master/users";
interface ApprovalLevel {
  level?: string | null;
  level_name?: string | null;
  level_value?: string | null;
}
interface SelectOptions {
  label: string;
  value: string;
}
const ApprovalHeirarchyMaster: FC<{
  id?: string | null;
  module?: string;
  data?: any;
  formAction?: string | null;
  setAction?: () => void;
  toggleModelPopUp: any;
}> = ({ id, module, formAction, toggleModelPopUp, data }) => {
  const [action, setAction] = useState(formAction);
  const methods = useForm({
    mode: "onChange",
  });
  const [approvalLevel, setApprovalLevel] = useState<ApprovalLevel[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [rolesList, setRolesList] = useState<SelectOptions[]>([]);
  const [approvalLevels, setApprovalLevels] = useState(0);
  const [globalData, setData] = useState<any>([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = methods;

  const [accessibilityKeys, setaccessibilityKeys] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [alertInfo, setAlertInfo] = useState<any>({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const fetchModuleConfig = async () => {
    console.log("data", data);
    try {
      const res = await usersApis.getModuleConfig();

      const moduleConfigData = res.data;
      const existingModuleIds = data
        .map((item: any) => item.approvalModuleName)
        .filter((item: any) => item != module);
      const filteredModuleConfigData = moduleConfigData.filter(
        (module: any) =>
          !existingModuleIds.includes(module.moduleName.toString())
      );
      console.log("filteredModuleConfigData", filteredModuleConfigData);
      setaccessibilityKeys(
        filteredModuleConfigData.map((obj: any) => ({
          label: obj.moduleName,
          value: obj.moduleID.toString(),
        }))
      );
      // setSelectedModule(data[0].moduleID.toString());
      // fetchData(data[0].moduleID.toString());
    } catch (err) {
      throw err;
    }
  };

  const onSubmit = async (data: any) => {
    console.log("data", data);
    try {
      if (!action && !id) {
        let name: any = accessibilityKeys.find(
          (key: any) => key.value == data.approvalModule
        );
        data.approvalModuleName = name.label;
        data.level = approvalLevel.map((level, index) => {
          const obj = {
            levelName: `level${index + 1}`,
            levelRole: data[`level${index + 1}`],
          };
          delete data[`level${index + 1}`];
          return obj;
        });
        data.approvalType = data.autoApproval ? "Auto" : "Multi-Level";
        data.approvalCount = data.approvalLevels;
        delete data.autoApproval;
        delete data.multiLevelApproval;
        delete data.approvalLevels;
        console.log(JSON.stringify(data));
        const { status, message } =
          await ApprovalHeirarchyApis.createApprovalHeirarchy(data);
        if (status) {
          Swal.fire("Created!", "Heirarchy has been created", "success");
          toggleModelPopUp();
        } else {
          // Swal.fire("Failed!", message, "error");
          setAlertInfo({
            type: "error",
            message: message,
            errors: [],
          });
        }
      } else if (action === "edit") {
        console.log("edit", data);
        // data.approvalModuleName = name.label;
        const moduleObj = accessibilityKeys.find(
          (key: any) => key.label == module
        );
        console.log("1111");
        data.approvalType = data.autoApproval ? "Auto" : "Multi-Level";
        data.approvalCount = data.approvalLevels;
        console.log("2222");
        data.level = approvalLevel.map((level, index) => {
          const obj = {
            levelName: `level${index + 1}`,
            levelRole: data[`level${index + 1}`],
          };
          delete data[`level${index + 1}`];
          return obj;
        });
        console.log("3333", data);
        data.approvalModule = moduleObj?.value;
        console.log("4444");
        if (data.autoApproval) {
          data = {
            approvalModule: moduleObj?.value,
            approvalType: data.autoApproval ? "Auto" : "Multi-Level",
            approvalCount: 0,
            level: [],
          };
        }
        console.log("data::FINAL", data);
        delete data.autoApproval;
        delete data.multiLevelApproval;
        delete data.approvalLevels;
        console.log("data::FINAL", data);
        Object.keys(data).forEach((key) => {
          // Check if the key starts with "level" and ends with a number
          if (/^level\d+$/.test(key)) {
            // Delete the key from the object
            delete data[key];
          }
        });
        console.log("data::FINAL:::Final", data);

        const { status, message } =
          await ApprovalHeirarchyApis.updateApprovalHierarchyById(id, data);
        if (status) {
          Swal.fire("Updated!", "Heirarchy has been updated", "success");
          toggleModelPopUp();
        } else {
          // Swal.fire("Failed!", message, "error");
          setAlertInfo({
            type: "error",
            message: message,
            errors: [],
          });
        }
      }
    } catch (error) {}
  };
  const fetchRoles = async () => {
    try {
      const { data, status, message } = await rolesApi.fetchRolesData();

      if (status) {
        setRolesList(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const handleApprovalLevelsChange = (e: any) => {
    const value = parseInt(e.target.value, 10);
    if (value <= rolesList.length) {
      setApprovalLevels(value);
    } else {
      Swal.fire(
        "Error",
        `Approval levels cannot exceed the number of available roles (${rolesList.length})`,
        "error"
      );
    }
  };

  const handleCheckboxChange = (name: any) => {
    setSelectedOption((prev) => (prev === name ? null : name));
    if (name === "multiLevelApproval") {
      setValue("multiLevelApproval", !MultiLevelApproval);
      if (!MultiLevelApproval) {
        setValue("approvalLevels", 0); // Reset approval levels
        setApprovalLevel([]); // Clear the approval levels array
        setSelectedRoles([]); // Clear the selected roles
      }
    }

    if (name === "autoApproval") {
      setValue("autoApproval", !AutoApproval);
      if (!AutoApproval) {
        setValue("multiLevelApproval", false); // Deselect multiLevelApproval if autoApproval is selected
      }
    }
  };

  const ApprovalLevels = watch("approvalLevels");
  const AutoApproval = watch("autoApproval");
  const MultiLevelApproval = watch("multiLevelApproval");

  useEffect(() => {
    if (MultiLevelApproval) {
      setValue("autoApproval", false);
    } else if (AutoApproval) {
      setValue("multiLevelApproval", false);
      setApprovalLevel([]); // Clear approval levels when AutoApproval is selected
    }
  }, [MultiLevelApproval, AutoApproval]);

  useEffect(() => {
    fetchModuleConfig();
    if (ApprovalLevels) {
      setApprovalLevel(Array.from({ length: ApprovalLevels }, () => ({})));
      setSelectedRoles(Array.from({ length: ApprovalLevels }, () => ""));
    }
  }, [ApprovalLevels]);

  const fetchData = async () => {
    try {
      const { data, status, message } =
        await ApprovalHeirarchyApis.fetchApprovalHeirarchyById(id);
      console.log(data);
      if (status) {
        setValue("approvalModule", data.approvalModuleName);
        setValue("approvalLevels", data.approvalCount);
        setValue("autoApproval", data.approvalType === "Auto");
        setValue("multiLevelApproval", data.approvalType === "Multi-Level");
        if (data.approvalType === "Multi-Level") {
          setApprovalLevels(data.approvalCount); // Ensure that approval levels are set
        }
        data?.level?.map((level: any, index: number) => {
          setValue(level.levelName, level.levelRole);
          // setSelectedRoles((pre)=>[...pre,level.levelRole]);
        });
        setData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error: any) {
      console.log(error);

      Swal.fire("Error", error, "error");
    }
  };
  useEffect(() => {
    console.log("module");
    fetchRoles();
    // setValue("autoApproval", true);
    if (action === "edit") {
      fetchData();
    }
  }, []);

  return (
    <>
    {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <h5 className="title">
        {action === "edit"
          ? `Edit ${module} Heirarchy `
          : action === "view"
          ? "View Heirarchy"
          : "Create Heirarchy"}
      </h5>
      <div style={{ marginTop: "15px" }}></div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!MultiLevelApproval && <div style={{ marginTop: "20px" }}></div>}
          <Row className="gy-4">
            {action !== "edit" && (
              <Col lg="6">
                <FormSelect
                  name="approvalModule"
                  label="Approval Module Name"
                  options={accessibilityKeys}
                  required={true}
                />
              </Col>
            )}
          </Row>
          <Row className="gy-5 mt-0">
            <Col lg="4">
              <FormCheckbox
                name="autoApproval"
                optionLabel="Auto Approval"
                size={"small"}
                checked={AutoApproval}
                onChange={() => {
                  setValue("autoApproval", !AutoApproval);
                  handleCheckboxChange("autoApproval");
                }}
              />
            </Col>
            <Col lg="4">
              <FormCheckbox
                name="multiLevelApproval"
                optionLabel="Multi Level Approval"
                size={"small"}
                checked={MultiLevelApproval}
                onChange={() => {
                  handleCheckboxChange("multiLevelApproval");
                  setValue("multiLevelApproval", !MultiLevelApproval);
                }}
              />
            </Col>
          </Row>
          {!MultiLevelApproval && <div style={{ marginTop: "30px" }}></div>}
          <div style={{ marginTop: "20px" }}></div>

          {MultiLevelApproval && (
            <>
              <Row>
                <Col lg="6">
                  <FormInput
                    name="approvalLevels"
                    label="Approval Levels"
                    type="number"
                    required={true}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: "15px" }}></div>
              <Row className="gy-4">
                {approvalLevel.map((level, index) => (
                  <Col lg="6" key={index}>
                    <FormSelect
                      name={`level${index + 1}`}
                      label={`Level ${index + 1}`}
                      options={rolesList.filter(
                        (role) =>
                          role.value === selectedRoles[index] ||
                          !selectedRoles.includes(role.value)
                      )}
                      required={true}
                      onChange={(value) => {
                        setSelectedRoles((prev) => {
                          const newSelectedRoles = [...prev];
                          newSelectedRoles[index] = value;
                          return newSelectedRoles;
                        });
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}

          {action !== "view" ? (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 ">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      {/* <Icon name="check" /> */}
                      <span>{action === "edit" ? "Update" : "Submit"}</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="link link-light"
                      type="button"
                      onClick={() => {
                        toggleModelPopUp();
                      }}
                    >
                      {/* <Icon name="cross" /> */}
                      <span>Cancel</span>
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          ) : (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <div
                      className="btn btn-primary btn-md"
                      color="primary"
                      onClick={() => setAction("edit")}
                    >
                      <Icon name="pen" />
                      <span>Edit</span>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default ApprovalHeirarchyMaster;
