import React, { useState, useEffect, FC, useCallback } from "react";
import { Badge, Row, Col, Button, Modal, ModalBody, Card, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import supplierApis from "../../api/master/supplier";
import costCenterApis from "../../api/master/costCenter";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import { FormProvider, useForm } from "react-hook-form";
import {
    PaperClipOutlined,
    DownloadOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import {
    Form,
    TableColumnsType,
    Input,
    Table,
    Timeline,
    Collapse as AntCollapse,
    Descriptions,
    Modal as AntModal,
    Upload,
    Flex,
    Spin,
    Empty,
    Space,
    Divider,
} from "antd";
import { nanoid } from "nanoid";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { Popover, Steps } from "antd";
import Swal from "sweetalert2";
import MaterialModal from "./EditPRMaterialModal";
import CropperComponent from "../components/CropperComponent";
import dayjs from "dayjs";
import Misc from "../masters/Misc";
import { UploadFile } from "antd/es/upload/interface";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import usersApis from "../../api/master/users";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import budgetApis from "../../api/master/budget";
import PurchaseOrderAside from "../purchase-orders/PurchaseOrderAside";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import PurchaseRequestAside from "./PurchaseRequestAside";
import { Link } from "react-router-dom";
import PdfGenerator from "./PRPDF";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatDecimal } from "../../utils/Utils";
const { Dragger } = Upload;
const { Panel } = AntCollapse;
const { FormatCurrency, beforeUpload } = Misc;

interface SelectOptions {
    label: string;
    value: string;
}

type Cess = {
    cessName: string;
    cessType?: string;
    cessPercentage?: string;
    cessAmount?: string;
};
interface TaxConfiguration {
    taxType: string;
    gstType?: string; //IGST or CGST+SGST
    cess?: Cess[];
}

interface CurrencyData {
    description: string;
    currency_code: string;
    currency_symbol: string;
    currency_format: string;
    example: string;
}

const ViewPRDetails: FC<any> = () => {
    const { data: currentUser }: any = useSelector((state: RootState) => state.currentUser)
    const methods = useForm({
        mode: "onChange",
    });
    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = methods;
    const [isLoading, setIsLoading] = useState(true);
    let { prId }: any = useParams();
    const navigate = useNavigate();
    const [OriginalData, setOriginalData] = useState<any>({});
    const [poStatus, setPoStatus] = useState<any>("");
    const [showSubmitBtm, setShowSubmitBtm] = useState(false);
    const [sm, setSm] = useState(false);
    const [tab, setTab] = useState("1");
    const [screenSize, setScreenSize] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
    const [instructionsVisible, setInstructionsVisible] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [materialData, setMaterialData] = useState<any>({});
    const [supplierList, setSupplierList] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
    const [newAttachments, setNewAttachments] = useState<any>([]);
    const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
    const [showCropModel, setShowCropModel] = useState<boolean>(false);
    const [signature, setSignature] = useState<any>([]);
    const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
    const [crRejectModal, setCrRejectModal] = useState<boolean>(false);
    const [disapprovePO, setDisapprovePO] = useState<boolean>(false);
    const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
    const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
        []
    );
    const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
    const [usersOptions, setUsersOptions] = useState<SelectOptions[]>([]);
    const deliveryAddressId = watch("deliveryAddressId");
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [generalLedgerOptions, setGeneralLedgerOptions] = useState<
        SelectOptions[]
    >([]);
    const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
        []
    );
    const [budgetOptions, setBudgetOptions] = useState<SelectOptions[]>([]);

    const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
        SelectOptions[]
    >([]);
    const [currency, setCurrency] = useState<CurrencyData>({
        description: "",
        currency_code: "",
        currency_symbol: "",
        currency_format: "",
        example: "",
    });
    const [showActionModelVisible, setShowActionModelVisible] = useState(false);
    const [DMS, setDMS] = useState<any>([]);
    const [editLineRow, setEditLineRow] = useState<any>();
    const [changeToBuyerData, setChangeToBuyerData] = useState<any>();
    const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState<any>("");
    const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
    const [instructions, setInstructions] = useState("");
    const [newInstructions, setNewInstructions] = useState<any>("");
    const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const [approverIds, setApproverIds] = useState<any>([]);
    const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
        useState(false);
    const [form] = Form.useForm();


    const siteIdWatch = watch("siteId");



    const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        fetchPRDetails();
        fetchDeliveryAddress();
        fetchSites();
        fetchUsers();
        viewChange();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        const headerClick = () => setSm(false);
        document
            .getElementsByClassName("nk-header")[0]
            ?.addEventListener("click", headerClick);

        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
            document
                .getElementsByClassName("nk-header")[0]
                ?.removeEventListener("click", headerClick);
        };
    }, []);

    const viewChange = () => {
        setScreenSize(window.innerWidth);
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setSm(false);
        }
    };

    const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
        setNewAttachments((pre: any) => {
            return [...pre, ...info.fileList];
        });
        setShowSubmitBtm(true);
    };

    const fetchHtmlContentFromUrl = async (url: string) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "text/html",
                },
            });

            return response?.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const fetchPRDetails = async () => {
        try {
            const res = await purchaseRequestApis.getById(prId);
            const { response, status, message } = res;
            if (status) {
                let data: any = response;
                data?.prLines?.forEach((item: any) => {
                    item.headerStaus = data?.prHeader?.status;
                })
                console.log("data", data)
                setOriginalData(data);
                setPoStatus(data?.prHeader?.status);
                if (data?.prApprovals) {
                    let ids = data?.prApprovals
                        ?.filter((item: any) => item?.status != "Approved")
                        ?.map((item: any) => item?.userId);
                    setApproverIds(ids);
                }

                setMaterialData(data);
                const LineItems = data?.prLines;
                setData(LineItems);
                setShowSubmitBtm(false);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const ShowAddressPopOver = ({ id }: any) => {
        if (!id) {
            return <></>;
        }
        let address = deliveryAddressList?.find((item: any) => item?.id == id);
        if (!address) {
            return <></>;
        }
        return (
            <ul>
                <li>{address.addressLine1}</li>
                <li>{address.addressLine2}</li>
                <li>
                    {address.city}, {address.stateName}, {address.countryName} -{" "}
                    {address.pincode}
                </li>
            </ul>
        );
    };


    const fetchSites = async () => {
        try {
            const data = await usersApis.getCurrentUser();
            if (data) {
                setCurrentUserId(data?.id);
                let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
                    label: item.siteName,
                    value: item.siteID.toString(),
                }));
                setSitesOptions(sitesOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUsers = async () => {
        try {
            const data = await usersApis.fetchBuyerUser()
            if (data) {
                // console.log("Data", data)
                let usersOptions = data?.data?.map((item: any) => ({
                    label: item.name,
                    value: item.userID.toString(),
                }));
                setUsersOptions(usersOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDeliveryAddress = async () => {
        try {
            const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
            if (status) {
                let deliveryAddressOptions = data.map((item: any) => ({
                    label: item.deliveryLocationName,
                    value: item.id.toString(),
                }));
                setDeliveryAddressOptions(deliveryAddressOptions);
                setDeliveryAddressList(data);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const [columns, setColumns] = useState<TableColumnsType<any>>([
        {
            title: "#",
            key: "#",
            width: 50,
            fixed: "left",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Material Category",
            dataIndex: "categoryName",
            key: "categoryLabel",
            width: 200,
            fixed: "left",
        },
        {
            title: "MATERIAL NAME",
            dataIndex: "materialName",
            key: "materialLabel",
            width: 200,
            fixed: "left",
        },
        {
            title: "Req Qty",
            dataIndex: "quantity",
            key: "quantity",
            width: 150,
            render: (text, record) => {
                // Convert the value to a float, apply toFixed(2), then format with commas
                const formattedNumber = parseFloat(text)
                    .toFixed(2) // Ensure two decimal places
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format with commas

                return `${formattedNumber} ${record?.uomName}`;
            },
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            width: 150,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 170,
            render: (text: any, record: any) => (
                <>
                    {text}
                    {
                        record.status === "PARTIAL_CONVERT" && (
                            <Popover
                                content={
                                    <div>
                                        {/* Convert awardedQty to a number and handle invalid values */}
                                        <p>Converted Qty: {(!isNaN(Number(record?.awardedQty))) ? Number(record.awardedQty).toFixed(2) : 'N/A'}</p>
                                    </div>
                                }
                                title="Info"
                            >
                                <InfoCircleOutlined style={{ marginLeft: 8 }} />
                            </Popover>
                        )
                    }
                </>
            ),
        },
        {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 150,
            render: (text, record: any, index) => {
                // If headerStatus is "IN_PROGRESS", hide the action button
                // if (record.headerStaus === "IN_PROGRESS") {
                //     return null;  // This hides the action column for this specific row
                // }
                // { poStatus }
                // Otherwise, render the button
                return (
                    <>
                        <Button
                            size="sm"
                            color=""
                            onClick={() => {
                                setEditLineRow(record);
                            }}
                        >
                            <Icon name="edit" />
                        </Button>
                    </>
                );
            }
        }
    ]);


    const filteredColumns = columns.filter((col) => {
        const operationStatus = ["REJECTED", "IN_PROGRESS"]; 
        if (col.key === "operation" && operationStatus.includes(materialData?.prHeader?.status)) {
            return false; // Filter out the "Action" column if status is "IN_PROGRESS" or "REJECTED"
        }
        return true; // Keep other columns
    });

    useEffect(() => {
        if (editLineRow) {
            setAddNewRowModel(true);
        }
    }, [editLineRow]);


    const ApprovalTimelineContent = () => {
        const approvalsData = materialData?.prApprovals;

        if (!approvalsData || approvalsData?.length === 0) {
            return <Badge color="outline-danger">No</Badge>;
        }

        return (
            <Popover
                content={
                    <Timeline className="approval-timeline">
                        {approvalsData?.map((approval: any, index: number) => {
                            const isApproved = approval.status == "APPROVED";
                            const isRejected = approval.status == "REJECTED";
                            return (
                                <Timeline.Item
                                    key={index}
                                    dot={
                                        isApproved ? (
                                            <CheckCircleOutlined style={{ color: "green" }} />
                                        ) : isRejected ? (
                                            <CloseCircleOutlined style={{ color: "red" }} />
                                        ) : undefined
                                    }
                                >
                                    {approval?.userName}
                                    <div className="designation">{approval?.roleName}</div>
                                    {approval?.approvalDate &&
                                        <div className="designation">{dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}</div>
                                    }
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                }
                title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
            >
                <span
                    style={{
                        color: "#2263b3",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                >
                    {/* <Badge color="outline-success">Yes</Badge> */}
                    Yes
                </span>
            </Popover>
        );
    };

    const SubmitChanges = async () => {
        try {
            const _formData = new FormData();
            // const updatedLineItems: any = [];
            // const newLineItems: any = [];
            // const deletedLineItems: any = [];

            // const newfiledata: any = [];
            // const deletedFileData: any = [...deletedAttachments];
            // const updatedFileData: any = [];

            console.log("materialData", materialData);

            let indentDate = dayjs(materialData?.prHeader?.indentDate).format("YYYY-MM-DD");
            let needByDate = dayjs(materialData?.prHeader?.needByDate).format("YYYY-MM-DD");

            const submitData = {
                ...materialData?.prHeader,
                status: "SUBMIT",
                indentDate: indentDate,
                needByDate: needByDate,
                deliveryAddressId: Number(materialData?.prHeader?.deliveryAddressId),
                siteId: Number(materialData?.prHeader?.siteId),
                requestedBy: Number(materialData?.prHeader?.requestedBy),
            };

            delete submitData.deliveryAddressInfo;

            _formData.append("prHeader", JSON.stringify(submitData));
            _formData.append("prLines", JSON.stringify(materialData?.prLines));
            _formData.append("prApprovals", JSON.stringify(materialData?.prApprovals));


            // console.log("submitData", submitData);

            const res = await purchaseRequestApis.newVersion(prId, _formData);
            const { status, message }: any = res;
            if (status) {
                fetchPRDetails();
                Swal.fire("Updated!", message, "success");
                navigate("/view-purchase-requests");
            } else {
                Swal.fire("Error!", message, "error");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const ConvertToRFQ = async () => {
        try {
            const { response, status } = await purchaseRequestApis.convertToRFQ(materialData?.prHeader?.prId);
            if (status) {
                console.log("response", response)
                const data = response;
                navigate("/new-rfq", { state: { data } });
            }
        }
        catch (error) {
            console.log(error);
        }
        setDisableSubmit(false);
    };

    const inactivePR = async () => {
        setShowActionModelVisible(true);
    }



    const handleOk = async () => {
        console.log("11111")
        try {
            const values = await form.validateFields();
            await apiCallToInactivePR(materialData?.prHeader?.prId, values.inActiveReason);
            setShowActionModelVisible(false);
            form.resetFields();
        } catch (error) {
        }
    }

    const handleCancel = () => {
        setShowActionModelVisible(false);
        form.resetFields();
    };



    const apiCallToInactivePR = async (prId: any, reason: string) => {
        // Replace this with your actual API call logic
        try {
            let data = {
                prId: Number(prId),
                remaks: reason,
            }
            const res = await purchaseRequestApis.inactivatePR(data);
            const { response, status, message } = res;
            console.log("data", response);
            if (res.status) {
                Swal.fire("Inactive!", "Budget has been Inactive", "success");
                fetchPRDetails();
            } else {
                Swal.fire("Failed!", message, "error");
            }
        } catch (error) {
            Swal.fire("Failed!", "Something went wrong", "error");
        }
    };

    const returnBillingDetails = () => {

        let address = currentUser?.registeredAddress
        if (!address) {
            return {};
        }
        return {
            name: currentUser?.orgName || "",
            addressLine1: address?.addressLine1,
            addressLine2: address?.addressLine2,
            city: address?.city?.cityName || "",
            state: address?.state?.stateName,
            pincode: address.pincode,
            country: address?.country?.countryName,
            gstin: currentUser?.gstin || "",
            pan: currentUser?.pan || "",
            cin: currentUser?.cin || "",
        };
    };

    const returnSupplierDetails = () => {
        let seller = OriginalData?.supplierDetails;
        if (!seller) {
            return {};
        }
        return {
            name: OriginalData?.supplierName || "",
            addressLine1: seller?.address?.area || "",
            addressLine2: seller?.address?.district?.districtName || "",
            city: seller?.address?.city || "",
            state: seller?.address?.state?.stateName || "",
            pincode: seller?.address?.pincode || "",
            country: seller?.address?.country?.countryName || "",
            gstin: seller?.gstin || "",
            pan: seller?.pan || "",
            cin: seller?.cin || "",
        };
    };

    const returnDetails = () => {
        let address: any = deliveryAddressList?.find((item: any) => item?.id == materialData?.prHeader?.deliveryAddressId)
        return {
            quoteNumber: materialData?.prHeader?.prSequence,
            quoteDate: dayjs(materialData?.prHeader?.indentDate).format("DD-MM-YYYY") || "N/A",
            terms: "",
            expectedShipmentDate: dayjs(materialData?.prHeader?.needByDate).format("DD-MM-YYYY") || "N/A",
            placeofSupply: address?.cityName || "",
            creditDays:
                materialData?.paymentTerms != "Cash and Carry"
                    ? materialData?.creditDays
                    : 0,
            deliveryAddressString:
                [
                    address?.addressLine1,
                    address?.addressLine2,
                    address?.cityName,
                    address?.districtName,
                    address?.stateName,
                    address?.pincode?.toString(),
                    address?.countryName,
                ]
                    ?.filter(Boolean)
                    ?.join(", ") || "",
            totals: {
                taxableAmount: formatDecimal(
                    materialData?.lineItems?.reduce(
                        (a: any, b: any) => a + Number(b.quantity) * Number(b.unitPrice),
                        0
                    )
                ),
                cgst: formatDecimal(
                    materialData?.lineItems?.reduce(
                        (a: any, b: any) => a + Number(b.cgst),
                        0
                    )
                ),
                sgst: formatDecimal(
                    materialData?.lineItems?.reduce(
                        (a: any, b: any) => a + Number(b.sgst),
                        0
                    )
                ),
                igst: formatDecimal(
                    materialData?.lineItems?.reduce(
                        (a: any, b: any) => a + Number(b.igst),
                        0
                    )
                ),
                total: formatDecimal(
                    materialData?.lineItems?.reduce(
                        (a: any, b: any) => a + Number(b.grossTotal),
                        0
                    )
                ),
            },
            lines: data?.map((item: any, index: any) => {
                let itemDescription = item?.materialName;
                if (item?.description) {
                    itemDescription += "\t" + item?.description;
                }
                if (materialData?.deliveryAddressType != "ORDER_LEVEL") {
                    let _address = deliveryAddressList?.find(
                        (_item: any) => _item?.id == item?.deliveryAddressId
                    );
                    if (_address) {
                        const addressString = [
                            _address.addressLine1,
                            _address.addressLine2,
                            _address.city,
                            _address.stateName,
                            _address.pincode?.toString(),
                            _address.countryName,
                        ]
                            .filter(Boolean)
                            .join(", ");
                        itemDescription += "\n\nDelivery Address: " + addressString;
                    }
                }
                return {
                    sno: index + 1,
                    itemDescription,
                    hsnSac: item?.hsnCode || "--",
                    rate: Number(item?.unitPrice || 0),
                    cgstPercentage: Number(item?.cgstPercentage || 0),
                    cgstAmount: Number(item?.cgst || 0),
                    sgstPercentage: Number(item?.sgstPercentage || 0),
                    sgstAmount: Number(item?.sgst || 0),
                    igstPercentage: Number(item?.igstPercentage || 0),
                    igstAmount: Number(item?.igst || 0),
                    taxableAmount: Number(item?.grossTotal || 0),
                    qty: item?.quantity + " " + item?.uomName,
                    uom: item?.uomName,
                };
            }),
        };
    };


    return (
        <>
            <Head title="Purchase request - Details" />
            <Modal
                isOpen={generalInformationEdit}
                toggle={() => setGeneralInformationEdit(false)}
                className="modal-dialog-centered"
                size="xl"
                backdrop="static"
            >
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        setGeneralInformationEdit(false);
                    }}
                    className="close"
                >
                    <Icon name="cross-sm"></Icon>
                </a>

                <ModalBody>
                    <>
                        <h5 className="title">Update General Information</h5>
                        <div style={{ marginTop: "15px" }}></div>

                        <FormProvider {...methods}>
                            <form
                                className="content clearfix"
                                onSubmit={handleSubmit((data) => {
                                    setMaterialData((pre: any) => {
                                        const newprHeader = {
                                            ...pre.prHeader, // Keep existing values
                                            ...data,
                                            siteName: sitesOptions.find(
                                                (item) => item.value == data?.siteId
                                            )?.label,
                                        }

                                        console.log("updatedData", newprHeader)

                                        const updatedMaterialData = {
                                            ...pre, // Keep existing data
                                            prHeader: newprHeader, // Update prHeader
                                        };

                                        return {
                                            ...pre,
                                            ...data,
                                            ...updatedMaterialData,
                                        };
                                    });
                                    setGeneralInformationEdit(false);
                                    setAddNewRowModel(false);
                                    setEditLineRow(null);
                                    // console.log(data);
                                    setShowSubmitBtm(true);
                                })}
                            >
                                <Row className="gy-4">
                                    <Col lg="4">
                                        <FormInput
                                            name="referenceIndentNumber"
                                            label="Indent Number"
                                            required={true}
                                            disabled
                                            type="text"
                                            placeholder="Enter Indent Number"
                                            tooltipText="Enter Indent Number."
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormDatePicker
                                            name="indentDate"
                                            label="Indent Date"
                                            required={true}
                                            minDate={new Date()}
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormSelect
                                            name="siteId"
                                            label={`Construction Site`}
                                            options={sitesOptions}
                                            required={true}
                                            placeholder="-Select-"
                                            tooltipText="Select the construction site where the materials will be used."
                                        />
                                    </Col>
                                </Row>
                                <Row className="gy-4 mt-1">

                                    <Col lg="4">
                                        <FormSelect
                                            name="requestedBy"
                                            label={`Requested By${usersOptions.length == 0
                                                ? "( Empty General Ledger List)"
                                                : ""
                                                }`}
                                            options={usersOptions}
                                            placeholder="-Select-"
                                        // tooltipText="Select the general ledger account for financial tracking."
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormDatePicker
                                            name="needByDate"
                                            label="Need By Date"
                                            required={true}
                                            minDate={new Date()}
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormSelect
                                            name="approvalRequired"
                                            disabled
                                            label="Approval Required ?"
                                            options={[
                                                { label: "Yes", value: "Y" },
                                                { label: "No", value: "N" },
                                            ]}
                                            placeholder="Select"
                                            required={true}
                                        />
                                        <div style={{ display: "none" }}>
                                            <FormInput name="approvalsData" label="" type="text" />
                                        </div>
                                        {/* {approvalRequired == "true" && (
                                            <Popover
                                                content="Click here to set required approvals"
                                                title="Approval Required"
                                            >
                                                <a
                                                    href="#"
                                                    className="success"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setApprovals((pre) => {
                                                            return {
                                                                ...pre,
                                                                open: true,
                                                            };
                                                        });
                                                    }}
                                                >
                                                    <EditOutlined /> Set Approvals &nbsp;
                                                    {approvals?.data?.length == 0 ? (
                                                        <span className="mandatory">Please set approvals</span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </a>
                                            </Popover>
                                        )} */}
                                    </Col>
                                </Row>
                                <Row className="gy-4 mt-1">


                                </Row>
                                <Row className="gy-4 mt-1">
                                    <Col lg="4">
                                        <FormSelect
                                            name="deliveryAddressId"
                                            label="Delivery Address"
                                            options={deliveryAddressOptions}
                                            required={true}
                                            placeholder="-Select-"
                                            tooltipText="Select the delivery address for the purchase order."
                                            // popOverOptions={
                                            //     <ShowAddressPopOver
                                            //         id={deliveryAddressId}
                                            //         matchKey="id"
                                            //         options={deliveryAddressList}
                                            //         text="View Selected Delivery Address"
                                            //     />
                                            // }
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: "8px 0" }} />
                                                    <Space
                                                        style={{
                                                            padding: "0 8px 4px",
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                        }}
                                                    >
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            size="small"
                                                            // onClick={() => {
                                                            //     handleModelPopUp(null, "");
                                                            // }}
                                                            style={{
                                                                width: "100%",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <span>Add Address</span>
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col lg="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button
                                                    className="btn btn-primary btn-md"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Save
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setGeneralInformationEdit(false);
                                                        reset();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </form>
                        </FormProvider>
                    </>
                </ModalBody>
            </Modal>

            <Content>
                <Card className="card-bordered">
                    <div className="card-aside-wrap">
                        <div
                            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                                }`}
                        >
                            <PurchaseRequestAside updateSm={setSm} sm={sm} />
                        </div>
                        {isLoading == true ? (
                            <>
                                <Flex align="center" gap="middle" className="pageLoader">
                                    <Spin size="large" className="m-auto" />
                                </Flex>
                            </>
                        ) : (
                            <div className="card-inner card-inner-lg">
                                {sm && mobileView && (
                                    <div
                                        className="toggle-overlay"
                                        onClick={() => setSm(!sm)}
                                    ></div>
                                )}
                                <BlockHead size="lg">
                                    <BlockBetween>
                                        <BlockHeadContent>
                                            <BlockTitle tag="h4">
                                                <span>
                                                    PR ID - {materialData?.prHeader?.prSequence} |{" "}
                                                    {materialData?.prHeader?.status}{" "}
                                                </span>
                                            </BlockTitle>
                                        </BlockHeadContent>
                                        <div className="d-flex">
                                            {showSubmitBtm && (
                                                <div className="actions clearfix">
                                                    <ul
                                                        className="m-auto d-flex align-items-center justify-content-center p-0"
                                                        style={{
                                                            flexDirection: "row-reverse",
                                                        }}
                                                    >
                                                        <li className="pt-0 pb-0">
                                                            {disableSubmit ? (
                                                                <Button color="primary" type="button">
                                                                    <Spinner size="sm" color="light" />
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setDisableSubmit(true);
                                                                        Swal.fire({
                                                                            title: "Are you sure?",
                                                                            text: "Do you want to submit the changes?",
                                                                            icon: "warning",
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: "#3085d6",
                                                                            cancelButtonColor: "#d33",
                                                                            confirmButtonText: "Yes, submit it!",
                                                                            cancelButtonText: "No, cancel",
                                                                        }).then(async (result) => {
                                                                            if (result.isConfirmed) {
                                                                                await SubmitChanges();
                                                                            }
                                                                            else {
                                                                                setDisableSubmit(false);
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            )}
                                                        </li>
                                                        <li className="pt-0 pb-0">
                                                            <Button
                                                                color="secondary"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "Do you want to revert the changes?",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, revert it!",
                                                                        cancelButtonText: "No, cancel",
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            fetchPRDetails();
                                                                            Swal.fire(
                                                                                "Reverted!",
                                                                                "Your changes have been reverted.",
                                                                                "success"
                                                                            );
                                                                        } else {
                                                                            setDisableSubmit(false);
                                                                        }
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn-dim btn btn-secondary"
                                                            >
                                                                Revert
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}

                                            {
                                                materialData?.prHeader?.status == "DRAFT" && !showSubmitBtm && (
                                                    <div className="paddingLeft10">
                                                        {disableSubmit ? (
                                                            <Button color="primary" type="button">
                                                                <Spinner size="sm" color="light" />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="toggle"
                                                                color="primary"
                                                                onClick={() => {
                                                                    setDisableSubmit(true);
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "Do you want to submit the changes?",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, submit it!",
                                                                        cancelButtonText: "No, cancel",
                                                                    }).then(async (result) => {
                                                                        if (result.isConfirmed) {
                                                                            await SubmitChanges();
                                                                        } else {
                                                                            setDisableSubmit(false);
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <span>Submit</span>
                                                            </Button>
                                                        )}
                                                    </div>
                                                )
                                            }

                                            {
                                                materialData?.prHeader?.status == "OPEN" && (
                                                    <div className="">
                                                        <ul className="nk-tb-actions"
                                                        >
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        tag="a"
                                                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                                                    >
                                                                        <Icon name="more-v"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu end>
                                                                        <ul className="link-list-opt no-bdr">
                                                                            <li onClick={() => {
                                                                                {
                                                                                    Swal.fire({
                                                                                        title: "Are you sure?",
                                                                                        text: "Do you want to Inactive this PR?",
                                                                                        icon: "warning",
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: "#3085d6",
                                                                                        cancelButtonColor: "#d33",
                                                                                        confirmButtonText: "Yes, Inactive it!",
                                                                                        cancelButtonText: "No, cancel",
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            inactivePR();
                                                                                        } else {
                                                                                            setDisableSubmit(false);
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }}>
                                                                                <Link to={`#`}>
                                                                                    <Icon className="text-danger" name="pause-circle"></Icon>
                                                                                    <span>Inactive PR</span>
                                                                                </Link>
                                                                            </li>

                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                            {!showSubmitBtm && (
                                                <div className="paddingLeft10">
                                                    <PdfGenerator
                                                        buyerDetails={returnBillingDetails()}
                                                        supplierDetails={returnSupplierDetails()}
                                                        Details={returnDetails()}
                                                        buyerOrgLogo={currentUser?.orgLogo}
                                                        hasIGST={false}
                                                    />
                                                </div>
                                            )}

                                            {!showSubmitBtm && (
                                                <div className="paddingLeft10">
                                                    <Button
                                                        className="toggle"
                                                        color="primary"
                                                        onClick={async () => {
                                                            navigate(-1);
                                                        }}
                                                    >
                                                        <Icon name="arrow-left" />
                                                        <span>Back</span>
                                                    </Button>
                                                </div>
                                            )}

                                            {
                                                (materialData?.prHeader?.status == "OPEN" || (materialData?.prLines?.filter((e: any) => ["OPEN", "PARTIAL_CONVERT"].includes(e.status)))?.length > 0) && (
                                                    <div className="paddingLeft10">
                                                        <Button
                                                            className="toggle"
                                                            color="primary"
                                                            onClick={ConvertToRFQ}
                                                        >
                                                            <span>Convert To RFQ</span>
                                                        </Button>
                                                    </div>
                                                )
                                            }




                                        </div>
                                        <BlockHeadContent className="align-self-start d-lg-none">
                                            <Button
                                                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""
                                                    }`}
                                                onClick={() => setSm(!sm)}
                                            >
                                                <Icon name="menu-alt-r" />
                                            </Button>
                                        </BlockHeadContent>
                                    </BlockBetween>
                                </BlockHead>

                                <div className="content clearfix">
                                    <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                                        <Panel header="General Information" key="1">
                                            {/* {poStatus} */}
                                            {poStatus !== "IN_PROGRESS" && poStatus !== "REJECTED" && (
                                                <span
                                                    className="cursor-pointer position-absolute"
                                                    style={{
                                                        right: 40,
                                                        top: 75,
                                                        fontSize: "18px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        // if(["Pending Approval"].includes(poStatus)){
                                                        setValue("referenceIndentNumber", materialData?.prHeader?.referenceIndentNumber);
                                                        setValue(
                                                            "indentDate",
                                                            dayjs(materialData?.prHeader?.indentDate)
                                                        );
                                                        setValue(
                                                            "requestedBy",
                                                            materialData?.prHeader?.requestedBy
                                                        );
                                                        setValue("needByDate", dayjs(materialData?.prHeader?.needByDate));
                                                        setValue(
                                                            "deliveryAddressId",
                                                            materialData?.prHeader?.deliveryAddressId
                                                        );
                                                        setValue(
                                                            "siteId",
                                                            materialData?.prHeader?.siteId
                                                        );
                                                        setValue(
                                                            "approvalRequired",
                                                            materialData?.prHeader?.approvalRequired
                                                        )

                                                        setGeneralInformationEdit(true);
                                                    }}
                                                >
                                                    <Icon name="edit" />
                                                </span>
                                            )
                                            }
                                            {/* <div className="tab-content mt-0"> */}
                                            {/* <div className={`tab-pane${tab === "1" && " active"}`}> */}
                                            <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                                                <div className="w-30 border-end ">

                                                    <div className="data-col align-center pt-1 ">
                                                        <div className="data-label w-45">Reference  Indent Number</div>
                                                        <div className="data-value w-55 ">
                                                            {
                                                                materialData?.prHeader?.referenceIndentNumber
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-col align-center pt-1">
                                                        <div className="data-label w-45">Indent Date</div>
                                                        <div className="data-value w-55">
                                                            {dayjs(materialData?.prHeader?.indentDate).format("DD-MM-YYYY") || "--"}
                                                        </div>
                                                    </div>
                                                    <div className="data-col align-center pt-1 ">
                                                        <div className="data-label w-45">Site</div>
                                                        <div className="data-value w-55 ">
                                                            {
                                                                sitesOptions.find(
                                                                    (item) => item.value == materialData?.prHeader?.siteId
                                                                )?.label
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="w-30 border-end paddingLeft10  ">
                                                    <div className="data-col align-center pt-1 ">
                                                        <div className="data-label w-30">Requested By</div>
                                                        <div className="data-value w-70 ">
                                                            {
                                                                usersOptions.find(
                                                                    (item) => item.value == materialData?.prHeader?.requestedBy
                                                                )?.label
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-col align-center pt-1">
                                                        <div className="data-label w-30">Need By Date</div>
                                                        <div className="data-value w-70">
                                                            {dayjs(materialData?.prHeader?.needByDate).format("DD-MM-YYYY") || "--"}
                                                        </div>
                                                    </div>
                                                    <div className="data-col align-center pt-1">
                                                        <div className="data-label w-30">Approvals</div>
                                                        <div className="data-value w-70">
                                                            <ApprovalTimelineContent />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-30 paddingLeft10">
                                                    <div className=" pt-1">
                                                        <div className="data-label w-9 mb-1">
                                                            Delivery Address
                                                        </div>
                                                        <div className="data-value w-90">
                                                            <ShowAddressPopOver
                                                                id={materialData?.prHeader?.deliveryAddressId}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* </div> */}
                                            {/* </div> */}
                                        </Panel>
                                        <Panel header="Material Informations" key="2">
                                            <div className="table-responsive poTable">
                                                <div
                                                    style={{
                                                        width: screenSize - 400 + "px",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <Table
                                                        className="customTable"
                                                        columns={filteredColumns}
                                                        dataSource={materialData?.prLines}
                                                        scroll={{ x: 1000 }}
                                                        locale={{
                                                            emptyText: (
                                                                <span
                                                                    style={{
                                                                        height: "300px",
                                                                        color: "#333",
                                                                        fontSize: "16px",
                                                                    }}
                                                                >
                                                                    Please click on "Add New Item" to add purchase
                                                                    order lines.
                                                                </span>
                                                            ),
                                                        }}
                                                        bordered
                                                        size="middle"
                                                        pagination={false}
                                                    />
                                                </div>
                                                {materialData?.prLines?.length > 0 && (
                                                    <Row
                                                        style={{
                                                            margin: "20px",
                                                        }}
                                                    >
                                                        {poStatus !== "IN_PROGRESS" && poStatus !== "REJECTED" && (
                                                            <Col
                                                                className={`${poStatus !== "Pending Approval"
                                                                    ? "justify-between"
                                                                    : "justify-end"
                                                                    }`}
                                                            >
                                                                {/* {poStatus == "Pending Approval" && ( */}
                                                                <a
                                                                    onClick={() => {
                                                                        setAddNewRowModel(true);
                                                                        setEditLineRow(null);
                                                                    }}
                                                                    href="#add"
                                                                    className="addNewItem"
                                                                >
                                                                    <Icon name="plus-round" />
                                                                    <span>Add New Item</span>
                                                                </a>
                                                                {/* )} */}
                                                            </Col>
                                                        )}
                                                    </Row>
                                                )}
                                            </div>
                                        </Panel>
                                    </AntCollapse>
                                    <Block>
                                        {addNewRowModel && (
                                            <MaterialModal
                                                modal={addNewRowModel}
                                                closeModal={() => {
                                                    setAddNewRowModel(false);
                                                    setEditLineRow(null);
                                                }}
                                                status={materialData?.prHeader?.status}
                                                formData={{
                                                    action: editLineRow == null ? "add" : "edit",
                                                    index: editLineRow?.prLineId,
                                                    item: editLineRow,
                                                }}
                                                append={(data: any) => {
                                                    console.log("Append", data, materialData);
                                                    let newLineItems = materialData?.prLines || [];
                                                    console.log(materialData?.prLines, "newLineItems");
                                                    const _data = newLineItems.find((item: any) => item.materialId == data.materialId);

                                                    if (_data) {
                                                        Swal.fire({
                                                            icon: "error",
                                                            title: "Error",
                                                            text: "Material Already Added",
                                                        });
                                                        return;
                                                    }

                                                    setMaterialData((prev: any) => {
                                                        let newData = {
                                                            ...prev,
                                                            prLines: [...prev.prLines, data], // Add new data to prLines immutably
                                                        };
                                                        console.log(newData, "newData");
                                                        return newData;
                                                    });
                                                    // setData((pre: any) => {
                                                    //     console.log("[...pre, data]", [...pre, data]);
                                                    //     return [...pre, data];
                                                    // });
                                                    setShowSubmitBtm(true);
                                                }}
                                                update={(id: any, data: any) => {
                                                    console.log("update", data, id, materialData?.prLines);
                                                    let newLineItems = materialData?.prLines || [];
                                                    const _data = newLineItems
                                                        .filter((item: any) => item.prLineId != id)
                                                        .find((item: any) => item.materialId == data.materialId);

                                                    if (_data) {
                                                        Swal.fire({
                                                            icon: "error",
                                                            title: "Error",
                                                            text: " Material Already Added",
                                                        });
                                                        return;
                                                    }

                                                    const updatedLines = newLineItems.map((item: any) => {
                                                        if (item.prLineId == id) {
                                                            return data;
                                                        }
                                                        return item;
                                                    });

                                                    setMaterialData((prev: any) => ({
                                                        ...prev,
                                                        prLines: updatedLines, // Update prLines immutably
                                                    }));

                                                    setData(updatedLines);
                                                    setShowSubmitBtm(true);
                                                }}
                                            />
                                        )}

                                    </Block>
                                </div>
                            </div>
                        )}
                    </div>
                </Card>
            </Content>



            <AntModal
                title={"Inactive PR"}
                visible={showActionModelVisible}
                onOk={handleOk} // Ensure form is submitted on OK
                onCancel={handleCancel}
                okText="Submit"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical" name="inactiveForm">
                    <Form.Item
                        name="inActiveReason"
                        label="Reason for Inactive"
                        rules={[{ required: true, message: "Please input your reason!" }]}
                    >
                        <Input.TextArea placeholder="Type your reason here..." />
                    </Form.Item>
                </Form>
            </AntModal>




        </>
    );
};

export default ViewPRDetails;
