import makeApiCall from "./axiosIndex";

const departments = {
    fetchDeptData: (): Promise<any> => makeApiCall<any>({ method: 'get', url: '/buyer/fetchDept' }),
    deleteDeptById: (deptID: any): Promise<any> => makeApiCall<any>({
        method: 'delete',
        url: `/buyer/deleteDeptById`,
        params: { id: deptID }
    }),
    createDept: (data: any): Promise<any> => makeApiCall<any>({
        method: 'post',
        url: '/buyer/createDept',
        data
    }),
    updateDeptById: (id: any, data: any): Promise<any> => makeApiCall<any>({
        method: 'put',
        url: `/buyer/updateDeptById`,
        params: { id: id },
        data
    }),
    fetchDeptById: (id: any): Promise<any> => makeApiCall<any>({
        method: 'get',
        url: `/buyer/fetchDeptById`,
        params: { deptId: id }
    }),
};

export default departments;
