import React, {
  useState,
  useEffect,
  FC,
  useCallback,
} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  RSelect,
} from "../../../components/Component";
import { Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import materialsApis from "../../../api/master/materials";
import FormSelect from "../../form-components/SelectComponent";
import FormatDate from "../DateFormater";
import supplierApis from "../../../api/master/supplier";
import {
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import misc from "../../../api/misc/dropDowns";
import FormTextArea from "../../form-components/TextAreaComponent";
import adminOrgApis from "../../../api/master/adminOrg";
import { useSelector } from "react-redux";
interface SelectOptions {
  label: string;
  value: string;
}
const ViewMaterialDetails: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

    useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { materialId } = useParams();
  const [data, setData]: any = useState();
  const [modalGI, setGIModal] = useState(false);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptions[]>([]);
  const [docs, setDocs] = useState<any>([]);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [formData, setFormData]: any = useState({
    // materialID: "",
    materialType: "",
    // orgID: "",
    productCode: "",
    productName: "",
    description: "",
    category: "",
    // length: 0,
    // width: 0,
    // height: 0,
    // dynamicPricing: false,
    // unitPrice: 0,
    // hsnCode: "",
    // sgstRate: 0,
    // cgstRate: 0,
    // igstRate: 0,
    quantityOnHand: 0,
    quantityAsOfDate: "",
    minimumStockLevel: 0,
    maximumStockLevel: 0,
    reorderPoint: 0,
    unitOfMeasure: "",
    specification: "",
    expenseType: "",
    productLife: 0,
    status: "",
    suppliers: [
      {
        supplierID: "",
        supplierName: "",
        currency: null,
        averageLeadDays: "",
        id: "",
      },
    ],
    subcategory: "",
    // supplierID: "",
    currency: "",
    // averageLeadDays: "",
  });
  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      materialType: "",
      productCode: "",
      productName: "",
      description: "",
      category: "",
      // length: 0,
      // width: 0,
      // height: 0,
      // dynamicPricing: false,
      // unitPrice: 0,
      // hsnCode: "",
      // sgstRate: 0,
      // cgstRate: 0,
      // igstRate: 0,
      quantityOnHand: 0,
      quantityAsOfDate: "",
      minimumStockLevel: 0,
      maximumStockLevel: 0,
      reorderPoint: 0,
      unitOfMeasure: "",
      specification: "",
      expenseType: "",
      productLife: 0,
      status: "",
      suppliers: [
        {
          supplierID: "",
          supplierName: "",
          currency: "INR",
          averageLeadDays: "",
          id: "",
        },
      ],
    },
  });
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "suppliers",
  });
  const imageWatch = watch("image");
  const documentWatch = watch("document");

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id,
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUOMOPtions();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = materialId;
    if (id !== undefined || null || "") {
      fetchMaterialsData(id);
      fetchSuppliersDropdown();
      fetchCurrencyData();
      getContructionsMaterialTypes();
      fetchSupplierCategory();
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [materialId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    if (modalEditBlockName === "SupplierInformation") {
      fetchSuppliersDropdown();
    }
    setGIModal(true);
  };

  const submitUpdates = async (submitData: any) => {
    const formData: any = new FormData();
    if (submitData?.image || submitData?.document) {
      const filesData = [];
      const deletedFileData: any = [];
      const files = [];

      if (submitData?.image && submitData?.image[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "material",
          purpose: "image",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
        files.push(submitData.image[0]);
        const previousDoc = docs.filter(
          (document: any) => document.moduleFileType == "image"
        );

        if (previousDoc) {
          previousDoc.forEach((doc: any) => {
            deletedFileData.push({
              docId: doc.docId,
            });
          });
        }
      }
      if (submitData?.document && submitData?.document[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "material",
          purpose: "document",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
        files.push(submitData.document[0]);
        const previousDoc = docs.filter(
          (document: any) => document.moduleFileType == "document"
        );
        if (previousDoc) {
          previousDoc.forEach((doc: any) => {
            deletedFileData.push({
              docId: doc.docId,
            });
          });
        }
      }
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: filesData,
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      files.forEach((file) => {
        formData.append(`files`, file);
      });
    } else {
      formData.append("files", JSON.stringify([]));
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    delete submitData.image;
    delete submitData.document;
    submitData["suppliers"] = submitData?.suppliers?.map((item: any) => {
      if (!item.averageLeadDays) {
        item.averageLeadDays = null;
      }
      delete item["id"];
      return item;
    });
    console.log("productLife", submitData.productLife,submitData.maximumStockLevel);
    if (!submitData.productLife) {
      submitData.productLife = null;
    }
    if (!submitData.quantityOnHand) {
      submitData.quantityOnHand = null;
    }
    if (!submitData.reorderPoint) {
      submitData.reorderPoint = null;
    }
    if (!submitData.maximumStockLevel) {
      console.log("minimumStockLevel",submitData.maximumStockLevel)
      submitData.maximumStockLevel = null;
    }
    if (!submitData.minimumStockLevel) {
      submitData.minimumStockLevel = null;
    }
    formData.append("materialData", JSON.stringify(submitData));
    setValue("image", null);
    setValue("document", null);
    const { status, message } = await materialsApis.updateProductById(
      materialId,
      formData
    );
    if (status) {
      Swal.fire("Updated!", "Material has been updated", "success");
      const id = materialId;
      setGIModal(false);
      fetchMaterialsData(id);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const deleteAPI = async (id: any) => {
    try {
      const { status, message } =
        await materialsApis.deletePrefferedSuppliersById(id);
      // fetchMaterialsData(materialId);
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const handleRemove = (index: any, idPath: any) => {
    // Call delete API
    const values = getValues(); // Get the current form values
    console.log("values", values);
    const id: any = values?.suppliers?.[index];
    console.log("id", id, id.id);
    if (id.id) {
      deleteAPI(id.id).then(() => {
        // Remove from field array
        remove(index);
      });
    } else {
      remove(index);
    }
  };

  const fetchMaterialsData = async (id: any) => {
    try {
      const { data, status, message } = await materialsApis.fetchProductById(
        id
      );
      console.log("status", data);
      if (status) {
        console.log("status", data);
        const newData = data;
        const { suppliers, docs } = data;
        setDocs(docs);
        setData(newData);
        let formatedsuppliers = suppliers.map((block: any) => ({
          supplierID: block.supplierID,
          supplierName: block.supplierName,
          currency: block.currency,
          averageLeadDays: block.averageLeadDays,
          id: block.id,
        }));
        if (suppliers.length === 0)
          formatedsuppliers = [
            {
              supplierID: "",
              supplierName: "",
              currency: "",
              averageLeadDays: "",
              id: "",
            },
          ];
        setFormData({
          // materialID: newData.materialID,
          materialType: newData.materialType,
          // orgID: newData.orgID,
          productCode: newData.productCode,
          productName: newData.productName,
          description: newData.description,
          category: newData.category,
          // length: newData.length,
          // width: newData.width,
          // height: newData.height,
          // dynamicPricing: newData.dynamicPricing,
          // unitPrice: newData.unitPrice,
          // hsnCode: newData.hsnCode,
          // sgstRate: newData.sgstRate,
          // cgstRate: newData.cgstRate,
          // igstRate: newData.igstRate,
          quantityOnHand: newData.quantityOnHand,
          quantityAsOfDate: newData.quantityAsOfDate,
          minimumStockLevel: newData.minimumStockLevel,
          maximumStockLevel: newData.maximumStockLevel,
          reorderPoint: newData.reorderPoint,
          unitOfMeasure: newData.unitOfMeasureName,
          unitOfMessureName: newData.unitOfMeasureName,
          specification: newData.specification,
          // expenseType: newData.expenseType,
          productLife: newData.productLife,
          status: newData.status,
          subcategory: newData.subcategory,
          supplierID: newData.supplierID,
          currency: newData.currency,
          averageLeadDays: newData.averageLeadDays,
          suppliers: formatedsuppliers,
        });
        setValue("suppliers", formatedsuppliers);
        setValue("category", newData.category);
        setValue("productName", newData.productName);
        setValue("productCode", newData.productCode);
        setValue("description", newData.description);
        setValue("materialType", newData.materialType);
        setValue("unitOfMeasure", newData.unitOfMeasure);
        setValue("unitPrice", newData.unitPrice);
        setValue("quantityOnHand", newData.quantityOnHand);
        setValue("quantityAsOfDate", newData.quantityAsOfDate);
        setValue("minimumStockLevel", newData.minimumStockLevel);
        setValue("maximumStockLevel", newData.maximumStockLevel);
        setValue("reorderPoint", newData.reorderPoint);
        setValue("productLife", newData.productLife);
        setValue("status", newData.status);
        setValue("specification", newData.specification);
        setValue("currency", newData.currency);
        setValue("averageLeadDays", newData.averageLeadDays);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  const [industryTypes, setIndustryTypes] = useState<SelectOptions[]>([]);
  const [categories, setCategories] = useState<SelectOptions[]>([]);

  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const getContructionsMaterialTypes = useCallback(async () => {
    try {
      const matCats = await supplierApis.getContructionsMaterialTypes();
      console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setIndustryTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status, message } = res;
      if (status) {
        // setAllCurrencyData(data);
        let currencyOptions = data.map((item: any) => {
          return {
            label: item.currency_code,
            value: item.currency_code,
          };
        });
        setCurrencyOptions(currencyOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSuppliersDropdown = async () => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplier();
      if (status) {
        // Assuming `data` is an array of suppliers
        const formattedData = data.map((supplier: any) => ({
          label: supplier.supplierName,
          value: supplier.supplierID,
        }));
        setSupplierOptions(formattedData);
        const defaultSupplier = formattedData.find(
          (option: any) => option.label === formData.supplierName
        );
        if (defaultSupplier) {
          setFormData((prevData: any) => ({
            ...prevData,
            supplierID: defaultSupplier.value,
          }));
        }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to fetch suppliers", "error");
    }
  };

  const handleChange = (selectedOption: any) => {
    console.log("Selected Option:", selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : "";
    console.log("Selected Value:", selectedValue);
    setFormData({
      ...formData,
      supplierID: selectedValue,
    });
  };

  const handleSupplierChange = (value: any, index: number, field: any) => {
    // setValue(`suppliers[${index}].supplierName`, supplierName);
    // setValue(`suppliers[${index}].supplierID`, value.value);
    update(index, {
      ...field,
      supplierName: value.label,
      supplierID: value.value,
    });
  };

  return (
    <React.Fragment>
      <Head title="Material Details"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Material Details -{" "}
                    <strong className="text-primary small">
                      {data.productName}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* {submitEditData && (
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => submitUpdates()}
                    >
                      <Icon name="check-round-cut"></Icon>
                      <span>Submit Updates</span>
                    </Button>
                  )} */}

                  {/* <Button
                    outline
                    className="bg-white d-none d-sm-inline-flex"
                    style={{ marginRight: "10px" }}
                    onClick={() => openEditModal()}
                  >
                    <Icon name="edit-alt-fill"></Icon>
                    <span>Edit</span>
                  </Button> */}
                  <Link to={`${process.env.PUBLIC_URL}/materails`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Product Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit?<Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ProductInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>:<></>}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Code</div>
                          <div className="data-value">{data.productCode}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Type</div>
                          <div className="data-value">
                            {
                              industryTypes.find(
                                (option: SelectOptions) =>
                                  option.value == data.materialType
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Category</div>
                          <div className="data-value">
                            {
                              categories.find(
                                (option: SelectOptions) =>
                                  option.value == data.category
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Name</div>
                          <div className="data-value">{data.productName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Unit Of Measure</div>
                          <div className="data-value">
                            {data.unitOfMeasureName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Specification</div>
                          <div className="data-value">{data.specification}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Product Life</div>
                          <div className="data-value">{data.productLife}</div>
                        </div>
                      </li>
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Preferred Suppliers</div>
                          <div className="data-value">{data.supplierName}</div>
                        </div>
                      </li> */}
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Delivery Lead Days (AVG)*
                          </div>
                          <div className="data-value">
                            {data.averageLeadDays}
                          </div>
                        </div>
                      </li> */}
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Description</div>
                          <div className="data-value">{data.description}</div>
                        </div>
                      </li>
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Width</div>
                          <div className="data-value">{data.width}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Height</div>
                          <div className="data-value">{data.height}</div>
                        </div>
                      </li> */}
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Preferred Suppliers</BlockTitle>
                        <p>List of Preferred Suppliers information</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit?<Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("preferredSuppliers")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>:<></>}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <div className="table-responsive mt-2 mb-2">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Supplier</th>
                            <th>Delivery Lead Days</th>
                            <th>Currency</th>
                            {/* <th>Planned End Date</th>
                            <th>Current Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data.suppliers.length > 0 ? (
                            data.suppliers.map((itm: any) => {
                              return (
                                <tr key={itm.id}>
                                  <td>{itm.supplierName}</td>
                                  <td>{itm.averageLeadDays}</td>
                                  <td>{itm.currency}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <span className="text-silent mt-5">
                                    No data found
                                  </span>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Inventory Information</BlockTitle>
                        <p>Inventory stock related details</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit?<Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("InventoryInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>:<></>}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Stock Quantity</div>
                          <div className="data-value">
                            {data.quantityOnHand}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Minimum Stock Level</div>
                          <div className="data-value">
                            {data.minimumStockLevel}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Maximum Stock Level</div>
                          <div className="data-value">
                            {data.maximumStockLevel}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Reorder Point</div>
                          <div className="data-value">{data.reorderPoint}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Images and Documents</BlockTitle>
                        <p>Uploaded Images and Documents</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit?<Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ImagesandDocuments")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>:<></>}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      {docs?.map((document: any) => {
                        return (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                {document.moduleFileType}
                              </div>
                              <span className="data-value">
                                <a
                                  href={document.filePath}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "default",
                                  }}
                                >
                                  {document.fileName}
                                </a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "ProductInformation" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">Edit Product Information </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="4">
                              <FormInput
                                name="productCode"
                                label="Material Code"
                                type="text"
                                disabled
                                placeholder="Enter Material Code"
                                required={true}
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="materialType"
                                label="Material Type"
                                required={true}
                                options={industryTypes}
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="category"
                                label="Category"
                                required={true}
                                options={categories}
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="productName"
                                label="Material Name"
                                type="text"
                                placeholder="Enter Material Name"
                                required={true}
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="unitOfMeasure"
                                label="Unit of Measure"
                                required={true}
                                options={uomOptions}
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="specification"
                                label="Specification"
                                type="text"
                                required={true}
                                placeholder="Enter Specification"
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="productLife"
                                label="Material Life (in days)"
                                type="number"
                                placeholder="Enter Material Life"
                              />
                            </Col>
                            <Col md="12">
                              <FormTextArea
                                name="description"
                                label="Description"
                                placeholder="Enter Description"
                                required={true}
                              />
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                    // onClick={() => submitForm(data)}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}

              {modalEditBlockName === "ImagesandDocuments" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">
                            Edit Preferred Suppliers Information{" "}
                          </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="6">
                              <FormInput
                                name="image"
                                label="Upload Material Image"
                                type="file"
                              />
                              {imageWatch && <div>{imageWatch[0]?.name}</div>}
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="document"
                                label="Upload Material Document"
                                type="file"
                              />
                              {documentWatch && (
                                <div>{documentWatch[0]?.name}</div>
                              )}
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}
              {modalEditBlockName === "preferredSuppliers" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">
                            Edit Preferred Suppliers Information{" "}
                          </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="12">
                              {fields.map((field: any, index) => (
                                <div key={field.id} className="row mb-3">
                                  <div className="col">
                                    <label className="form-label">
                                      Select Supplier
                                    </label>
                                    <RSelect
                                      name={`suppliers[${index}].supplierID`}
                                      label="Select Supplier"
                                      defaultValue={supplierOptions.find(
                                        (item: any) =>
                                          item.value == field.supplierID
                                      )}
                                      options={supplierOptions}
                                      placeholder="Select Supplier"
                                      onChange={(value: any) =>
                                        handleSupplierChange(
                                          value,
                                          index,
                                          field
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col">
                                    <FormInput
                                      name={`suppliers[${index}].averageLeadDays`}
                                      label="Delivery Lead Days"
                                      //   required={true}
                                      type="number"
                                      placeholder="Delivery Lead Days"
                                    />
                                  </div>
                                  <div className="col">
                                    <FormSelect
                                      name={`suppliers[${index}].currency`}
                                      label="Currency"
                                      //   required={true}
                                      options={currencyOptions}
                                      placeholder="-Select-"
                                      // defaultValue={defaultCurrencyValue} // Set default value
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <div style={{ marginTop: 30 }}>
                                      <button
                                        type="button"
                                        className="btn btn-success ms-2"
                                        onClick={() =>
                                          append({
                                            supplierID: "",
                                            supplierName: "",
                                            averageLeadDays: "",
                                            currency: "INR",
                                            id: "",
                                          })
                                        }
                                      >
                                        +
                                      </button>
                                      &nbsp;
                                      {index > 0 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            handleRemove(
                                              index,
                                              `suppliers[${index}].id`
                                            )
                                          }
                                        >
                                          -
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}

              {modalEditBlockName === "InventoryInformation" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">Edit Inventory Information </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="6">
                              <FormInput
                                name="quantityOnHand"
                                label="Stock Quantity"
                                type="number"
                                placeholder="Enter Stock Quantity"
                                // required={true}
                              />
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="minimumStockLevel"
                                label="Minimum Stock Level"
                                type="number"
                                placeholder="Enter Minimum Stock Level"
                                // required={true}
                              />
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="maximumStockLevel"
                                label="Maximum Stock Level"
                                type="number"
                                placeholder="Enter Maximum Stock Level"
                                // required={true}
                              />
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="reorderPoint"
                                label="Reorder Point"
                                type="number"
                                placeholder="Enter Reorder Point"
                                // required={true}
                              />
                            </Col>
                            {/* <Col md="6">
                              <FormSelect
                                name="unitOfMeasure"
                                label="Unit of Measure"
                                required={true}
                                options={uomOptions}
                                onChange={(e) =>
                                  setFormData({ ...formData, unitOfMeasure: e })
                                }
                                placeholder="Select Unit of Measure"
                              /> */}
                            {/* <label
                                className="form-label"
                                htmlFor="unitOfMeasure"
                              >
                                Unit of Measure
                              </label>
                              <RSelect
                                options={[
                                  { label: "Unit 1", value: "unit 1" },
                                  { label: "Unit 2", value: "unit 2" },
                                ]}
                                placeholder="Select Unit of Measure"
                                name="unitOfMeasure"
                                defaultValue={
                                  formData.unitOfMeasure
                                    ? [
                                        {
                                          value: formData.unitOfMeasure,
                                          label: formData.unitOfMeasure,
                                        },
                                      ]
                                    : null
                                }
                                isRequired
                                onChange={(e: any) =>
                                  setFormData({
                                    ...formData,
                                    unitOfMeasure: e[0].value,
                                  })
                                }
                              /> */}
                            {/* </Col> */}
                          </Row>
                          <Row className="mt-4">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewMaterialDetails;
