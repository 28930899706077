import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  AlertOutlined,
} from "@ant-design/icons";

const statusMapper: any = {
  "Pending Approval": {
    buyer: {
      title: "Pending Approval",
      color: "gold", 
      icon: <HourglassOutlined />, 
    },
    seller: {
      title: "Pending Approval",
      color: "gold",
      icon: <HourglassOutlined />,
    },
  },
  "Approval Rejected": {
    buyer: {
      title: "Approval Rejected",
      color: "red", 
      icon: <CloseCircleOutlined />, 
    },
    seller: {
      title: "Approval Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "Partially Delivered": {
    buyer: {
      title: "Partially Delivered",
      color: "orange", 
      icon: <ExclamationCircleOutlined />, 
    },
    seller: {
      title: "Partially Delivered",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Fully Delivered": {
    buyer: {
      title: "Fully Delivered",
      color: "green", 
      icon: <CheckCircleOutlined />, 
    },
    seller: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "Partially Dispatched": {
    buyer: {
      title: "Partially Dispatched",
      color: "purple", 
      icon: <ExclamationCircleOutlined />, 
    },
    seller: {
      title: "Partially Dispatched",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Fully Dispatched": {
    buyer: {
      title: "Fully Dispatched",
      color: "green", 
      icon: <CheckCircleOutlined />, 
    },
    seller: {
      title: "Fully Dispatched",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "To be Dispatched": {
    buyer: {
      title: "To be Dispatched",
      color: "blue", 
      icon: <SyncOutlined spin />, 
    },
    seller: {
      title: "To be Dispatched",
      color: "blue",
      icon: <SyncOutlined spin />,
    },
  },
};

export default statusMapper;
