import axios from "axios";
import authService from "./auth/authService";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_LOGIN_URL;
console.log(API_URL);

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data && config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest.url == "/auth/refresh") {
      window.location.href = "/";
      return
    }
    if (
      error.response.status === 401 &&
      error?.response?.data?.message === "Token Expired"
    ) {
      try {
        const data = await authService.refreshToken();
        if (data.status && data.token && data.token.access_token) {
          Cookies.set("access-token", JSON.stringify(data.token), {
            expires: 7,
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("refresh-token", data.token.refresh_token, {
            expires: 7,
            secure: true,
            sameSite: "Strict",
          });
          const accessToken = Cookies.get("access-token");
          originalRequest.headers["Authorization"] = `${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        console.log("Token refresh failed:", refreshError);
        window.location.href = "/";
        return error?.response?.data;
      }
    } else if (
      error.response.status === 401 &&
      window.location.pathname !== "/"
    ) {
      console.log("Unauthorized access");
      window.location.href = "/";
      return error?.response?.data;
    } else if (error.response.status === 409) {
      return error.response;
    } else if (error.response.status === 400) {
      return error.response;
    }
    console.log("------ Error ---------", error?.response?.data);
    throw error?.response?.data;
  }
);

export default axiosInstance;
