import React, { useEffect, FC, useCallback, useState } from "react";
import { Modal, ModalBody, Form, Alert } from "reactstrap";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import FormSelect from "../../form-components/SelectComponent";

const AddModal: FC<any> = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  alertInfo,
}) => {
  useEffect(() => {
    console.log("Initial formData:", formData);
    reset(formData);
  }, [formData]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [industryTypes, setIndustryTypes] = useState([]);

  const getContructionsMaterialTypes = useCallback(async () => {
    try {
      const matCats = await supplierApis.getContructionsMaterialTypes();
      console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setIndustryTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  useEffect(() => {
    getContructionsMaterialTypes();
  }, []);
  return (
    <Modal
      isOpen={modal}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="xl"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add Material Category</h5>
          {alertInfo.message && (
            <div className="mb-3 mt-1">
              <Alert
                color={alertInfo.type === "error" ? "danger" : "success"}
                className="alert-icon"
              >
                <strong>{alertInfo.message}</strong>
                <Icon
                  name={
                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                  }
                />
                {alertInfo.errors.length > 0 ? (
                  <ul>
                    {alertInfo.errors.map((err: any, index: any) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <Form
              className="row gy-4"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">
                  Material Category ID<span className="mandatory">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("referenceID", {
                      required: "This field is required",
                    })}
                    value={formData.referenceID}
                    onChange={(e) =>
                      setFormData({ ...formData, referenceID: e.target.value })
                    }
                    placeholder="Enter Material Category"
                  />
                  {errors.referenceID &&
                    typeof errors.referenceID.message === "string" && (
                      <span className="invalid">
                        {errors.referenceID.message}
                      </span>
                    )}
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">
                    Material Type<span className="mandatory">*</span>
                  </label>
                  <RSelect
                    label="Material Type"
                    required={true}
                    {...register("materialID", {
                      required: "This field is required",
                    })}
                    options={industryTypes}
                    value={industryTypes.find(
                      (option: any) => option.value == formData.materialID
                    )}
                    onChange={(e: any) =>
                      setFormData({ ...formData, materialID: e.value })
                    }
                  />
                  {errors.materialID &&
                    typeof errors.materialID.message === "string" && (
                      <span className="invalid" style={{ color: '#e85347',fontSize: '11px',fontStyle:"italic"}}>
                        {errors.materialID.message}
                      </span>
                    )}
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">
                  Category Name<span className="mandatory">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("category", {
                      required: "This field is required",
                    })}
                    value={formData.category}
                    onChange={(e) =>
                      setFormData({ ...formData, category: e.target.value })
                    }
                    placeholder="Category Name"
                  />
                  {errors.category &&
                    typeof errors.category.message === "string" && (
                      <span className="invalid">{errors.category.message}</span>
                    )}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    Description<span className="mandatory">*</span>
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    {...register("description", {
                      required: "This field is required",
                    })}
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    placeholder="Write your description"
                  />
                  {errors.description &&
                    typeof errors.description.message === "string" && (
                      <span className="invalid">
                        {errors.description.message}
                      </span>
                    )}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      Add
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AddModal;
