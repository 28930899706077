import React from 'react';
import { Timeline, Tag, Popover, Descriptions, Empty } from 'antd';
import moment from 'moment';

interface TimelineItem {
    level: string;
    name: string;
    roleName: string;
    roleID: string;
    approvalStatus: string;
    date: string;
    rejectionReason: string;
}

const sampleData: TimelineItem[] = [
    {
        level: "Level 1",
        name: "John Doe",
        roleName: "CEO",
        roleID: "1",
        approvalStatus: "Approved",
        date: "03-Aug-2024",
        rejectionReason: ""
    },
    {
        level: "Level 2",
        name: "Jane Smith",
        roleName: "Manager",
        roleID: "2",
        approvalStatus: "Rejected",
        date: "04-Aug-2024",
        rejectionReason: "Missing documents"
    },
    {
        level: "Level 3",
        name: "Sam Johnson",
        roleName: "Engineer",
        roleID: "3",
        approvalStatus: "Pending",
        date: "05-Aug-2024",
        rejectionReason: ""
    }
];

const TimelineComponent: React.FC<{ dataApprovalDetails: TimelineItem[] }> = ({ dataApprovalDetails }) => {
    const data = dataApprovalDetails;

    if (data.length === 1) {
        return (
            <Descriptions title="" column={1}>
                <Descriptions.Item label={<span className="description-label">Level</span>}>{data[0].level}</Descriptions.Item>
                <Descriptions.Item label={<span className="description-label">Name</span>}>{data[0].name}</Descriptions.Item>
                <Descriptions.Item label={<span className="description-label">Role Name</span>}>{data[0].roleName}</Descriptions.Item>
                <Descriptions.Item label={<span className="description-label">Approval Status</span>}>
                    <Tag color={getTagColor(data[0].approvalStatus)}>{data[0].approvalStatus}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label={<span className="description-label">Date</span>}>{moment(data[0].date).format('DD-MMM-YYYY')}</Descriptions.Item>
                {data[0].approvalStatus === 'Rejected' && (
                    <Descriptions.Item label={<span className="description-label">Rejection Reason</span>}>{data[0].rejectionReason}</Descriptions.Item>
                )}
            </Descriptions>
        );
    }

    return (
        <>
            {data.length > 0 ? 
            (<>
                <Timeline>
                    {data.map((item, index) => (
                        <Timeline.Item key={index} color={getTimelineColor(item.approvalStatus)}>
                            <div>
                                <strong>{item.level}</strong> - <strong>{item.name}</strong> - &nbsp;
                                <Tag color={getTagColor(item.approvalStatus)}>{item.approvalStatus}</Tag>
                            </div>
                            <div>Designation: {item.roleName}</div>
                            <div>Action Date: {moment(item.date).format('DD-MMM-YYYY')}</div>
                            {item.approvalStatus === 'Rejected' && (
                                <div>
                                    <Popover content={item.rejectionReason} title="Rejection Reason">
                                        <span color="error" style={{ cursor: 'pointer', color: 'red' }}>Reason</span>
                                    </Popover>
                                </div>
                            )}
                        </Timeline.Item>
                    ))}
                </Timeline>
            </>) 
            : 
            (<>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Empty description="No Approval Details" />
                </div> 
            </>)
            }
        </>

    );
};

const getTagColor = (status: string) => {
    switch (status) {
        case 'Approved':
            return 'green';
        case 'Rejected':
            return 'red';
        case 'Pending':
            return 'orange';
        default:
            return 'blue';
    }
};

const getTimelineColor = (status: string) => {
    switch (status) {
        case 'Approved':
            return 'green';
        case 'Rejected':
            return 'red';
        case 'Pending':
            return 'orange';
        default:
            return 'blue';
    }
};

export default TimelineComponent;
