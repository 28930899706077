import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
const InventoryInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };
  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-6">
          <FormInput
            name="quantityOnHand"
            label="Stock Quantity"
            type="number"
            placeholder="Enter Stock Quantity"
            // required={true}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="minimumStockLevel"
            label="Minimum Stock Level"
            type="number"
            placeholder="Enter Minimum Stock Level"
            // required={true}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="maximumStockLevel"
            label="Maximum Stock Level"
            type="number"
            placeholder="Enter Maximum Stock Level"
            // required={true}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="reorderPoint"
            label="Reorder Point"
            type="number"
            placeholder="Enter Reorder Point"
            // required={true}
          />
        </div>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(InventoryInformation);
