import React, { useState, useEffect, FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Label, Input, Row, Col, Button, Alert } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import Swal from "sweetalert2";
import { Icon } from "../../../components/Component";
import sites from "../../../api/master/sites";
import usersApis from "../../../api/master/users";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
interface SelectOptions {
  label: string;
  value: string;
}
const DeliveryAddressMaster: FC<{
  id?: string | null;
  formAction?: string | null;
  setAction?: () => void;
  toggleModelPopUp: () => void;
}> = ({ id, formAction, toggleModelPopUp }) => {
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [action, setAction] = useState(formAction);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
    reset,
  } = methods;
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);

  const selectedCountry = watch("country");
  const selectedState = watch("state");

  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);

  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data?.data?.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      fetchStatesByCountry(countryOptions[0].value);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (!country) {
          return;
        }
        setValue("country", id);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await sites.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onStateChange = (e: any) => {
    console.log("e", e);
    getDistricts(e);
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  const onSubmit = async (data: any) => {
    if (!action && !id) {
      const response = await deliveryAddressApis.createDeliveryAddress({
        ...data,
        country: 1,
      });
      const { status, message } = response;
      if (status) {
        Swal.fire("Created!", "Delivery Address has been created", "success");
        toggleModelPopUp();
      } else {
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } else if (action === "edit") {
      const response = await deliveryAddressApis.updateDeliveryAddressById(id, {
        ...data,
        country: 1,
      });
      const { status, message } = response;
      if (status) {
        Swal.fire("Updated!", " Delivery Address has been updated", "success");
        toggleModelPopUp();
      } else {
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    }
  };
  const fetchSites = async () => {
    try {
      const res = await sites.fetchSites();
      const { data, status, message } = res;
      if (status) {
        let sitesOptions = data.map((item: any) => {
          return { label: item.name, value: item.siteID.toString() };
        });
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDeliveryAddressById = async () => {
    try {
      const { data, status, message } =
        await deliveryAddressApis.fetchDeliveryAddressById(id);
      if (status) {
        getDistricts(data.state);
        reset((previousValues) => {
          const newData = Object.keys(previousValues).reduce((acc, key) => {
            return {
              ...acc,
              [key]: data[key]?.toString(),
            };
          }, {});
          return newData;
        });
        setValue("state", data.state.toString());
        setValue("district", data.district.toString());
      } else {
        toggleModelPopUp();
        Swal.fire("Failed!", message, "error");
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchSites();
    fetchCountrys();
  }, []);
  useEffect(() => {
    if (id) {
      fetchDeliveryAddressById();
    }
    if (selectedState) {
      getDistricts(selectedState);
    }
  }, [id]);

  return (
    <>
      <h5 className="title">
        {action === "edit"
          ? "Edit Delivery Address"
          : action === "view"
          ? "View Delivery Address"
          : "Create Delivery Address"}
      </h5>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <div style={{ marginTop: "15px" }}></div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-4">
              <FormInput
                name="referenceID"
                label="Delivery Address ID"
                required={true}
                placeholder="Enter Delivery Address ID"
              />
            </div>
            <div className="col-md-4">
              <FormSelect
                name="siteId"
                label="Construction Site"
                options={sitesOptions}
                required={true}
                placeholder="Select Site"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                name="deliveryLocationName"
                label="Delivery Location Name"
                disabled={action === "view"}
                type="text"
                placeholder="Enter Delivery Location Name"
                required={true}
              />
            </div>
          </div>
          <div style={{ marginTop: "15px" }}></div>
          <div className="row">
            <div className="col-md-4">
              <FormSelect
                name="state"
                label="State"
                options={states}
                required={true}
                placeholder="Select State"
                onChange={(ev) => onStateChange(ev)}
              />
            </div>
            <div className="col-md-4">
              <FormSelect
                name="district"
                label="District"
                options={districtOptions}
                required={true}
                placeholder="Select District"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                name="city"
                label="City"
                placeholder="Enter City"
                required={true}
                type="text"
              />
            </div>

            <div style={{ marginTop: "15px" }}></div>
            <div className="col-md-4">
              <FormInput
                type="text"
                name="addressLine1"
                label="Address Lane 1"
                required={true}
                placeholder="Enter Address"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="text"
                name="addressLine2"
                label="Address Lane 2"
                placeholder="Enter Address"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="number"
                name="pincode"
                label="Pincode/Zipcode"
                placeholder="Enter Pincode/Zipcode"
                required={true}
              />
            </div>
          </div>
          <div style={{ marginTop: "15px" }}></div>

          <div className="row">
            <div className="col-md-4">
              <FormInput
                type="text"
                name="contactPersonName"
                label="Contact Person"
                placeholder="Enter Contact Person"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="number"
                name="mobileNumber"
                label="Contact Mobile"
                placeholder="Enter Contact Mobile"
                pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="text"
                name="email"
                label="Contact Mail"
                placeholder="Enter Contact Mail"
                pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
              />
            </div>
          </div>

          {action !== "view" ? (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      <span>{action === "edit" ? "Update" : "Submit"}</span>
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleModelPopUp();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          ) : (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <div
                      className="btn btn-primary btn-md"
                      color="primary"
                      onClick={() => setAction("edit")}
                    >
                      <Icon name="pen" />
                      <span>Edit</span>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default DeliveryAddressMaster;
