import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Icon } from "../../components/Component";
import { Modal } from "antd";
interface SignatureModalProps {
  modal: boolean;
  toggle: () => void;
  onSave: (croppedImage: string) => void;
}
const SignatureModal: React.FC<SignatureModalProps> = ({
  modal,
  toggle,
  onSave,
}) => {
  const [showSignaturePad, setShowSignaturePad] = useState(false);

  const sigCanvas = useRef<any>(null);

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const saveSignature = () => {
    const dataUrl = sigCanvas?.current?.toDataURL();
    if (dataUrl) {
      onSave(dataUrl);
    }
  };

  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        setShowSignaturePad(true);
      }, 100);
    }
  }, [modal]);

  return (
    <div>
      <Modal
        open={modal}
        title="Signature"
        onCancel={toggle}
        footer={
          <>
            <ul className="m-auto d-flex align-items-center justify-content-end p-0">
              <li className="pt-0 pb-0 p-2">
                <Button
                  className="btn btn-primary btn-md"
                  type="submit"
                  color="primary"
                  onClick={saveSignature}
                >
                  Save
                </Button>
              </li>
              <li className="pt-0 pb-0">
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </li>
            </ul>
          </>
        }
      >
        {showSignaturePad && (
          <div style={{ height: "400px" }}>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                className: "sigCanvas",
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Button
              className="btn btn-md"
              type="button"
              color=""
              onClick={clearSignature}
            >
              <Icon name="redo" />
              <span>Reset</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignatureModal;
