import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import {
  Icon,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import FormCheckbox from "../../form-components/CheckboxComponent";
import Swal from "sweetalert2";
const ComplianceandCertifications: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };

  const regulatoryCompliancedocsUploadWatch = watch(
    "regulatoryCompliancedocsUpload"
  );
  const licensesAndPermitsdocsUploadWatch = watch(
    "licensesAndPermitsdocsUpload"
  );
  const insuranceCoveragedocsUploadWatch = watch("insuranceCoveragedocsUpload");
  const ScopeofWorkdocsUploadWatch = watch("ScopeofWorkdocsUpload");
  const BillingandPaymentTermsdocsUploadWatch = watch(
    "BillingandPaymentTermsdocsUpload"
  );
  const contractTermsdocsUploadWatch = watch("contractTermsdocsUpload");
  const environmentalCompliancedocsUploadWatch = watch(
    "environmentalCompliancedocsUpload"
  );
  const wasteManagementProceduresdocsUploadWatch = watch(
    "wasteManagementProceduresdocsUpload"
  );
  const qualityAssuranceProceduresdocsUploadWatch = watch(
    "qualityAssurancePlandocsUpload"
  );
  const disputeResolutionProcessdocsUploadWatch = watch(
    "disputeResolutionProcessdocsUpload"
  );

  useEffect(() => {
    const fileSizeCheck = (files: any) => {
      if (files && files[0]?.size && files[0].size > 2097152) {
        Swal.fire({
          icon: "error",
          title: "File size should be less than 2MB.",
        });
      }
    };

    fileSizeCheck(regulatoryCompliancedocsUploadWatch);
    fileSizeCheck(licensesAndPermitsdocsUploadWatch);
    fileSizeCheck(insuranceCoveragedocsUploadWatch);
    fileSizeCheck(ScopeofWorkdocsUploadWatch);
    fileSizeCheck(BillingandPaymentTermsdocsUploadWatch);
    fileSizeCheck(contractTermsdocsUploadWatch);
    fileSizeCheck(environmentalCompliancedocsUploadWatch);
    fileSizeCheck(wasteManagementProceduresdocsUploadWatch);
    fileSizeCheck(qualityAssuranceProceduresdocsUploadWatch);
    fileSizeCheck(disputeResolutionProcessdocsUploadWatch);
    if (
      regulatoryCompliancedocsUploadWatch &&
      regulatoryCompliancedocsUploadWatch[0]?.size &&
      regulatoryCompliancedocsUploadWatch[0].size > 2097152
    ) {
      setValue("files", null);
      Swal.fire({
        icon: "error",
        title: "File size should be less than 2MB.",
      });
    }
  }, [
    regulatoryCompliancedocsUploadWatch,
    licensesAndPermitsdocsUploadWatch,
    insuranceCoveragedocsUploadWatch,
    ScopeofWorkdocsUploadWatch,
    BillingandPaymentTermsdocsUploadWatch,
    contractTermsdocsUploadWatch,
    environmentalCompliancedocsUploadWatch,
    wasteManagementProceduresdocsUploadWatch,
    qualityAssuranceProceduresdocsUploadWatch,
    disputeResolutionProcessdocsUploadWatch,
  ]);

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <Col lg="4">
          {regulatoryCompliancedocsUploadWatch &&
            regulatoryCompliancedocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"file"}>
                    Upload Cancelled Cheque
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`file-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{regulatoryCompliancedocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("regulatoryCompliancedocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                regulatoryCompliancedocsUploadWatch &&
                regulatoryCompliancedocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="regulatoryCompliancedocsUpload"
              label="Regulatory Compliance Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {licensesAndPermitsdocsUploadWatch &&
            licensesAndPermitsdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"licensesAndPermitsdocsUpload"}
                  >
                    Licenses And Permits Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`licensesAndPermitsdocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{licensesAndPermitsdocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("licensesAndPermitsdocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                licensesAndPermitsdocsUploadWatch &&
                licensesAndPermitsdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="licensesAndPermitsdocsUpload"
              label="Licenses And Permits Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {insuranceCoveragedocsUploadWatch &&
            insuranceCoveragedocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"insuranceCoveragedocsUpload"}
                  >
                    Insurance Coverage Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`insuranceCoveragedocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{insuranceCoveragedocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("insuranceCoveragedocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                insuranceCoveragedocsUploadWatch &&
                insuranceCoveragedocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="insuranceCoveragedocsUpload"
              label="Insurance Coverage Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {ScopeofWorkdocsUploadWatch &&
            ScopeofWorkdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"ScopeofWorkdocsUpload"}
                  >
                    Scope of Work Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`ScopeofWorkdocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{ScopeofWorkdocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("ScopeofWorkdocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                ScopeofWorkdocsUploadWatch &&
                ScopeofWorkdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="ScopeofWorkdocsUpload"
              label="Scope of Work Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {BillingandPaymentTermsdocsUploadWatch &&
            BillingandPaymentTermsdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"BillingandPaymentTermsdocsUpload"}
                  >
                    Billing and Payment Terms Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`BillingandPaymentTermsdocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{BillingandPaymentTermsdocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("BillingandPaymentTermsdocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                BillingandPaymentTermsdocsUploadWatch &&
                BillingandPaymentTermsdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="BillingandPaymentTermsdocsUpload"
              label="Billing and Payment Terms Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {contractTermsdocsUploadWatch &&
            contractTermsdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"contractTermsdocsUpload"}
                  >
                    Contract Terms Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`contractTermsdocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{contractTermsdocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("contractTermsdocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                contractTermsdocsUploadWatch &&
                contractTermsdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="contractTermsdocsUpload"
              label="Contract Terms Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {environmentalCompliancedocsUploadWatch &&
            environmentalCompliancedocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"environmentalCompliancedocsUpload"}
                  >
                    Environmental Compliance Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`environmentalCompliancedocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{environmentalCompliancedocsUploadWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("environmentalCompliancedocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                environmentalCompliancedocsUploadWatch &&
                environmentalCompliancedocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="environmentalCompliancedocsUpload"
              label="Environmental Compliance Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {wasteManagementProceduresdocsUploadWatch &&
            wasteManagementProceduresdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"wasteManagementProceduresdocsUpload"}
                  >
                    Waste Management Procedures Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`wasteManagementProceduresdocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>
                    {wasteManagementProceduresdocsUploadWatch[0]?.name}
                  </span>
                  <span
                    onClick={() => {
                      setValue("wasteManagementProceduresdocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                wasteManagementProceduresdocsUploadWatch &&
                wasteManagementProceduresdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="wasteManagementProceduresdocsUpload"
              label="Waste Management Procedures Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {qualityAssuranceProceduresdocsUploadWatch &&
            qualityAssuranceProceduresdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"qualityAssurancePlandocsUpload"}
                  >
                    Quality Assurance Plan Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`qualityAssurancePlandocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>
                    {qualityAssuranceProceduresdocsUploadWatch[0]?.name}
                  </span>
                  <span
                    onClick={() => {
                      setValue("qualityAssurancePlandocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                qualityAssuranceProceduresdocsUploadWatch &&
                qualityAssuranceProceduresdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="qualityAssurancePlandocsUpload"
              label="Quality Assurance Plan Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        <Col lg="4">
          {disputeResolutionProcessdocsUploadWatch &&
            disputeResolutionProcessdocsUploadWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label
                    className="form-label"
                    htmlFor={"disputeResolutionProcessdocsUpload"}
                  >
                    Dispute Resolution Process Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`disputeResolutionProcessdocsUpload-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>
                    {disputeResolutionProcessdocsUploadWatch[0]?.name}
                  </span>
                  <span
                    onClick={() => {
                      setValue("disputeResolutionProcessdocsUpload", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
          <div
            style={{
              display: `${
                disputeResolutionProcessdocsUploadWatch &&
                disputeResolutionProcessdocsUploadWatch[0]?.name
                  ? "none"
                  : "block"
              }`,
            }}
          >
            <FormInput
              name="disputeResolutionProcessdocsUpload"
              label="Dispute Resolution Process Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </div>
        </Col>

        {/* <Col md="6">
          <FormInput
            name="regulatoryCompliancedocsUpload"
            label="Regulatory Compliance Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {regulatoryCompliancedocsUploadWatch && (
            <div>{regulatoryCompliancedocsUploadWatch[0]?.name}</div>
          )}
        </Col> */}
        {/* <Col md="6">
          <FormInput
            name="licensesAndPermitsdocsUpload"
            label="Licenses And Permits Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {licensesAndPermitsdocsUploadWatch && (
            <div>{licensesAndPermitsdocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="insuranceCoveragedocsUpload"
            label="Insurance Coverage Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {insuranceCoveragedocsUploadWatch && (
            <div>{insuranceCoveragedocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="ScopeofWorkdocsUpload"
            label="Scope of Work Docs Upload"
            type="file"
            placeholder="Docs Upload"
            // disabled={action === "view"}
          />
          {ScopeofWorkdocsUploadWatch && (
            <div>{ScopeofWorkdocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="BillingandPaymentTermsdocsUpload"
            label="Billing and Payment Terms Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {BillingandPaymentTermsdocsUploadWatch && (
            <div>{BillingandPaymentTermsdocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="contractTermsdocsUpload"
            label="Contract Terms Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {contractTermsdocsUploadWatch && (
            <div>{contractTermsdocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="environmentalCompliancedocsUpload"
            label="Environmental Compliance Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {environmentalCompliancedocsUploadWatch && (
            <div>{environmentalCompliancedocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="wasteManagementProceduresdocsUpload"
            label="Waste Management Procedures Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {wasteManagementProceduresdocsUploadWatch && (
            <div>{wasteManagementProceduresdocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="qualityAssurancePlandocsUpload"
            label="Quality Assurance Plan Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {qualityAssuranceProceduresdocsUploadWatch && (
            <div>{qualityAssuranceProceduresdocsUploadWatch[0]?.name}</div>
          )}
        </Col>
        <Col md="6">
          <FormInput
            name="disputeResolutionProcessdocsUpload"
            label="Dispute Resolution Process Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
          {disputeResolutionProcessdocsUploadWatch && (
            <div>{disputeResolutionProcessdocsUploadWatch[0]?.name}</div>
          )}
        </Col> */}
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(ComplianceandCertifications);
