import makeApiCall from "../master/axiosIndex";

const purchaseOrderCreation = {
  purchaseOrderCreationMethod: (data: any) =>
    makeApiCall({
      url: "/order-vs-billing/submitOrder",
      method: "post",
      data,
    }),
  fetchOrder: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/order-vs-billing/fetchOrder?orderNumber=${id}`,
    }),
  purchaseOrderUpdateMethod: (data: any, orderNumber: any) =>
    makeApiCall({
      url: `/order-vs-billing/amendmentOrder/${orderNumber}`,
      method: "post",
      data,
    }),
  fetchBuyerLinkedSupplierListMethod: (): Promise<any> =>
    makeApiCall<any>({
      url: "/order-vs-billing/fetchBuyerLinkedSupplierList",
      method: "get",
    }),
};

export default purchaseOrderCreation;
