import makeApiCall from "./axiosIndex";

const rolesApi = {
  fetchRolesData: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchRole" }),
  deleteRoleById: (RoleId: string): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteRoleById?id=${RoleId}`,
    }),
  createRole: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createRole",
      data,
    }),
  updateRoleById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateRoleById`,
      params: { id },
      data,
    }),
  fetchRoleById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchRoleById`,
      params: { id: id },
    }),
  fetchRoleWithDeptId: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchRoleWithDeptId`,
      params: { id },
    }),
  getModuleConfig: (): Promise<any> => {
    return makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchModuleConfig",
    });
  },
  createReportingHierarchy: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createReportingHierarchy",
      data,
    }),
  fetchReportingHierarchy: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/buyer/fetchReportingHierarchy",
    }),
  updateReportingHierarchy: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: "/buyer/updateReportingHierarchy",
      params: { id },
      data,
    }),
  fetchReportingHierarchyById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchReportingHierarchyById`,
      params: { id },
    }),
  deleteReportingHierarchyById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteReportingHierarchyById`,
      params: { id },
    }),
  fetchHierarchyUsersByModuleId:(moduleId:any,siteId:any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchHierarchyUsersByModuleId`,
      params: { id: moduleId,siteId },
    }),
};

export default rolesApi;
