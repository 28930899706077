import makeApiCall from "./axiosIndex";

const GeneralLedgerApis = {
    fetchLedger: (): Promise<any> => makeApiCall<any>({ method: 'get', url: '/buyer/fetchLedger' }),
    createLedger: (data: any): Promise<any> => makeApiCall<any>({
        method: 'post',
        url: '/buyer/createLedger',
        data
    }),
    updateLedgerById: (id: any, data: any): Promise<any> => makeApiCall<any>({
        method: 'put',
        url: `/buyer/updateLedgerById`,
        params: { id: id },
        data
    }),
    fetchLedgerById: (id: any): Promise<any> => makeApiCall<any>({
        method: 'get',
        url: `/buyer/fetchLedgerById`,
        params: { id }
    }),
    deleteLedgerById: (id: any): Promise<any> => makeApiCall<any>({
        method: 'delete',
        url: `/buyer/deleteLedgerById`,
        params: { id }
    })
};

export default GeneralLedgerApis;