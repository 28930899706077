import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
import FormCheckbox from "../../form-components/CheckboxComponent";
const ComplianceandCertifications: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };
  // const [formData, setFormData] = useState({
  //     companyName: "",
  //     contactPerson: "",
  //     email: "",
  //     phoneNumber: "",
  //     mailingAddressLine1: "",
  // });

  // const onInputChange = (e: any) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Scope of Work</h6>
        </div>
        <div className="col-md-6">
          <FormInput
            name="servicesOffered"
            label="Services Offered"
            type="textarea"
            placeholder="Enter Services Offered"
          />
        </div>

        <div className="col-md-6">
          <FormInput
            name="expertiseAreas"
            label="Expertise Areas"
            type="textarea"
            placeholder="Expertise Areas"
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="docsUpload"
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </div>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <h6>Compliance and Certifications</h6>
        </div>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormCheckbox
            name="regulatoryCompliance"
            optionLabel="Regulatory Compliance"
            size={"small"}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormCheckbox
            name="licensesAndPermits"
            optionLabel="Licenses and Permits"
            size={"small"}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormCheckbox
            name="insuranceCoverage"
            optionLabel="Insurance Coverage"
            size={"small"}
          />
        </Col>
        <Col
          md="6"
          sm={undefined}
          lg={undefined}
          xxl={undefined}
          size={undefined}
          className={undefined}
        >
          <FormInput
            name="docsUpload"
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
            // disabled={action === "view"}
          />
        </Col>
        <div className="col-md-12" style={{ marginTop: 15}}>
          <h6>Project Experience</h6>
        </div>
        <div className="col-md-6">
          <FormInput
            name="pastProjects"
            label="Past Projects"
            type="textarea"
            placeholder="Enter Past Projects"
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="clientTestimonials"
            label="Client Testimonials"
            type="textarea"
            placeholder="Enter Client Testimonials"
          />
        </div>
        <div className="col-md-6">
          <FormInput
            name="docsUpload"
            label="Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </div>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(ComplianceandCertifications);
