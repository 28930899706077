import React from "react";

const Header_PurchaseRequest: React.FC = (props: any) => {
    return (
        <>
            <div id="prForms" className="steps clearfix">
                <ul>
                    <li className={props.current >= 1 ? "first done" : "first"}>
                        <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                            <span className="number">01</span> <h5>General Details</h5>
                        </a>
                    </li>
                    {/* <li className={props.current >= 2 ? "done" : ""}>
            <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
              <span className="current-info audible">current step: </span>
              <h5>Approvals</h5>
            </a>
          </li> */}
                    <li className={props.current >= 2 ? "done" : ""}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">02</span> <h5>Material/Service Details</h5>
                        </a>
                    </li>
                    {/* <li className={props.current >= 3 ? "done" : ""}>
            <a href="#wizard-01-h-3" onClick={(ev) => ev.preventDefault()}>
              <span className="current-info audible">current step: </span>
              <h5>Others</h5>
            </a>
          </li> */}
                    <li className={props.current === 3 ? "last done" : "last"}>
                        <a href="#wizard-01-h-3" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">03</span> <h5>Preview</h5>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};
export default Header_PurchaseRequest;
