import React, { useEffect, FC } from "react";
import {
    Modal,
    ModalBody,
    Form,
} from "reactstrap";
import {
    Icon,
    Button,
    RSelect,
} from "../../../components/Component";
import {Row,Col} from "reactstrap";
import { useForm } from "react-hook-form";


const EditModal: FC<any> = ({ modal, closeModal, onSubmit, formData, setFormData, filterStatus }) => {
    useEffect(() => {
        reset(formData)
    }, [formData]);
    const { reset, register, handleSubmit, formState: { errors } } = useForm();
    return (

        <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
            <ModalBody>
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        closeModal()
                    }}
                    className="close"
                >
                    <Icon name="cross-sm"></Icon>
                </a>
                <div className="p-2">
                    <h5 className="title">Update User</h5>
                    <div className="mt-4">
                        <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label">Reference ID</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        {...register('referenceID', { required: "This field is required" })}
                                        value={formData.referenceID}
                                        onChange={(e) => setFormData({ ...formData, referenceID: e.target.value })}
                                        placeholder="Enter name" />
                                    {/* {errors.referenceID && <span className="invalid">{errors.referenceID.message}</span>} */}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label">Dept Name</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        {...register('deptName', {
                                            required: "This field is required",
                                        })}
                                        value={formData.deptName}
                                        onChange={(e) => setFormData({ ...formData, deptName: e.target.value })}
                                        placeholder="Enter Dept Name" />
                                    {/* {errors.deptName && <span className="invalid">{errors.deptName.message}</span>} */}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label">Team Count</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        {...register('teamCount')}
                                        disabled
                                        value={parseFloat(formData.teamCount)}
                                        onChange={(e) => setFormData({ ...formData, teamCount: e.target.value })}
                                        placeholder="Team Count" />
                                    {/* {errors.teamCount && <span className="invalid">{errors.teamCount.message}</span>} */}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label">description</label>
                                    <input
                                        className="form-control"
                                        type="textarea"
                                        {...register('description', { required: "This field is required" })}
                                        value={formData.description}
                                        onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                                    {/* {errors.description && <span className="invalid">{errors.description.message}</span>} */}
                                </div>
                            </Col>
                            <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit">
                                            Update Department
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                closeModal();
                                            }}
                                            className="link link-light"
                                        >
                                            Cancel
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Form>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default EditModal;
