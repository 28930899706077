import React from "react";
import { Row, Col, Card, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

interface PaymentWidgetProps {
  totalPurchases: number;
  purchasesMonthValue: number;
  purchasesWeekValue: number;
  accountsPayables: number;
  payablesMonthValue: number;
  payablesWeekValue: number;
  overdue: number;
  overdueMonthValue: number;
  overdueWeekValue: number;
  currency: string;
}

const PaymentWidgets: React.FC<PaymentWidgetProps> = ({
  totalPurchases,
  purchasesMonthValue,
  purchasesWeekValue,
  accountsPayables,
  payablesMonthValue,
  payablesWeekValue,
  overdue,
  overdueMonthValue,
  overdueWeekValue,
  currency,
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={8}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">Total Invoices</h6>
            </div>
            <div className="card-tools">
              <Tooltip title="Total Purchases">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
            <span className="amount">
              {formatCurrency2(totalPurchases, currency)}
              <Tooltip title={formatCurrency(totalPurchases, currency)}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
          </div>
          <div className="invest-data">
            <div className="invest-data-amount g-2">
              <div className="invest-data-history">
                <div className="title">This Month</div>
                <span className="amount">
                  {formatCurrency2(purchasesMonthValue, currency)}
                  <Tooltip title={formatCurrency(purchasesMonthValue, currency)}>
                    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                  </Tooltip>
                </span>
              </div>
              <div className="invest-data-history">
                <div className="title">This Week</div>
                <span className="amount">
                  {formatCurrency2(purchasesWeekValue, currency)}
                  <Tooltip title={formatCurrency(purchasesWeekValue, currency)}>
                    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                  </Tooltip>
                </span>
              </div>
            </div>
            <div className="invest-data-icon">
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ShoppingCartOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
        </Card>
      </Col>

      <Col span={24} md={8}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">Accounts Payables (Outstanding)</h6>
            </div>
            <div className="card-tools">
              <Tooltip title="Accounts Payables">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
            <span className="amount">
              {formatCurrency2(accountsPayables, currency)}
              <Tooltip title={formatCurrency(accountsPayables, currency)}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
          </div>
          <div className="invest-data">
            <div className="invest-data-amount g-2">
              <div className="invest-data-history">
                <div className="title">This Month</div>
                <span className="amount">
                  {formatCurrency2(payablesMonthValue, currency)}
                  <Tooltip title={formatCurrency(payablesMonthValue, currency)}>
                    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                  </Tooltip>
                </span>
              </div>
              <div className="invest-data-history">
                <div className="title">This Week</div>
                <span className="amount">
                  {formatCurrency2(payablesWeekValue, currency)}
                  <Tooltip title={formatCurrency(payablesWeekValue, currency)}>
                    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                  </Tooltip>
                </span>
              </div>
            </div>
            <div className="invest-data-icon">
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DollarOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
        </Card>
      </Col>

      <Col span={24} md={8}>
        <Card className="card-full">
          <div className="card-title-group align-start mb-0">
            <div className="card-title">
              <h6 className="subtitle">Overdue</h6>
            </div>
            <div className="card-tools">
              <Tooltip title="Overdue Payments">
                <InfoCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          </div>
          <div className="card-amount">
            <span className="amount">
              {formatCurrency2(overdue, currency)}
              <Tooltip title={formatCurrency(overdue, currency)}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
          </div>
          <div className="invest-data">
            <div className="invest-data-amount g-2">
              <div className="invest-data-history">
                <div className="title">This Month</div>
                <span className="amount">
                  {formatCurrency2(overdueMonthValue, currency)}
                  <Tooltip title={formatCurrency(overdueMonthValue, currency)}>
                    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                  </Tooltip>
                </span>
              </div>
              <div className="invest-data-history">
                <div className="title">This Week</div>
                <span className="amount">
                  {formatCurrency2(overdueWeekValue, currency)}
                  <Tooltip title={formatCurrency(overdueWeekValue, currency)}>
                    <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                  </Tooltip>
                </span>
              </div>
            </div>
            <div className="invest-data-icon">
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#5470C6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ExclamationCircleOutlined style={{ color: "#fff", fontSize: 24 }} />
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentWidgets;
