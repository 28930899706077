import makeApiCall from "../master/axiosIndex";

const paymentApis = {
  submitPayment: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/submitPayment",
      method: "post",
      data,
    }),
  runPayment: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/runPayment",
      method: "post",
      data,
    }),
  fetchAllPendingApprovalPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingApprovalPayments",
      method: "get",
    }),
  fetchPayment: (paymentNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/fetchPayment?paymentNumber=${paymentNumber}`,
      method: "get",
    }),
  approvePayment:(paymentNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/approvePayment?paymentNumber=${paymentNumber}`,
      method: "patch",
    }),
  disapprovePayment:(paymentNumber: string, reason: string): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/disapprovePayment?paymentNumber=${paymentNumber}&rejectedReason=${reason}`,
      method: "patch",
    }),
  fetchAllPendingRunPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPendingRunPayments",
      method: "get",
    }),
  fetchAllPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/payment/fetchAllPayments",
      method: "get",
    }),
  fetchAllInvoicePaymentLines:(invoiceNumber: any): Promise<any> =>
    makeApiCall<any>({
      url: `/payment/fetchAllInvoicePaymentLines?invoiceNumber=${invoiceNumber}`,
      method: "get",
    }),
  fetchAllOrgUnUtilizedCreditDebitNotes:(supplierId: any): Promise<any> =>
    makeApiCall<any>({
      url: `/credit-debit-note/fetchAllOrgUnUtilizedCreditDebitNotes?supplierId=${supplierId}`,
      method: "get",
    }),

};

export default paymentApis;
