import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";
import { Steps, Step } from "react-step-builder";
import Header from "./Header";
import GeneralDetails from "./GeneralDetails";
import Materails from "./Invoice_Materails";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Preview from "./Preview";
import { Link } from "react-router-dom";
import Others from "./Others";

interface FileFormat {
  name: string;
  type: string;
  size: string;
  file: any;
}

const config = {
  before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const Main: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [files, setFiles] = useState<FileFormat[]>([]);
  const [materialDetails, setMaterialDetails] = useState<any>();
 
  
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });
  const {watch,register} = formMethods;

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  return (
    <>
      <Head title="Credit Note" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">New Credit Note</BlockTitle>
                  </BlockHeadContent>
                  {/* update the link */}
                  <Link to={`${process.env.PUBLIC_URL}/all-credit-debit-notes`}> 
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block className="mt-1">
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <FormProvider {...formMethods}>
                    <Steps config={config}>
                      <Step component={GeneralDetails} />
                      <Step component={Materails} />
                      <Step component={Others}/>
                      <Step component={Preview} />
                    </Steps>
                  </FormProvider>
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default Main;
