import exportFromJSON from "export-from-json";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from "reactstrap";
import { Icon } from "../Component";

const Export = ({ data }: { data: any }) => {
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 2000);
    }
  }, [modal]);

  const fileName = "user-data";

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(data))
    setModal(true);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle className="btn btn-white btn-outline-light">
          <Icon name="download-cloud"></Icon>
          <span>Export</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => copyToClipboard()}>
              <Icon name="copy"></Icon>
              <span>Copy</span>
          </DropdownItem>
          <DropdownItem onClick={() => exportCSV()}>
            <Icon name="file-text"></Icon>
            <span>CSV</span>
          </DropdownItem>
          <DropdownItem onClick={() => exportExcel()}>
            <Icon name="file-xls"></Icon>
            <span>Excel</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {modal && <Modal
        isOpen={modal}
        className="modal-dialog-centered text-center"
        size="sm"
      >
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">
            Copied {data.length} rows to clipboard
          </div>
        </div>
      </Modal>}
    </React.Fragment>
  );
};

export default Export;
