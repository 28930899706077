import React from "react";
import Pages from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";

const App = () => {
  return (
    <ThemeProvider>
      <Pages />
    </ThemeProvider>
  );
};

export default App;
