import makeApiCall from "./axiosIndex";

const ApprovalHeirarchyApis = {
  fetchApprovalHeirarchy: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchApprovalHierarchy" }),
  deleteApprovalHeirarchyById: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteApprovalHierarchyById`,
      params: { id },
    }),
  createApprovalHeirarchy: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createApprovalHierarchy",
      data,
    }),
  fetchApprovalHeirarchyById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchApprovalHierarchyById`,
      params: { id },
    }),
  updateApprovalHierarchyById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateApprovalHierarchyById?id=${id}`,
      data,
    }),
  fetchApprovalHierarchyByModuleId: (moduleId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchApprovalHierarchyByModuleId?id=${moduleId}`,
    }),
    
};

export default ApprovalHeirarchyApis;
