import React, { ButtonHTMLAttributes, FC } from "react";
import classNames from "classnames";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light'; // Add more color options as needed
    size?: 'sm' | 'md' | 'lg'; // Define size options according to your CSS framework
    outline?: boolean;
    className?: string;
}

const Button: FC<ButtonProps> = ({ color, size, className, outline, disabled, children, ...props }) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: color && !outline,
    [`btn-outline-${color}`]: outline && color,
    [`btn-${size}`]: size,
    disabled,
    [className || '']: className,
  });

  return (
    <button className={buttonClass} disabled={disabled} {...props}>
      {children}
    </button>
  );
};

export default Button;
