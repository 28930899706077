import React, { useState, useEffect, FC } from "react";
import { Card, Badge, Modal, ModalHeader, ModalBody,Button } from "reactstrap";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Icon } from "../../components/Component";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}
interface BuyerDetails {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
  gstin: string;
  pan: string;
  cin: string;
}

interface Lines {
  sno: number;
  itemDescription: string;
  hsnSac: string;
  qty?: number;
  rate: number;
  cgstPercentage: number;
  cgstAmount: number;
  sgstPercentage: number;
  sgstAmount: number;
  taxableAmount: number;
  uom: string;
}

interface Totals {
  taxableAmount: string;
  cgst: string;
  sgst: string;
  total: string;
}

interface Data {
  quoteNumber: string;
  quoteDate: string;
  terms: string;
  expectedShipmentDate: string;
  placeofSupply: string;
  creditDays: number;
  deliveryAddressString: string;
  lines: Lines[];
  totals: Totals;
}

interface FooterData {
  orgName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  pincode: string;
  state: string;
  email: string;
  phoneNumber: string;
  cin: string;
  website: string;
}

interface Extras {
  termsandconditions?: string;
  disclaimer?: string;
}

const PdfGenerator: FC<any> = ({
  buyerDetails,
  supplierDetails,
  Details,
  buyerOrgLogo,
  hasIGST,
}: any) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const [htmlContent, setHtmlContent] = useState(`
    <h1>Welcome to ProcureX</h1>
    <p>
      ProcureX is an innovative <strong>AI-based procurement platform</strong> designed to simplify the work scheduling and site inventory process.
    </p>
    <h2>Why Choose Us?</h2>
    <ul>
      <li>Efficient work scheduling</li>
      <li>Real-time inventory management</li>
      <li>AI-based forecasting and planning</li>
    </ul>
    <p>Thank you for choosing ProcureX, your partner in procurement!</p>
  `);
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const fetchImageAsBase64 = async (url: string): Promise<string> => {
    try {
      const response = await fetch(`${url}`);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            console.log(
              "Image successfully fetched and converted to Base64:",
              reader.result
            ); // Debugging line
            resolve(reader.result as string);
          } else {
            reject(new Error("Failed to read the image data"));
          }
        };
        reader.onerror = () =>
          reject(new Error("Error reading the image data"));
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching the image:", error);
      throw new Error("Failed to fetch the image.");
    }
  };

  const drawBorderedBox = (doc: jsPDF) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Define the margins for the box (e.g., 10px margins)
    const margin = 5;
    const boxX = margin;
    const boxY = 20; // Start after the header
    const boxWidth = pageWidth - 2 * margin;
    const boxHeight = pageHeight - 41; // Leave space for the footer at the bottom

    // Draw the border box
    doc.setDrawColor(157, 157, 157);
    doc.setLineWidth(0.2);
    doc.rect(boxX, boxY, boxWidth, boxHeight);
  };
  const generatePDF = async () => {
    const doc = new jsPDF();
    const extras = {
      termsandconditions: `
        <h2 style="text-align:center;">Terms and Conditions</h2>
        <p>All goods are subject to the following terms and conditions:</p>
        <ol>
          <li>The supplier must deliver the items as per the agreed schedule.</li>
          <li>All goods must comply with the agreed specifications and quality standards.</li>
          <li>Late deliveries will incur a penalty of 5% per day of delay.</li>
        </ol>
      `,
      disclaimer: `
        <h2 style="text-align:center;">Disclaimer</h2>
        <p>The information contained in this document is confidential and intended solely for the recipient. Any unauthorized use, disclosure, or distribution is prohibited.</p>
        <p>The supplier assumes full responsibility for any errors or omissions contained herein.</p>
      `,
    };

    //const leftLogo = await fetchImageAsBase64('http://localhost:3000/static/media/logo-dark2x.8758ccf4e7a33281ce73.png');

    const addImageWithMaxHeight = async (
      doc: any,
      imageData: string,
      x: number,
      y: number,
      maxHeight: number
    ) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = imageData;

        img.onload = function () {
          const aspectRatio = img.width / img.height;

          // Ensure that the image height is capped at maxHeight, adjusting width accordingly
          const height = Math.min(img.height, maxHeight); // Restrict height to maxHeight
          const width = height * aspectRatio; // Adjust width based on aspect ratio

          doc.addImage(img.src, "PNG", x, y, width, height); // Add image to the PDF
          resolve();
        };

        img.onerror = function (error) {
          console.error("Image failed to load", error);
          reject(new Error("Image load failed"));
        };
      });
    };

    const addHeader = async (doc: jsPDF, leftLogo: string | null) => {
      if (leftLogo) {
        // If the image fetch was successful, add the image
        await addImageWithMaxHeight(doc, leftLogo, 10, 5, 10); // Await to ensure it finishes before continuing
      } else {
        // If the image fetch failed, print fallback text
        doc.setFontSize(20);
        doc.text("Procurex", 10, 20); // Fallback text in case of image fetch failure
      }
    };

    const addFooter = async (doc: jsPDF) => {
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(7);

      const line1 = `${buyerDetails.name}`;
      const line2 = `${buyerDetails.addressLine1}${buyerDetails.addressLine2}, ${buyerDetails?.city} ${buyerDetails.pincode}, ${buyerDetails.state}, ${buyerDetails.country}`;
      const line3 = `CIN: ${buyerDetails.cin} `;
      const line4 = `GSTIN: ${buyerDetails.gstin} | PAN: ${buyerDetails.pan}`;

      const lastLineY = pageHeight - 7;
      const lineSpacing = 3;
      const startY = lastLineY - 3 * lineSpacing;

      doc.text(line1, pageWidth / 2, startY, { align: "center" });
      doc.text(line2, pageWidth / 2, startY + lineSpacing, { align: "center" });
      doc.text(line3, pageWidth / 2, startY + 2 * lineSpacing, {
        align: "center",
      });
      doc.text(line4, pageWidth / 2, startY + 3 * lineSpacing, {
        align: "center",
      });

      const pageTextY = startY - 8;
      doc.text("This PO is governed by Terms & Conditions", 10, pageTextY);
    };
    const addGeneralDetailsSection = async (
      doc: jsPDF,
      buyerDetails: BuyerDetails,
      Details: Data,
      supplierDetails: any
    ) => {
      const sectionY = 16; // Starting Y position for the General Details Section
      const pageWidth = doc.internal.pageSize.getWidth();

      // Set font for heading and add text for General Details Section
      doc.setFontSize(11);
      doc.setFont("helvetica", "bold"); // Bold for the heading
      doc.setTextColor(0, 0, 0); // Black text color

      let currentY = sectionY + 10; // Keep track of the current Y position

      // Add buyer details
      if (buyerDetails) {
        doc.text(buyerDetails.name || "", 10, currentY);
        currentY += 5; // Move Y position for the next line

        doc.setFontSize(8);
        doc.setFont("helvetica", "normal");
        doc.text(
          `${buyerDetails.addressLine1 || ""} ${
            buyerDetails.addressLine2 || ""
          }`,
          10,
          currentY
        );
        currentY += 5;
        doc.text(
          `${buyerDetails.city || ""}, ${buyerDetails.state || ""}, ${
            buyerDetails.pincode || ""
          }`,
          10,
          currentY
        );

        currentY += 5;
        doc.text(`${buyerDetails.country || ""}`, 10, currentY);
        currentY += 5;
        doc.text(`GSTIN: ${buyerDetails.gstin || ""}`, 10, currentY);
        currentY += 5;
        doc.text(`PAN: ${buyerDetails.pan || ""}`, 10, currentY);
        currentY += 5;
        doc.text(`CIN: ${buyerDetails.cin || ""}`, 10, currentY);
        currentY += 10; // Add extra space after buyer details
      }

      doc.setFontSize(20);
      doc.setFont("helvetica", "bold");
      const purchaseOrderText = "PURCHASE REQUEST";

      // Calculate X position for the text to be right-aligned
      const textWidth = doc.getTextWidth(purchaseOrderText); // Get the width of the text
      const rightX = pageWidth - textWidth - 10; // Position the text with 10px padding from the right edge
      doc.setTextColor(20, 20, 20); // Black text color
      doc.text(purchaseOrderText, rightX, sectionY + 35); // Align the text at the top-right

      // Draw a horizontal line to separate sections
      doc.setDrawColor(150, 150, 150); // Gray color for the line
      doc.line(5, currentY, pageWidth - 5, currentY);
      currentY += 5;

      const sectionWidth = (pageWidth - 10) / 2; // Subtracting the left and right margins
      let leftTableHeight = currentY;
      let rightTableHeight = currentY;

      // Capture the current Y position before the tables are drawn to draw the vertical line later
      const tableStartY = currentY;

      // Left table (details)
      const leftBody: any = [];
      if (Details.quoteNumber) {
        leftBody.push(["PR #", Details.quoteNumber || " "]);
      }
      if (Details.quoteDate) {
        leftBody.push(["Indent Date", Details.quoteDate || " "]);
      }
      if (Details.terms) {
        leftBody.push(["Terms of Payment:", Details.terms || " "]);
      }
      if (Details.creditDays) {
        leftBody.push(["Credit Days", Details.creditDays?.toString() || " "]);
      }
      doc.autoTable({
        startY: currentY,
        theme: "plain",
        showHead: "never",
        body: leftBody,
        styles: {
          fontSize: 8,
          cellPadding: { bottom: 1 },
          overflow: "linebreak",
        },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: "auto", halign: "left", fontStyle: "bold" },
        },
        margin: { left: 10 },
        didDrawCell: (data: any) => {
          leftTableHeight = Math.max(
            leftTableHeight,
            data.cell.y + data.cell.height
          ); // Track maximum Y position for the left table
        },
      });

      // Right table (details)
      const rightBody: any = [];
      if (Details?.placeofSupply) {
        rightBody.push(["Place of Supply", Details?.placeofSupply || " "]);
      }
      if (Details.expectedShipmentDate) {
        rightBody.push([
          "Expected Shipment Date:",
          Details.expectedShipmentDate || " ",
        ]);
      }
      if (Details?.deliveryAddressString) {
        rightBody.push([
          "Delivery Address",
          Details?.deliveryAddressString || " ",
        ]);
      }
      doc.autoTable({
        startY: currentY,
        margin: { left: sectionWidth + 10 },
        theme: "plain",
        showHead: "never",
        body: rightBody,
        styles: {
          fontSize: 8,
          cellPadding: { bottom: 1 },
          overflow: "linebreak",
        },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: "auto", halign: "left", fontStyle: "bold" },
        },
        didDrawCell: (data: any) => {
          rightTableHeight = Math.max(
            rightTableHeight,
            data.cell.y + data.cell.height
          ); // Track maximum Y position for the right table
        },
      });

      const maxHeight = Math.max(leftTableHeight, rightTableHeight); // Calculate the maximum height

      // Draw the vertical line separator between the left and right tables without any gap on top and bottom
      doc.setDrawColor(150, 150, 150); // Gray color for the line
      doc.line(sectionWidth + 5, tableStartY, sectionWidth + 5, maxHeight + 5); // Ensure the line extends beyond the bottom by 5 units

      // Add Supplier Details section after the tables
      currentY = maxHeight + 10; // Move below the tables
      doc.setFillColor(200, 200, 200);
      doc.rect(5, currentY, pageWidth - 10, 6, "F"); // Title background
      return currentY; // Return the final Y position to calculate the next section start point
    };

    const addTable = (doc: any, Details: any, currentY: any) => {
      const mainTitles: any = [
        { content: "Sno", rowSpan: 2 },
        { content: "Material & Description", rowSpan: 2 },
        { content: "QTY", rowSpan: 2 },
      ];
      
      const subTitles:any =[]
      const head = [mainTitles, subTitles];

      const rows = Details?.lines?.map((line: any, index: any) => {
        const row = [
          (index + 1).toString(),
          line.itemDescription,
          line.qty || "",
        ];
       row.push(line.taxableAmount.toFixed(2));
        return row;
      });

      let footer: any = [
          ["", "", "", "", "", "", "", "", { content: "" }],
          ["", "", "", "", "", "", "", "", { content: "" }],
          ["", "", "", "", "", "", "", "", { content: "" }],
          [
            // "",
            // "",
            // "",
            // "",
            // "",
            // "",
            // "",
            { content: "Authorised Signature", colSpan: 2, halign: "middle" },
            "",
          ],
          [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            { content: "", styles: { halign: "middle" } },
          ],
        ];


      doc.autoTable({
        head,
        body: rows,
        foot: footer,
        startY: currentY,
        pageBreak: "auto",
        columnStyles: {
          4: { halign: "right" }, // Rate column width, right-aligned
          5: { halign: "right" }, // CGST % column width, right-aligned
          6: { halign: "right" }, // CGST Amt column width, right-aligned
          7: { halign: "right" }, // SGST % column width, right-aligned
          8: { halign: "right" }, // SGST Amt column width, right-aligned
          9: { halign: "right" }, // Taxable Amount column width, right-aligned
        },

        didParseCell: (data: any) => {
          // Align the text of the last column ('Taxable Amount', 'CGST (5%)', 'SGST (5%)', 'Total')

          if (data.section === "foot" && data.column.index === 7) {
            data.cell.styles.halign = "right"; // Manually set right alignment
          }
          if (data.section === "foot" && data.column.index === 9) {
            data.cell.styles.halign = "left"; // Manually set right alignment
          }
          // Make 'Total' row bold
          if (
            (data.section === "foot" && data.row.index === 3) ||
            (data.section === "foot" && data.row.index === 2 && hasIGST)
          ) {
            data.cell.styles.fontStyle = "bold"; // Set bold style for the "Total" row
            data.cell.styles.halign = "right";
          } else if (data.section === "foot") {
            data.cell.styles.fontStyle = "normal"; // Ensure other rows are not bold
            data.cell.styles.halign = "right";
          }
        },
        didDrawCell: (data: any) => {
          const { cell } = data;

          doc.setDrawColor(150, 150, 150);
       },
        headStyles: {
          fontSize: 9,
          fillColor: [227, 227, 227],
          textColor: [31, 31, 31],
          valign: "middle",
          cellPadding: 2.5,
          lineWidth: 0.5,
          lineHeight: 1.5,
        },
        footStyles: {
          fontSize: 9,
          textColor: [31, 31, 31],
          lineWidth: 0,
        },
        styles: {
          fillColor: [255, 255, 255],
          fontSize: 8,
          lineWidth: 0.2,
          cellPadding: 1.5,
          overflow: "linebreak",
        },

        theme: "grid",
        margin: { top: 20, bottom: 20, left: 5.1, right: 5.1 },
        showFoot: "lastPage",
      });

      // Update page number for all pages
      const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageText = `Page ${i} of ${pageCount}`;
        doc.text(pageText, pageWidth - 20, pageHeight - 10, { align: "right" });
      }
    };

    // Generate PDF
    // const leftLogo = await fetchImageAsBase64(
    //   `https://procurexdevuatstg.blob.core.windows.net/procurexuatblob/prc8.png`
    // );
    const leftLogo = await fetchImageAsBase64(buyerOrgLogo);

    const currentY = await addGeneralDetailsSection(
      doc,
      buyerDetails,
      Details,
      supplierDetails
    );

    addTable(doc, Details, currentY);
    const applyHeaderAndFooter = async () => {
      const totalPages = doc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        await addHeader(doc, leftLogo);
        await addFooter(doc);
        drawBorderedBox(doc); // Ensure borders are added to each page if needed
      }
    };

    await applyHeaderAndFooter();

    // Generate the PDF as a Blob
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl); // Set the Blob URL to display the PDF inline
  };
  return (
    <>
      <Button type="button" color="" onClick={generatePDF}>
        <Icon name="file-pdf"/> PDF 
      </Button>
      {pdfUrl && (
        <Modal
          isOpen={pdfUrl !== null}
          toggle={() => setPdfUrl(null)}
          className="modal-dialog-centered"
          size={"xl"}
        >
          <ModalHeader toggle={() => setPdfUrl(null)}>Purchase Request Preview</ModalHeader>
          <ModalBody>
            <iframe
              src={pdfUrl}
              style={{
                width: "100%",
                height: "80vh",
                border: "none",
              }}
              title="PDF Viewer"
            ></iframe>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default PdfGenerator;
