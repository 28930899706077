import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";
import { Steps, Step } from "react-step-builder";
import Header_Buyer from "./Header_PurchaseOrder";
import PurchaseOrder_GeneralDetails from "./PurchaseOrder_GeneralDetails";
import PurchaseOrder_Materails from "./PurchaseOrder_Materails";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseOrder_Preview from "./PurchaseOrder_Preview";
import { Link } from "react-router-dom";
import PurchaseOrderOthers from "./PurchaseOrderOthers";
import dayjs from "dayjs";
const TAX_SLAP_DROPDOWN = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "5%",
    value: "1",
  },
  {
    label: "12%",
    value: "2",
  },
  {
    label: "18%",
    value: "3",
  },
  {
    label: "28%",
    value: "4",
  },
];
const config = {
  before: Header_Buyer as React.ComponentType<{}> as () => React.ReactElement,
};

const PurchaseOrderMain: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const formMethods = useForm({
    mode: "onChange",
  });
  const { watch, register, reset, setValue } = formMethods;

  const location = useLocation();
  const convertionData = location.state?.data;

  console.log("convertionData", convertionData);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    try {
      const _data = convertionData;
      if (!_data) {
        return;
      }
      const {
        quotationId,
        supplierQuoteId,
        supplierId,
        costCenterId,
        siteId,
        billingAddressId,
        deliveryAddressType,
        deliveryAddressId,
        lineLevelDelivery,
        needByDate,
        paymentTerms,
        paymentPercentage,
        paymentDays,
      }: any = _data?.header;
      const lines = _data?.lines;
      if (quotationId) setValue("quotationId", quotationId);
      if (supplierQuoteId) setValue("supplierQuoteId", supplierQuoteId);
      if (supplierId) setValue("supplierId", supplierId);
      if (costCenterId) setValue("costCenterId", costCenterId);
      if (siteId) setValue("siteId", siteId);

      if (billingAddressId) setValue("billingAddressId", billingAddressId);
      if (deliveryAddressType)
        setValue(
          "deliveryAddressType",
          deliveryAddressType == "ORDER_LEVEL" ? "Order Level" : "Line Level"
        );
      if (deliveryAddressId) setValue("deliveryAddressId", deliveryAddressId);
      if (needByDate) setValue("needByDate", dayjs(needByDate));
      if (lineLevelDelivery)
        setValue(
          "needByDateType",
          lineLevelDelivery == "ORDER_LEVEL" ? "Order Level" : "Line Level"
        );
      if (paymentTerms) setValue("paymentTerms", paymentTerms);
      if (paymentPercentage) setValue("advancePercentage", paymentPercentage);
      if (paymentDays) setValue("creditDays", paymentDays);
      if (lines)
        setValue(
          "items",
          lines?.map((item: any) => {
            let _item: any = {
              ...item,
              key: item?.quotationLineId,
              materialLabel: item.materialName,
              materialName: item.materialId,
              materialCategory: item.categoryId,
              uomLabel: item.uomName,
              dms: item.dms,
              quoteShortListId: item.shortListId,
              supplierQuoteLineId: item.supplierQuoteLineId,
              igst: TAX_SLAP_DROPDOWN?.find(
                (i: any) => i.label == item.igstPercentage + "%"
              )?.value,
              sgst: TAX_SLAP_DROPDOWN?.find(
                (i: any) =>
                  i.label ==
                  Number(item.sgstPercentage) +
                    Number(item.cgstPercentage) +
                    "%"
              )?.value,
              cgst: TAX_SLAP_DROPDOWN?.find(
                (i: any) =>
                  i.label ==
                  Number(item.sgstPercentage) +
                    Number(item.cgstPercentage) +
                    "%"
              )?.value,
            };
            if (item.needByDate) _item.needByDate = dayjs(item.needByDate);
            return _item;
          })
        );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  return (
    <>
      <Head title="User List - Profile" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">New Purchase Order</BlockTitle>
                  </BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/view-purchase-order`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block className="mt-1">
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <FormProvider {...formMethods}>
                    <Steps config={config}>
                      <Step component={PurchaseOrder_GeneralDetails} />
                      <Step component={PurchaseOrder_Materails} />
                      <Step component={PurchaseOrderOthers} />
                      <Step component={PurchaseOrder_Preview} />
                    </Steps>
                  </FormProvider>
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default PurchaseOrderMain;
