import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Select, Empty } from 'antd';
import { formatCurrency } from '../../../utils/Utils';
import { QuotationAnalyticsProps } from './interfaces';

const { Option } = Select;

const MonthlySavingsTrends: React.FC<{ monthlySavingsTrendsData: any }> = ({ monthlySavingsTrendsData }) => {
    console.log("monthlySavingsTrendsData", monthlySavingsTrendsData)
    const months = monthlySavingsTrendsData?.monthOnMonthSavings?.data?.map((item: any) => {
        const shortMonth = item.month.slice(0, 3); // Use slice to get the first three letters
        return `${shortMonth} ${item.year}`; // Return the formatted string
    }); const actualValues = monthlySavingsTrendsData?.monthOnMonthSavings?.data?.map((item: any) => parseFloat(item.totalCostSaving));
    const avgSavings = monthlySavingsTrendsData?.monthOnMonthSavings?.data?.map((item: any) => parseFloat(item.avgSavingPercentage));

    console.log("monthOnMonthSavings", actualValues)
    useEffect(() => {
        const chartDom = document.getElementById('monthly-savings-chart') as HTMLElement;

        // Check if the chart DOM element exists before initializing the chart
        if (chartDom) {
            const myChart = echarts.init(chartDom);

            // Check if there's data for the selected category
            if (actualValues?.length && actualValues.some((value:any) => value > 0)) {
                const option = {
                    grid: {
                        left: '100px',
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {
                        show: false,
                    },
                    xAxis: {
                        type: 'category',
                        data: months,
                        name: 'Months',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            fontSize: 14,
                            padding: 20,
                        },
                        axisLabel: {
                            interval: 0,
                            rotate: 0,
                        },
                    },
                    yAxis: [
                        {
                            type: 'value',
                            name: 'Actual Savings',
                            nameLocation: 'middle',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                fontSize: 14,
                                padding: 60,
                            },
                            position: 'left',
                            axisLabel: {
                                formatter: (value: number) => formatCurrency(value, 'INR'), // Assuming currency is always INR
                            },
                        },
                        {
                            type: 'value',
                            name: 'Savings in Percentage (%)',
                            nameLocation: 'middle',
                            nameTextStyle: {
                                fontWeight: 'bold',
                                padding: 40,
                            },
                            position: 'right',
                            axisLabel: {
                                formatter: (value: number) => `${value}%`,
                            },
                        },
                    ],
                    series: [
                        {
                            name: 'Actual Savings',
                            type: 'bar',
                            data: actualValues,
                            itemStyle: {
                                color: '#5470C6',
                            },
                            barWidth: '30%',
                            barCategoryGap: '50%',
                            yAxisIndex: 0,
                        },
                        {
                            name: 'Savings in Percentage',
                            type: 'line',
                            data: avgSavings,
                            lineStyle: {
                                color: '#3BAEA3',
                            },
                            smooth: true,
                            yAxisIndex: 1,
                        },
                    ],
                };

                myChart.setOption(option);
            } else {
                myChart.clear(); // Clear the chart if there's no data
            }

            // Dispose of the chart instance on cleanup
            return () => {
                myChart.dispose();
            };
        }
    }, [actualValues, avgSavings, months]); // Dependency array updated to include dynamic data

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Month-on-Month Savings Trends</h6>
                    <p>Visual representation of savings trends by material category over the past 12 months.</p>
                </div>
            </div>
            {actualValues?.length && actualValues.some((value:any) => value > 0) ? (
                <div id="monthly-savings-chart" style={{ width: '100%', height: '400px' }} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No Data found for the selected category" />
                </div>
            )}
        </div>
    );
};

export default MonthlySavingsTrends;
