import React from "react";
const Header: React.FC = (props: any) => {
  const NavLables = [
    "Consultant Information",
    // "Consultant Profile",
    "Compliance & Certifications",
    // "Scope of Work",
    // "Project Experience",
    // "Billing & Payment Terms",
    "Contractual Agreements",
    // "Project Access & Coordination",
    "Environmental & Safety Considerations",
    // "Emergency Response &  Contingency Plans",
    // "Conflict Resolution",
  ];
  return (
    <>
      <div className="steps clearfix">
        <ul>
          {NavLables.map((item, index, array) => {
            if (index === array.length-1) {
              return (
                <li className={props.current === index+1 ? "last done" : "last"}>
                  <a
                    href="#wizard-01-h-2"
                    onClick={(ev) => ev.preventDefault()}
                  >
                    <span className="current-info audible">current step: </span>
                    <span className="number">Step {index + 1}</span><h5>{item}</h5>
                  </a>
                </li>
              );
            }
            return (
              <li
                className={
                  props.current >= index+1
                    ? `${index === 0 ? "first done" : "done"}`
                    : `${index === 0 ? "first" : ""}`
                }
              >
                <a href={`#wizard-01-h-${index}`}onClick={(ev) => ev.preventDefault()}>
                  <span className="current-info audible">current step: </span>
                  <span className="number">Step {index + 1}</span> <h5>{item}</h5>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default Header;
