import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: null,
};

export const allowedRoutesSlice = createSlice({
    name: "allowedRoutes",
    initialState,
    reducers: {
        updateAllowedRoutes: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        clearAllowedRoutes: (state) => {
            state.data = [];
            state.loading = false;
            state.error = null;
        },
    }
});

export const {updateAllowedRoutes, clearAllowedRoutes} = allowedRoutesSlice.actions;

export default allowedRoutesSlice.reducer;