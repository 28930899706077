import React from "react";
const Header: React.FC = (props: any) => {
    return (
        <>
           
           <div className="steps clearfix">
                <ul>
                    <li className={props.current >= 1 ? "first done" : "first"}>
                        <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                            <span className="number">01</span> <h5>General Information</h5>
                        </a>
                    </li>
                    
                    <li className={props.current === 2 ? "last done" : ""}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">02</span> <h5>Accessibility</h5>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};
export default Header;
