import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  RSelect,
  UserAvatar,
  TooltipComponent,
} from "../../../components/Component";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import sites from "../../../api/master/sites";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { getDateStructured } from "../../../utils/Utils";
import FormInput from "../../form-components/InputComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormSelect from "../../form-components/SelectComponent";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Role } from "./types/Roles";
import FormCheckbox from "../../form-components/CheckboxComponent";
import rolesApi from "../../../api/master/roles";
import FormTextArea from "../../form-components/TextAreaComponent";
import departments from "../../../api/master/departments";
import FormatDate from "../DateFormater";
import { useSelector } from "react-redux";
interface Accessability {
  module: string;
  create: boolean;
  view: boolean;
  edit: boolean;
  cancle: boolean;
  approve: boolean;
  reject: boolean;
}
interface SelectOptions {
  label: string;
  value: string;
}
const RoleViewDetails: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

    useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { roleID } = useParams();
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState(false);
  const [modalSS, setSSModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [countryOptions, setcountryOptions] = useState([]);
  const [formData, setFormData] = useState({
    roleName: "",
    description: "",
    referenceID: "",
    deptID: "",
    status: "",
    accessibility: [],
  });
  const methods = useForm({
    mode: "onChange",
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const [accessibilityKeys, setaccessibilityKeys] = useState([]);
  const [departmentData, setDepartmentData] = useState<SelectOptions[]>([]);
  // const [accessibilityOptions, setaccessibilityOptions] = useState<any>([
  //   "Sub Feature",
  //   "ADD",
  //   "VIEW",
  //   "EDIT",
  //   "CANCEL",
  //   "APPROVE",
  //   "REJECT",
  // ]);

  const updatedAccessibilityOptions = [
    {
      label: "Sub Feature",
      value: "Sub Feature",
    },
    {
      label: "Create / Add",
      value: "ADD",
    },
    {
      label: "View",
      value: "VIEW",
    },
    {
      label: "Edit / Update",
      value: "EDIT",
    },
    {
      label: " Delete / Inactive",
      value: "CANCEL",
    },
    {
      label: "Approve / Reject",
      value: "APPROVE",
    },
  ];
  const readOnlyAddModules = ["Dashboard"];
  const readOnlyUpdateModules = ["Dashboard"];
  const readOnlyRejectModules = ["Dashboard", "GRN"];
  const readOnlyDeleteModules = [
    "Dashboard",
    "Purchase Request",
    "Quotation",
    "Purchase Order",
    "Invoice",
    "GRN",
    "Payments",
    "Credit Note",
  ];

  const onSubmit = async (data: any) => {
    const accessibility: {
      module: string;
      add: boolean;
      view: boolean;
      edit: boolean;
      cancel: boolean;
      approve: boolean;
      reject: boolean;
    }[] = [];
    accessibilityKeys.forEach((key: SelectOptions) => {
      accessibility.push({
        module: key.value,
        add: data[`${key.value}_add`],
        view: data[`${key.value}_view`],
        edit: data[`${key.value}_edit`],
        cancel: data[`${key.value}_cancel`],
        approve: data[`${key.value}_approve`],
        reject: data[`${key.value}_reject`],
      });
      delete data[`${key.value}_add`];
      delete data[`${key.value}_view`];
      delete data[`${key.value}_edit`];
      delete data[`${key.value}_cancel`];
      delete data[`${key.value}_approve`];
      delete data[`${key.value}_reject`];
    });
    data.accessibility = accessibility;
    console.log(JSON.stringify(data));

    try {
      const { status, message } = await rolesApi.updateRoleById(roleID, data);
      if (status) {
        Swal.fire("Updated!", "Role has been updated", "success");
        fetchRoleById(roleID);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {}
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const data = res.data;
      setaccessibilityKeys(
        data.map((obj: any) => ({
          label: obj.moduleName,
          value: obj.moduleID.toString(),
        }))
      );
    } catch (err) {
      throw err;
    }
  };
  const fetchDept = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        setDepartmentData(
          data.map((obj: any) => ({
            label: obj.deptName,
            value: obj.deptID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    fetchDept();
    fetchModuleConfig();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = roleID;
    console.log("id", id);
    if (id !== undefined || null || "") {
      fetchRoleById(id);
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [roleID]);

  const openEditModal = () => {
    setGIModal(true);
  };

  const fetchRoleById = async (id: any) => {
    try {
      const { data, status, message } = await rolesApi.fetchRoleById(id);
      if (status) {
        const newData = data;
        setData(newData); // Update data with fetched response
        setFormData({
          roleName: newData.roleName,
          description: newData.description,
          referenceID: newData.referenceID,
          status: newData.status,
          deptID: newData?.deptID?.toString(),
          accessibility: [],
        });
        data?.accessibility?.forEach((moduleObj: any) => {
          let moduleName = moduleObj?.module?.toString();
          setValue(`${moduleName}_add`, moduleObj.add);
          setValue(`${moduleName}_view`, moduleObj.view);
          setValue(`${moduleName}_edit`, moduleObj.edit);
          setValue(`${moduleName}_cancel`, moduleObj.cancel);
          setValue(`${moduleName}_approve`, moduleObj.approve);
          setValue(`${moduleName}_reject`, moduleObj.reject);
        });
        setValue("roleName", data.roleName);
        setValue("description", data.description);
        setValue("deptID", data?.deptID?.toString());
        setValue("referenceID", data.referenceID);
        setGIModal(false);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="View Role"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Role Details -{" "}
                    <strong className="text-primary small">
                      {data.roleName}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {allowedActions?.edit?<Button
                    outline
                    className="bg-white d-none d-sm-inline-flex"
                    style={{ marginRight: "10px" }}
                    onClick={() => openEditModal()}
                  >
                    <Icon name="edit-alt-fill"></Icon>
                    <span>Edit</span>
                  </Button>:<></>}
                  <Link to={`${process.env.PUBLIC_URL}/roles`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockHeadContent>
                      <BlockTitle tag="h5">General Information</BlockTitle>
                      <p>Name, Address etc.</p>
                    </BlockHeadContent>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Role Name</div>
                          <div className="data-value">{data.roleName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Description</div>
                          <div className="data-value">{data.description}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Role ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Department Name</div>
                          <div className="data-value">
                            {
                              departmentData?.find(
                                (d: any) => d.value == data.deptID
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Status</div>
                          <div className="data-value">{data.status}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockHeadContent>
                      <BlockTitle tag="h5">Accessability </BlockTitle>
                      <p>Role Accessability Details etc.</p>
                    </BlockHeadContent>
                  </BlockHead>

                  <Card className="card-bordered">
                    <table className="table table-hover" id="accessesbility">
                      <thead>
                        <tr>
                          {updatedAccessibilityOptions.map((item: any) => {
                            return <th key={item.value}>{item.label}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {accessibilityKeys.map((item: any) => {
                          let row = data?.accessibility?.find(
                            (element: any) => element.module == item.value
                          );
                          return (
                            <tr>
                              <td>{item.label}</td>
                              <td>
                                {row?.add ? (
                                  <Icon
                                    name="check-circle-fill"
                                    className="text-success"
                                  />
                                ) : (
                                  <Icon
                                    name="cross-circle-fill"
                                    className="text-danger"
                                  />
                                )}
                              </td>
                              <td>
                                {row?.view ? (
                                  <Icon
                                    name="check-circle-fill"
                                    className="text-success"
                                  />
                                ) : (
                                  <Icon
                                    name="cross-circle-fill"
                                    className="text-danger"
                                  />
                                )}
                              </td>
                              <td>
                                {row?.edit ? (
                                  <Icon
                                    name="check-circle-fill"
                                    className="text-success"
                                  />
                                ) : (
                                  <Icon
                                    name="cross-circle-fill"
                                    className="text-danger"
                                  />
                                )}
                              </td>
                              <td>
                                {row?.cancel ? (
                                  <Icon
                                    name="check-circle-fill"
                                    className="text-success"
                                  />
                                ) : (
                                  <Icon
                                    name="cross-circle-fill"
                                    className="text-danger"
                                  />
                                )}
                              </td>
                              <td>
                                {row?.approve ? (
                                  <Icon
                                    name="check-circle-fill"
                                    className="text-success"
                                  />
                                ) : (
                                  <Icon
                                    name="cross-circle-fill"
                                    className="text-danger"
                                  />
                                )}
                              </td>
                              {/* <td>
                                {row?.reject ? (
                                  <Icon
                                    name="check-circle-fill"
                                    className="text-success"
                                  />
                                ) : (
                                  <Icon
                                    name="cross-circle-fill"
                                    className="text-danger"
                                  />
                                )}
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <ModalBody>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="p-2">
                      <h5 className="title">Edit Role Details </h5>
                      <ul className="nk-nav nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              modalTab === "1" && "active"
                            }`}
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModalTab("1");
                            }}
                            href="#GeneralInformation"
                          >
                            General Information
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              modalTab === "2" && "active"
                            }`}
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModalTab("2");
                            }}
                            href="#Accessability"
                          >
                            Accessability
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={`tab-pane ${
                            modalTab === "1" ? "active" : ""
                          }`}
                          id="GeneralInformation"
                        >
                          <Row className="gy-4">
                            <Col
                              size="4"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <FormInput
                                name="referenceID"
                                label="Internal ID"
                                required={true}
                                type="text"
                                maxLength={10}
                                placeholder="Enter Internal ID"
                              />
                            </Col>
                            <Col
                              size="4"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <FormSelect
                                name="deptID"
                                required={true}
                                label="Department Name"
                                options={departmentData}
                                placeholder="Select Department Name"
                              />
                            </Col>
                            <Col
                              size="4"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <FormInput
                                name="roleName"
                                label="Role Name"
                                required={true}
                                type="text"
                                minLength={2}
                                // maxLength={30}
                                placeholder="Enter Role Name"
                              />
                            </Col>
                            <Col
                              size="12"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <FormTextArea
                                name="description"
                                label="Description"
                                required={true}
                                maxLength={50}
                                placeholder="Enter Role Description"
                              />
                            </Col>
                            <Col
                              size="12"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className={`tab-pane ${
                            modalTab === "2" ? "active" : ""
                          }`}
                          id="Accessability"
                        >
                          <Row className="gy-4">
                            <Col
                              size="12"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <table
                                className="table table-hover"
                                id="accessesbility"
                              >
                                <thead>
                                  <tr>
                                    {updatedAccessibilityOptions.map(
                                      (item: any) => {
                                        return (
                                          <th key={item.value}>{item.label}</th>
                                        );
                                      }
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {accessibilityKeys.map((item: any) => {
                                    return (
                                      <tr>
                                        <td>{item.label}</td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyAddModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_add`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            name={`${item.value}_view`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyUpdateModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_edit`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyDeleteModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_cancel`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyRejectModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_approve`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        {/* <td>
                                          <FormCheckbox
                                            name={`${item.value}_reject`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td> */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Col>
                            <Col
                              size="12"
                              sm={undefined}
                              lg={undefined}
                              md={undefined}
                              xxl={undefined}
                              className={undefined}
                            >
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </ModalBody>
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default RoleViewDetails;
