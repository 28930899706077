import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from '../axiosInstance';
import Cookies from 'js-cookie';

export interface ApiCallConfig {
    method: 'get' | 'post' | 'put' | 'delete'| 'patch';
    url: string;
    data?: any;
    params?: Record<string, any>;
}

async function makeApiCall<T>(config: ApiCallConfig): Promise<T> {
    const accessToken = Cookies.get('access-token');
    try {
        const axiosConfig: AxiosRequestConfig = {
            method: config.method,
            url: config.url,
            data: config.data,
            params: config.params,
            headers: accessToken ? { 'Authorization': `${accessToken}` } : {}
        };

        const response: AxiosResponse<T> = await axiosInstance(axiosConfig);
        return response.data;
    } catch (error: any) {
        console.log("Error intercepted in makeApiCall:", error);
        return error;
    }
}

export default makeApiCall