import React, { useEffect, useState } from 'react';
import { Table, Select, Empty } from 'antd';
import { formatCurrency, formatCurrency2 } from '../../../utils/Utils'; // Assuming this utility formats currency
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { QuotationAnalyticsProps } from './interfaces';
const { Option } = Select;

const Top10NegotiationsByMaterials: React.FC<any> = ({ top10NegotiationsByMaterialsData }) => {
    // const [selectedCategory, setSelectedCategory] = useState<keyof typeof materialsData>('Steel');
    const materials = top10NegotiationsByMaterialsData?.top10NegotiationByMaterials;
    const [materialsData, setMaterialsData] = useState<any>([]);


    useEffect(() => {
        if (materials) {
            const flattenedArray = Object.values(materials).flat();
            if (JSON.stringify(materialsData) !== JSON.stringify(flattenedArray)) {
                setMaterialsData(flattenedArray || []);
            }

        }
    }, [materials, materialsData]);

    const columns = [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',

        },
        {
            title: 'Quotation Value',
            dataIndex: 'quotationValue',
            key: 'quotationValue',
            render: (value: number, record: { currency: string }) => (
                <div>
                    {formatCurrency2(value, record.currency)}
                    <Tooltip title={formatCurrency(value, record.currency)}>
                        <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff', fontSize: 10 }} />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Negotiated Value',
            dataIndex: 'negotiatedValue',
            key: 'negotiatedValue',
            render: (value: number, record: { currency: string }) => (
                <div>
                    {formatCurrency2(value, record.currency)}
                    <Tooltip title={formatCurrency(value, record.currency)}>
                        <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff', fontSize: 10 }} />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: '% Reduced',
            dataIndex: 'percentReduced',
            key: 'percentReduced',
            render: (value: number) => (
                <span style={{ color: 'green' }}>
                    {`${value}%`}
                </span>
            ),
        },
    ];



    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Top 10 Negotiations by Materials</h6>
                    <p>Insights on negotiation savings by material.</p>
                </div>
            </div>
            {materialsData.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={materialsData}
                    rowKey="materialID"
                    pagination={{ pageSize: 5 }}  // Set maximum 5 rows per page
                    size='small'
                    rowClassName={(record, index) => index % 2 === 0 ? '' : 'striped-row'}
                />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No Data found for the selected category" />
                </div>
            )}
        </div>
    );
};

export default Top10NegotiationsByMaterials;
