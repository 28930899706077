import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
    error: null,
};

export const accessibilitySlice = createSlice({
    name: "accessibility",
    initialState,
    reducers: {
        updateAccessibility: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        clearAccessibility: (state) => {
            state.data = [];
            state.loading = false;
            state.error = null;
        },
    },
});

export const { updateAccessibility, clearAccessibility } = accessibilitySlice.actions;
export default accessibilitySlice.reducer;