import React, { useState } from 'react';
import { Drawer, Table, Tooltip, Avatar, Input } from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { formatCurrency, formatCurrency2, getUserLabel } from '../../../utils/Utils'; // Assuming these utilities are available

interface SupplierOverdueData {
  supplierName: string;
  overduePercentage: number;
  overdueAmount: number;
  currency: string;
}

interface SupplierOverdueDrawerProps {
  visible: boolean;
  onClose: () => void;
  supplierOverdueData: SupplierOverdueData[];
}

// const supplierOverdueData: SupplierOverdueData[] = [
//   { supplierName: 'Alpha Supplies', overduePercentage: 15, overdueAmount: 200000, currency: 'INR' },
//   { supplierName: 'Beta Traders', overduePercentage: 10, overdueAmount: 150000, currency: 'INR' },
//   { supplierName: 'Gamma Corp', overduePercentage: 20, overdueAmount: 300000, currency: 'INR' },
//   { supplierName: 'Delta Industries', overduePercentage: 25, overdueAmount: 400000, currency: 'INR' },
//   { supplierName: 'Epsilon Enterprises', overduePercentage: 5, overdueAmount: 100000, currency: 'INR' },
//   // Add more supplier data as needed
// ];

const SupplierOverdueDrawer: React.FC<SupplierOverdueDrawerProps> = ({ visible, onClose,supplierOverdueData }) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filteredData = supplierOverdueData.filter((supplier) =>
    supplier.supplierName.toLowerCase().includes(searchText)
  );

  const columns = [
    {
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      key: 'supplierName',
      sorter: (a: SupplierOverdueData, b: SupplierOverdueData) => a.supplierName.localeCompare(b.supplierName),
      render: (text: string) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ backgroundColor: '#E5E9F2', color: '#526484', marginRight: 8 }}>
            {getUserLabel(text)}
          </Avatar>
          {text}
        </div>
      ),
    },
    {
      title: 'Overdue Percentage',
      dataIndex: 'overduePercentage',
      key: 'overduePercentage',
      sorter: (a: SupplierOverdueData, b: SupplierOverdueData) => a.overduePercentage - b.overduePercentage,
      render: (value: number) => `${Number(value)}%`,
    },
    {
      title: 'Overdue Amount',
      dataIndex: 'overdueAmount',
      key: 'overdueAmount',
      sorter: (a: SupplierOverdueData, b: SupplierOverdueData) => a.overdueAmount - b.overdueAmount,
      render: (value: number, record: SupplierOverdueData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {formatCurrency2(value, record.currency)}
          <Tooltip title={formatCurrency(value, record.currency)}>
            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Drawer
      title="Supplier Overdue Percentages and Amounts"
      placement="right"
      width={'60%'}
      onClose={onClose}
      open={visible}
    >
      <Input
        placeholder="Search by Supplier Name"
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="supplierName"
        pagination={{ pageSize: 5 }}
        size="small"
      />
    </Drawer>
  );
};

export default SupplierOverdueDrawer;
