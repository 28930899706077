import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import sequenceApis from "../../../api/master/sequence";

interface currentState {
  data: string[];
  loading: boolean;
  error: string | null;
}
const initialState: currentState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchSequenceAccess = createAsyncThunk(
  "sequenceAccess/fetchSequenceAccess",
  async (_, { rejectWithValue }) => {
    try {
      const response = await sequenceApis.fetchSequence();
      const { data} = response;
      const today = new Date();

      const activeModules = data
        .filter(
          (item: any) =>
            new Date(item.endDate) > today && item.status === "Active"
        )
        .map((item: any) => item.moduleName);
      return activeModules;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const sequenceAccessSlice = createSlice({
  name: "sequenceAccess",
  initialState,
  reducers: {
    updateSequenceAccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    clearSequenceAccess: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSequenceAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSequenceAccess.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSequenceAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as any;
      });
  },
});

export const { updateSequenceAccess, clearSequenceAccess } =
  sequenceAccessSlice.actions;

export default sequenceAccessSlice.reducer;
