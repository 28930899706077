import React, { useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface CNRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const CreditDebitNoteAside: FC<CNRequestAsideProps> = ({ updateSm, sm }) => {
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    const location = useLocation();

    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "credit-debit-note-new":
                return (
                    <Link
                        to="/credit-debit-note-new"
                        className={isActive(`${process.env.PUBLIC_URL}/credit-debit-note-new`)}
                    >
                        <Icon name="check-fill-c" />
                        <span>Create New</span>
                    </Link>
                );
            case "pending-approval-credit-debit-notes":
                return (
                    <Link
                        to="/pending-approval-credit-debit-notes"
                        className={isActive(`${process.env.PUBLIC_URL}/pending-approval-credit-debit-notes`)}
                    >
                        <Icon name="check-fill-c" />
                        <span>Pending Approval</span>
                    </Link>
                );
            case "all-credit-debit-notes":
                return (
                    <Link
                        to="/all-credit-debit-notes"
                        className={isActive(`${process.env.PUBLIC_URL}/all-credit-debit-notes`)}
                    >
                        <Icon name="eye-fill" />
                        <span>View Credit/Debit Notes</span>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>Credit/Debit Note</h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "credit-debit-note-new",
                        "pending-approval-credit-debit-notes",
                        "all-credit-debit-notes"
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CreditDebitNoteAside;
