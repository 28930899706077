import makeApiCall from "../master/axiosIndex";

const invoiceApis = {
  submitInvoice: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/submitInvoice",
      method: "post",
      data: data,
    }),
  fetchAllInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllInvoices",
      method: "get",
    }),
  fetchAllInvoicesByOrderAndOrderLineNo: (
    orderId: any,
    orderLineId: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllInvoicesByOrderAndOrderLineNo?orderNumber=${orderId}&orderLineNo=${orderLineId}`,
      method: "get",
    }),
  fetchInvoice: (id: string): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchInvoice?invoiceNumber=${id}`,
      method: "get",
    }),
  fetchPayableInvoicesForPayments: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchPayableInvoicesForPayments",
      method: "get",
    }),
  fetchAllPaidInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllPaidInvoices",
      method: "get",
    }),
  fetchAllInvoicesForCreditDebitNote: (orgOrSupplierId: any): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllInvoicesForCreditDebitNote?orgOrSupplierId=${orgOrSupplierId}`,
      method: "get",
    }),
};

export default invoiceApis;
