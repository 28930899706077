import makeApiCall from "./axiosIndex";

const usersApis = {
  fetchBuyerUser: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchBuyerUser" }),

  deleteBuyerById: (buyerId: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteBuyerUserById?id=${buyerId}`,
    }),

  createBuyer: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createBuyerUser",
      data,
    }),

  updateBuyerById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateBuyerUserById`,
      params: { id: id },
      data,
    }),

  fetchBuyerById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchBuyerUserById`,
      params: { id },
    }),

  fetchUsersByRoleId: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchUsersByRoleId`,
      params: { id },
    }),

  fetchRolesData: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchRole`,
    }),

  getDeptData: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchDept`,
    }),

  fetchContractor: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchContractor`,
    }),

  fetchConsultant: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchConsultant`,
    }),

  getCountrys: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/countries`,
    }),

  getStatesByCountry: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/stateByCountry`,
      params: { countryId: id },
    }),

  getDistrictsByState: (stateId: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/districtByState`,
      params: { stateId },
    }),

  getModuleConfig: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchModuleConfig`,
    }),
  getCurrentUser: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/users/currentUser`,
    }),
  editUser: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/users/editUser`,
      data,
    }),
};

export default usersApis;
