import React, { useState, useEffect } from 'react';
import { Drawer, Tabs, Select, Empty, Table, Tooltip, Button, Row, Col } from 'antd';
import * as echarts from 'echarts';
import { HeatMapOutlined, TableOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { formatCurrency2, formatCurrency } from '../../../utils/Utils';

const { TabPane } = Tabs;
const { Option } = Select;

interface Budget {
    budgetID: string;
    budgetName: string;
}

interface BudgetsBySite {
    [key: string]: Budget[];
}

const sites = [
    { siteID: 'site001', siteName: 'Site A' },
    { siteID: 'site002', siteName: 'Site B' },
    { siteID: 'site003', siteName: 'Site C' },
    { siteID: 'site004', siteName: 'Site D' },
    { siteID: 'site005', siteName: 'Site E' },
];

const budgetsBySite: BudgetsBySite = {
    site001: [{ budgetID: 'budget001', budgetName: 'Budget 1' }],
    site002: [{ budgetID: 'budget002', budgetName: 'Budget 2' }],
    site003: [{ budgetID: 'budget003', budgetName: 'Budget 3' }],
    site004: [{ budgetID: 'budget004', budgetName: 'Budget 4' }],
    site005: [{ budgetID: 'budget005', budgetName: 'Budget 5' }],
};

const categories = [
    { categoryID: 'cat001', categoryName: 'Steel' },
    { categoryID: 'cat002', categoryName: 'Cement' },
    { categoryID: 'cat003', categoryName: 'Electricals' },
    { categoryID: 'cat004', categoryName: 'HVAC' },
];

const heatMapData = [
    {
        siteID: 'site001',
        budgetID: 'budget001',
        categoryID: 'cat001',
        materialID: 'mat001',
        materialName: '10 MM TMT Bar',
        overrunPercentage: 25,
    },
    {
        siteID: 'site001',
        budgetID: 'budget001',
        categoryID: 'cat001',
        materialID: 'mat002',
        materialName: '12 MM TMT Bar',
        overrunPercentage: 15,
    },
    {
        siteID: 'site001',
        budgetID: 'budget001',
        categoryID: 'cat002',
        materialID: 'mat003',
        materialName: 'OPC 43 Grade Cement',
        overrunPercentage: 30,
    },
    // More data for different sites, budgets, and categories...
];

const HeatMapWithSiteWiseOverrun: React.FC<{ visible: boolean; onClose: () => void }> = ({
    visible,
    onClose,
}) => {
    const [plotSiteID, setPlotSiteID] = useState<string>('site001');
    const [plotBudgetID, setPlotBudgetID] = useState<string>('budget001');
    const [plotCategoryID, setPlotCategoryID] = useState<string>(categories[0].categoryID);
    const [tableSiteID, setTableSiteID] = useState<string>('site001');
    const [tableBudgetID, setTableBudgetID] = useState<string>('budget001');
    const [tableCategoryID, setTableCategoryID] = useState<string>(categories[0].categoryID);
    const [plotData, setPlotData] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);

    useEffect(() => {
        const filtered = heatMapData.filter(
            (item) =>
                item.siteID === plotSiteID &&
                item.budgetID === plotBudgetID &&
                item.categoryID === plotCategoryID
        );
        setPlotData(filtered);
    }, [plotSiteID, plotBudgetID, plotCategoryID]);

    useEffect(() => {
        const filtered = heatMapData.filter(
            (item) =>
                item.siteID === tableSiteID &&
                item.budgetID === tableBudgetID &&
                item.categoryID === tableCategoryID
        );
        setTableData(filtered);
    }, [tableSiteID, tableBudgetID, tableCategoryID]);

    useEffect(() => {
        if (visible) {
            initializeChart(plotData);
        }
    }, [plotData, visible]);

    const initializeChart = (data: any[]) => {
        const chartDom = document.getElementById('heat-map') as HTMLElement;
        if (chartDom && data.length > 0) {
            const myChart = echarts.init(chartDom);

            const xAxisData = data.map((item) => item.materialName);
            const seriesData = data.map((item) => item.overrunPercentage);

            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}%',
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    name: 'Materials',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 20,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: ['Overrun Percentage'],
                    name: 'Overrun (%)',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 40,
                    },
                },
                visualMap: {
                    min: 0,
                    max: 100,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    bottom: '15%',
                },
                series: [
                    {
                        name: 'Overrun',
                        type: 'heatmap',
                        data: seriesData.map((value, index) => [index, 0, value]),
                        label: {
                            show: true,
                            formatter: '{c}%',
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        } else if (chartDom) {
            const myChart = echarts.init(chartDom);
            myChart.clear();
            myChart.setOption({
                graphic: {
                    type: 'text',
                    left: 'center',
                    top: 'center',
                    style: {
                        text: 'No Data Available',
                        fontSize: 20,
                        fill: '#ccc',
                    },
                },
            });
        }
    };

    return (
        <Drawer
            title="Heat Map with Site-wise Overrun"
            placement="right"
            onClose={onClose}
            open={visible}
            width={'75%'}
        >
            <Tabs defaultActiveKey="plot">
                <TabPane
                    tab={
                        <span>
                            <HeatMapOutlined /> &nbsp;
                            Plot
                        </span>
                    }
                    key="plot"
                >
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col>
                            <label>Site:</label>
                            <Select
                                placeholder="Select Site"
                                style={{ width: 150 }}
                                value={plotSiteID}
                                onChange={(value) => {
                                    setPlotSiteID(value);
                                    setPlotBudgetID(budgetsBySite[value][0].budgetID);
                                }}
                            >
                                {sites.map((site) => (
                                    <Option key={site.siteID} value={site.siteID}>
                                        {site.siteName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <label>Budget:</label>
                            <Select
                                placeholder="Select Budget"
                                style={{ width: 150 }}
                                value={plotBudgetID}
                                onChange={(value) => setPlotBudgetID(value)}
                            >
                                {budgetsBySite[plotSiteID]?.map((budget) => (
                                    <Option key={budget.budgetID} value={budget.budgetID}>
                                        {budget.budgetName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <label>Category:</label>
                            <Select
                                placeholder="Select Category"
                                style={{ width: 150 }}
                                value={plotCategoryID}
                                onChange={(value) => setPlotCategoryID(value)}
                            >
                                {categories.map((category) => (
                                    <Option key={category.categoryID} value={category.categoryID}>
                                        {category.categoryName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={() => initializeChart(plotData)} style={{  }}>
                                Apply Filters
                            </Button>
                        </Col>
                    </Row>
                    {plotData.length > 0 ? (
                        <div id="heat-map" style={{ width: '100%', height: '400px' }} />
                    ) : (
                        <Empty description="No budget details found for this selection" />
                    )}
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            <TableOutlined /> &nbsp;
                            Table View
                        </span>
                    }
                    key="table"
                >
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col>
                            <label>Site:</label>
                            <Select
                                placeholder="Select Site"
                                style={{ width: 150 }}
                                value={tableSiteID}
                                onChange={(value) => {
                                    setTableSiteID(value);
                                    setTableBudgetID(budgetsBySite[value][0].budgetID);
                                }}
                            >
                                {sites.map((site) => (
                                    <Option key={site.siteID} value={site.siteID}>
                                        {site.siteName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <label>Budget:</label>
                            <Select
                                placeholder="Select Budget"
                                style={{ width: 150 }}
                                value={tableBudgetID}
                                onChange={(value) => setTableBudgetID(value)}
                            >
                                {budgetsBySite[tableSiteID]?.map((budget) => (
                                    <Option key={budget.budgetID} value={budget.budgetID}>
                                        {budget.budgetName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <label>Category:</label>
                            <Select
                                placeholder="Select Category"
                                style={{ width: 150 }}
                                value={tableCategoryID}
                                onChange={(value) => setTableCategoryID(value)}
                            >
                                {categories.map((category) => (
                                    <Option key={category.categoryID} value={category.categoryID}>
                                        {category.categoryName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Table
                        columns={[
                            {
                                title: 'Material ID',
                                dataIndex: 'materialID',
                                key: 'materialID',
                            },
                            {
                                title: 'Material Name',
                                dataIndex: 'materialName',
                                key: 'materialName',
                            },
                            {
                                title: 'Overrun Percentage',
                                dataIndex: 'overrunPercentage',
                                key: 'overrunPercentage',
                                render: (value: number) => (
                                    <>
                                        {value}%{' '}
                                        <Tooltip title={`${value}% overrun`}>
                                            <InfoCircleOutlined style={{ fontSize: '12px', marginLeft: 4 }} />
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ]}
                        dataSource={tableData}
                        pagination={{ pageSize: 10 }}
                        rowKey="materialID"
                        rowClassName={(record, index) => (index % 2 === 0 ? '' : 'striped-row')}
                    />
                </TabPane>
            </Tabs>
        </Drawer>
    );
};

export default HeatMapWithSiteWiseOverrun;
