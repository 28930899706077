import React, { useState, useEffect } from "react";
import { useFormContext, FormProvider, Controller, useWatch } from "react-hook-form";
import { Button, Row, Col } from "reactstrap";
import { Collapse, Input, Upload, message, Switch, Checkbox } from 'antd';
import { PaperClipOutlined, SearchOutlined, InboxOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UploadFile } from 'antd/es/upload/interface';
import { useFormData } from './FormDataContext'; // Import your context hook
import { DocumentFormValues, DocumentData, ApprovalData } from './types'; // Import your types
import supplierApis from "../../../api/master/supplier";

interface SelectOptions {
    label: string;
    value: any;
};

const { Panel } = Collapse;
const { Dragger } = Upload;



const RFQOthers: React.FC<{ prev: () => void, next: () => void }> = (props: any) => {
    const methods = useFormContext();

    const { setValue, watch } = methods;
    let formData: any = {
        attachments: [],
    };
    const [fileList, setFileList] = useState<UploadFile[]>(
        formData.attachments || []
    );
    const [searchTerm, setSearchTerm] = useState("");
    const [supplierError, setSupplierError] = useState(false);
    const [publishToPrx, setPublishToPrx] = useState(formData.publishToPrx === 'Y'); // Set switch to ON by default
    const [suppliers, setSuppliers] = useState<any[]>([]);

    const watchedSuppliers = useWatch({
        control: methods.control,
        name: Object.keys(methods.getValues()).filter(key => key.startsWith('supplier_')),
    });

    const previousFormData = watch();
    console.log("previousFormData", previousFormData)

    useEffect(() => {
        const selectedSuppliers = watchedSuppliers.filter(Boolean);
        setSupplierError(selectedSuppliers.length === 0);
    }, [watchedSuppliers]);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    // const attachmentsWatch = watch("attachments");
    const attachmentsWatch = watch("attachments");
    useEffect(() => {
        console.log("attachmentsWatch", attachmentsWatch);
        if (attachmentsWatch) {
            setFileList(attachmentsWatch);
        }
    }, [attachmentsWatch]);

    useEffect(() => {
        console.log("attachmentsWatch", attachmentsWatch);
        if (attachmentsWatch) {
            setFileList(attachmentsWatch);
        }
    }, [attachmentsWatch]);

    const submitForm = (data: any) => {
        setValue("attachments", fileList);


        const selectedSuppliers = Object.keys(data).filter(key => key.startsWith('supplier_') && data[key]);

        if (selectedSuppliers.length === 0) {
            setSupplierError(true);
            return;
        } else {
            setSupplierError(false);
        }



        const supplierIDs = selectedSuppliers.map(key => {
            const match = key.match(/_(\d+)$/);
            return match ? parseInt(match[1], 10) : null;
        }).filter(id => id !== null);



        const supplierNames = supplierIDs.map(id => {
            const supplier = suppliers.find((item: any) => item.supplierID == id);
            return supplier ? supplier.supplierName : null;
        }).filter(name => name !== null);

        const result = supplierNames;

        setValue('SupplierNamesSelected', result);


        props.next();
    };


    const fetchSuppliers = async () => {
        try {
            let allCat: any = [];
            previousFormData?.items?.forEach((item: any) => {
                allCat.push(item.materialCategory);
            });
            const allCatString = allCat.join(',');
            const { data, status, message } = await supplierApis.fetchSuppliersByCategoryId({id:allCatString});
            console.log("status:", status);
            if (status) {
                setSuppliers(data.map((item: any) => ({
                    supplierName: item.supplierName, // Adjust this based on the actual key in your data
                    supplierID: item?.supplierOrgID ? item?.supplierOrgID?.toString() : item?.supplierID?.toString(),
                })));
                console.log("Suppliers:", suppliers);
            } else {
                throw new Error(message);
            }
        } catch (error) {

        }
    }

    const filteredSuppliers = suppliers.filter((supplier: any) =>
        supplier.supplierName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const renderSupplierCheckboxes = (suppliers: { supplierName: string, supplierID: string }[]) => {
        return suppliers.map((supplier) => (
            <div key={supplier.supplierID} style={{ marginBottom: '15px' }}>
                <Controller
                    name={`supplier_${supplier.supplierID}`}
                    control={methods.control}
                    defaultValue={true}
                    render={({ field }) => (
                        <Checkbox {...field} checked={field.value} className="custom-checkbox">
                            {supplier.supplierName}
                        </Checkbox>
                    )}
                />
            </div>
        ));
    };

    const beforeUpload = (file: UploadFile) => {

        const isAllowedType = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/gif",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.type || "");
        if (!isAllowedType) {
            message.error(
                "You can only upload PDF, JPG, JPEG, PNG, GIF, DOC, DOCX files!"
            );
            return Upload.LIST_IGNORE;
        }
        if (file.size && file.size / 1024 / 1024 >= 2) {
            message.error("File must be smaller than 5MB!");
            return Upload.LIST_IGNORE;
        }
        return true;
    };

    const handleChange = (info: { fileList: UploadFile[] }) => {
        setFileList(info.fileList);
    };

    return (
        <FormProvider {...methods}>
            <form className="content clearfix" onSubmit={methods.handleSubmit(submitForm)}>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Select Suppliers" key="1" className={supplierError ? 'error-panel' : ''}>
                        <Input
                            placeholder="Search Suppliers"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            prefix={<SearchOutlined />}
                            className="mb-3"
                        />
                        <Row style={{ marginBottom: '15px', marginTop: '15px' }}>
                            <Col md="4">
                                {renderSupplierCheckboxes(filteredSuppliers.slice(0, Math.ceil(filteredSuppliers.length / 3)))}
                            </Col>
                            <Col md="4">
                                {renderSupplierCheckboxes(filteredSuppliers.slice(Math.ceil(filteredSuppliers.length / 3), Math.ceil(2 * filteredSuppliers.length / 3)))}
                            </Col>
                            <Col md="4">
                                {renderSupplierCheckboxes(filteredSuppliers.slice(Math.ceil(2 * filteredSuppliers.length / 3), filteredSuppliers.length))}
                            </Col>
                        </Row>
                        {supplierError === false && <p style={{ color: 'red' }}>At least one supplier is required to select.</p>}
                        {/* <div style={{ marginTop: '15px' }}>
                            <Switch checked={publishToPrx} onChange={setPublishToPrx} /> Publish the quotation to Procurex
                        </div> */}
                    </Panel>
                    <Panel header="Instructions (if any)" key="2">
                        <Controller
                            name="instructions"
                            control={methods.control}
                            defaultValue=""
                            render={({ field }) => (
                                <ReactQuill
                                    theme="snow"
                                    value={field.value}
                                    onChange={field.onChange}
                                    className="mb-3"
                                />
                            )}
                        />
                    </Panel>
                    <Panel header="Terms and Conditions (if any)" key="3">
                        <Controller
                            name="termsAndConditions"
                            control={methods.control}
                            defaultValue=""
                            render={({ field }) => (
                                <ReactQuill
                                    theme="snow"
                                    value={field.value}
                                    onChange={field.onChange}
                                    className="mb-3"
                                />
                            )}
                        />
                    </Panel>
                    <Panel header="Attachments (if any)" key="4">
                        <Controller
                            name="attachments"
                            control={methods.control}
                            render={({ field }) => (
                                <Dragger
                                    beforeUpload={beforeUpload}
                                    fileList={fileList}
                                    onChange={handleChange}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Click or drag file to this area to upload
                                    </p>
                                    <p className="ant-upload-hint">
                                        {" "}
                                        Drag 'n' drop required files here, or click to required
                                        files{" "}
                                    </p>
                                </Dragger>
                            )}
                        />
                    </Panel>
                </Collapse>

                <div className="actions clearfix">
                    <ul>
                        <li>
                            <Button color="primary" type="submit">
                                Next
                            </Button>
                        </li>
                        <li>
                            <Button color="primary" onClick={props.prev} className="btn-dim btn btn-secondary">
                                Previous
                            </Button>
                        </li>
                    </ul>
                </div>
            </form>
        </FormProvider>
    );
};

export default React.memo(RFQOthers);
