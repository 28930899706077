import React from 'react';
import { getUserLabel } from './Utils'; // Import the function from utils

interface UserLabelProps {
    supplierName: string;
    color: string;
    text: string;
}

const UserLabel: React.FC<UserLabelProps> = ({ supplierName, color, text }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: color,
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                marginRight: '10px'
            }}>
                {getUserLabel(supplierName)}
            </div>
            {text}
        </div>
    );
};

export default UserLabel;
