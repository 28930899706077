import React, { useEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  Button,
  Row,
} from "reactstrap";
import { Icon } from "../../components/Component";
import { Modal } from "antd";

interface CropperComponentProps {
  modal: boolean;
  toggle: () => void;
  onSave: (croppedImage: string) => void;
}

const CropperComponent: React.FC<CropperComponentProps> = ({
  modal,
  toggle,
  onSave,
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [image, setImage] = useState<string>("");

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
      onSave(croppedDataUrl);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        alert(`File size exceeds ${maxSize / (1024 * 1024)}MB`);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const uploadImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = handleFileChange as any;
    input.click();
  };

  return (
    <Modal
      open={modal}
      title="Crop Image"
      onCancel={toggle}
      footer={
        <>
          <ul className="m-auto d-flex align-items-center justify-content-end p-0">
            <li className="pt-0 pb-0 p-2">
              <Button color="primary" onClick={onCrop}>
                Save
              </Button>
            </li>
            <li className="pt-0 pb-0">
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </li>
          </ul>
        </>
      }
    >
      <Cropper
        src={image}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={16 / 9}
        guides={true}
        ref={cropperRef}
      />
      <Row
        className="justify-content-center gy-4"
        style={{ marginTop: "10px" }}
      >
        <Button
          color="btn-dim btn flex justify-content-center"
          onClick={uploadImage}
        >
          <span>Upload </span>
          <Icon name="upload-cloud" />
        </Button>
      </Row>
    </Modal>
  );
};

export default CropperComponent;
