import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Spinner } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Tooltip,
  Drawer,
  Modal,
  Form,
  InputNumber,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import dispatchApis from "../../api/dispatch/dispatch";
import DispatchAside from "./paymentsNav";
import paymentApis from "../../api/payments/payments";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import usersApis from "../../api/master/users";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const RunPayments: FC = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [selectedCnKeys, setSelectedCnKeys] = useState<React.Key[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [widgetData, setWidgetData] = useState<WidgetData[]>([
    {
      title: "Pending",
      count: 0,
      progress: 0,
      color: "#OO65BD",
    },
    {
      title: "Changes Requested",
      count: 0,
      progress: 0,
      color: "#20639B",
    },
    {
      title: "Buyer Accepted Changes",
      count: 0,
      progress: 0,
      color: "#FA991C",
    },
    {
      title: "Changes from Buyer",
      count: 0,
      progress: 0,
      color: "#F6D55C",
    },
    {
      title: "To be Invoiced",
      count: 0,
      progress: 0,
      color: "#OOCC99",
    },
  ]);
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [CNError, setCNError] = useState<any>(null);
  const [selectedCNValueTotal, setSelectedCNValueTotal] = useState(0);
  const [selectedCNValueUtilized, setSelectedCNValueUtilized] = useState([]);
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [paymentMode, setPaymentMode] = useState<string>("neft");
  const [editingCn, setEditingCn] = useState<any>([]);
  const [CN, setCN] = useState<any>([]);
  const isCnEditing = (record: any) => editingCn.includes(record.noteNumber);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    fetchCurrentUser();
    fetchData();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Payment #",
      dataIndex: "paymentId",
      key: "PaymentId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 180,
    },
    {
      title: "Payable Amt",
      dataIndex: "payableAmount",
      key: "payableAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(Number(text), "INR")}
            <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 120,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 70,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewDrawer(true);
              fetchPaymentDetails(record?.paymentNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  const fetchPaymentDetails = async (paymentNumber: any) => {
    try {
      const res = await paymentApis.fetchPayment(paymentNumber);
      const { data, status, message } = res;
      if (status) {
        setDrawerData(data);
        fetchAllOrgUnUtilizedCreditDebitNotes(
          data?.paymentLines[0]?.supplierId
        );
        setApproverIds(
          data?.paymentApprovals
            ?.filter((item: any) => item?.status == "Pending")
            ?.map((item: any) => item?.userId)
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const drawerColumns: any = [
    // {
    //   title: "#",
    //   key: "#",
    //   width: 50,
    //   fixed: "left",
    //   render: (text: any, record: any, index: any) => index + 1,
    // },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Payable Amt",
      dataIndex: "payableAmount",
      key: "payableAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(Number(text), "INR")}
            <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
  ];
  const cNColumns: any = [
    {
      title: "Note #",
      dataIndex: "noteId",
      key: "noteId",
      width: 100,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Note Amt",
      key: "unUtilizedAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(Number(record?.unUtilizedAmount), "INR")}
            <Tooltip
              title={formatCurrency(Number(record?.unUtilizedAmount), "INR")}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "utilizedAmount",
      key: "utilizedAmount",
      width: 200,
      editable: true,
      render: (text: any, record: any) => {
        return <span></span>;
      },
    },
  ];
  const onCNSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedCnKeys(newSelectedRowKeys);
    if (!newSelectedRowKeys) {
      editingCn.forEach((key: any) => {
        const row = CN.find((item: any) => item.noteNumber == key);
        form3.setFieldsValue({
          [`utilizedAmount_${row.noteNumber}`]:
            Number(row?.noteAmount) - Number(row?.utilizedAmount),
        });
      });
      setEditingCn(() => []);
      form3.resetFields();
    }
    const unSelectedRowKeys = selectedCnKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );
    unSelectedRowKeys?.forEach((key) => {
      const row = CN.find((item: any) => item.noteNumber == key);
      form3.setFieldsValue({
        [`utilizedAmount_${row.noteNumber}`]:
          Number(row?.noteAmount) - Number(row?.utilizedAmount),
      });
    });
    const editableRowKeys: any = [];
    newSelectedRowKeys?.forEach((key) => {
      const row = CN.find((item: any) => item.noteNumber == key);
      if (row) {
        form3.setFieldsValue({ ...row });
        editableRowKeys.push(row.noteNumber);
      }
    });
    setEditingCn(editableRowKeys);
  };
  const cnRowSelection = {
    selectedRowKeys: selectedCnKeys,
    onChange: onCNSelectChange,
  };

  const fetchData = async () => {
    try {
      const res = await paymentApis.fetchAllPendingRunPayments();
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setFilteredData(data);
        setWidgetData((widgetArray: any) => {
          return widgetArray.map((widget: any) => {
            const statusCount = data.filter((item: any) => {
              const mappedStatus = statusMapper[item.orderStatus]?.buyer?.title;
              if (!mappedStatus) {
                return false;
              }
              return (
                mappedStatus?.toLowerCase() ==
                widget?.title?.trim()?.toLowerCase()
              );
            }).length;
            return {
              ...widget,
              count: statusCount,
              progress: statusCount
                ? ((statusCount / data?.length) * 100).toFixed(0)
                : 0,
            };
          });
        });
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const fetchAllOrgUnUtilizedCreditDebitNotes = async (supplierId: any) => {
    try {
      const res = await paymentApis.fetchAllOrgUnUtilizedCreditDebitNotes(
        supplierId
      );
      const { data, status, message } = res;
      if (status) {
        setCN(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        setCurrentUserId(data?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const runPayment = async (_data: any) => {
    try {
      const res = await paymentApis.runPayment({
        paymentData: {
          paymentNumber: _data.paymentNumber,
          paymentMode: _data?.paymentMode,
          transactionNumber: _data?.transactionNumber?.toString(),
          creditDebitNotes: selectedCNValueUtilized,
        },
      });
      const { data, status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        setViewDrawer(false);
        setDrawerData(null);
        fetchData();
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableSubmit(false)
  };

  const EditCN = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }: any) => {
    const handleBlur = (value: number) => {
      console.log("blur", value);
      form3.submit();
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={`utilizedAmount_${record.noteNumber}`}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${title} is required`,
              },
              {
                type: "number",
                min: 0.01,
                message: `${title} must > 0`,
              },
              {
                type: "number",
                max: Number(record?.unUtilizedAmount),
                message: `${title} must <= ${formatCurrency(
                  Number(record?.unUtilizedAmount),
                  "INR"
                )}`,
              },
            ]}
            initialValue={Number(record?.unUtilizedAmount)}
          >
            <InputNumber
              className="w-100"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              onKeyPress={(event) => {
                const regex = /^[0-9\b\.]+$/;
                if (!regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
              step={0.01}
              onBlur={(e: any) => handleBlur(e?.target?.value)} // Use onBlur instead of onChange
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <Head title="Pending Run Payments - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">Pending Run Payments</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                {originalData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Pending Payment Approvals" />
                  </div>
                ) : (
                  <div>
                    {filteredData.length == 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <Empty description="No Data for selected filters" />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: screenSize - 330 + "px",
                          minHeight: "200px",
                          marginTop: "20px",
                        }}
                      >
                        <Table
                          className="customTable"
                          columns={columns.map((item: any) => ({
                            ...item,
                          }))}
                          dataSource={filteredData}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "50"],
                          }}
                          bordered
                          size="middle"
                          scroll={{ x: 1400 }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Run Payment</strong>
            <>
              <div style={{ textAlign: "right" }}>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      form2.submit();
                    }}
                    color="primary"
                    disabled={CNError ? true : false}
                    className="m-3 mt-0 mb-0"
                  >
                    Clear Payment
                  </Button>
                )}
              </div>
            </>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {drawerData ? (
          <>
            <Form
              form={form2}
              layout="vertical"
              onFinish={(data) => {
                setDisableSubmit(true);
                Swal.fire({
                  title: "Are you sure?",
                  text: "You are about to Clear Payment",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes !",
                }).then((result) => {
                  if (result.isConfirmed) {
                    runPayment({
                      ...data,
                      paymentNumber: drawerData?.paymentNumber,
                    });
                  }else{
                setDisableSubmit(false);
                  }
                });
              }}
              requiredMark={false}
            >
              <div className="d-flex justify-content-between p-3 pb-0 pt-0">
                <div>
                  <strong>Remark</strong>
                  <p>{drawerData?.remarks}</p>
                </div>
                <div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between">
                      <div className="justify-end">
                        <strong>Payable Amount Total &nbsp; &nbsp; </strong>
                      </div>
                      <strong className="fw-bold">
                        {formatCurrency(
                          Number(drawerData?.payableAmount || 0),
                          drawerData?.currencyCode || "INR"
                        )}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 300 + "px",
                    margin: "0px auto 15px auto",
                  }}
                >
                  <Table
                    className="customTable"
                    columns={drawerColumns}
                    dataSource={drawerData?.paymentLines}
                    scroll={{ x: 1000 }}
                    bordered
                    size="middle"
                    pagination={false}
                  />
                </div>
              </div>
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 400 + "px",
                    margin: "0px auto 15px auto",
                  }}
                >
                  <Form
                    form={form3}
                    component={false}
                    onFinish={(data) => {
                      let selectedCNValueTotal = 0;
                      const _selectedCNValues: any = [];
                      Object.keys(data).map((key) => {
                        if (key.includes("utilizedAmount")) {
                          selectedCNValueTotal += Number(data[key]);
                          _selectedCNValues.push({
                            noteNumber: Number(key.split("_")[1]),
                            utilizedAmount: Number(data[key]),
                          });
                        }
                      });

                      setSelectedCNValueUtilized(_selectedCNValues);
                      if (selectedCNValueTotal > drawerData?.payableAmount) {
                        setCNError(
                          "Utilized Amount is greater than Payable Amount"
                        );
                      } else {
                        setCNError(null);
                      }
                      setSelectedCNValueTotal(selectedCNValueTotal);
                    }}
                  >
                    <Table
                      title={() => (
                        <div className="d-flex justify-content-between">
                          {CNError ? (
                            <strong style={{ color: "red" }}>{CNError}</strong>
                          ) : (
                            <strong>Select Credit Notes (if needed)</strong>
                          )}
                          {selectedCnKeys.length > 0 && (
                            <Button
                              color="primary"
                              className="m-0"
                              style={{ height: "30px" }}
                              onClick={() => {
                                form3.submit();
                              }}
                            >
                              Use
                            </Button>
                          )}
                        </div>
                      )}
                      footer={() => (
                        <div className="w-25" style={{ marginLeft: "auto" }}>
                          <div className="d-flex justify-content-between">
                            <strong>Payable Amount</strong>
                            <strong>
                              {formatCurrency(drawerData?.payableAmount, "INR")}
                            </strong>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <strong>Credit Note Amount</strong>
                            <strong>
                              {formatCurrency(selectedCNValueTotal, "INR")}
                            </strong>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <strong>Remaining Amount</strong>
                            <strong>
                              {formatCurrency(
                                drawerData?.payableAmount -
                                  selectedCNValueTotal,
                                "INR"
                              )}
                            </strong>
                          </div>
                        </div>
                      )}
                      className="customTable"
                      rowSelection={cnRowSelection}
                      columns={cNColumns?.map((col: any) => {
                        if (!col.editable) {
                          return col;
                        }
                        return {
                          ...col,
                          onCell: (record: any) => ({
                            record,
                            inputType: "number",
                            dataIndex: col.dataIndex,
                            title: col.title,
                            editing: isCnEditing(record),
                          }),
                        };
                      })}
                      dataSource={CN?.map((field: any) => {
                        return {
                          ...field,
                          key: field.noteNumber,
                        };
                      })}
                      scroll={{ x: "max-content" }}
                      bordered
                      size="small"
                      pagination={false}
                      components={{
                        body: {
                          cell: EditCN,
                        },
                      }}
                    />
                  </Form>
                </div>
              </div>
              <div className="">
                <div className="tab-content mt-0 w-70 paddingLeft10 m-auto">
                  <div className="tab-content mt-0">
                    <Col className=" w-90  flex-wrap  p-0">
                      <>
                        <div className="w-100 p-1 pt-0 pb-0">
                          <div className="data-col p-0 pt-1">
                            <div className="data-label w-20">Payment Mode</div>
                            <div className="data-value w-80">
                              <Form.Item
                                name="paymentMode"
                                label={undefined}
                                className="w-100 paddingLeft10  p-0 m-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select payment mode",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Invoice Type"
                                  onChange={(e) => {
                                    setPaymentMode(e);
                                  }}
                                >
                                  <Option value="neft">
                                    National Electronic Fund Transfer (NEFT)
                                  </Option>
                                  <Option value="rtgs">
                                    Real Time Gross Settlement (RTGS)
                                  </Option>
                                  <Option value="imps">
                                    Immediate Payment Services (IMPS)
                                  </Option>
                                  <Option value="cheque">CHEQUE</Option>
                                  <Option value="upi">UPI</Option>
                                  <Option value="cash">CASH</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="w-100 p-1 pt-0 pb-0">
                          {["neft", "rtgs", "imps"].includes(paymentMode) ? (
                            <div className="data-col p-0 pt-1">
                              <div className="data-label w-20">UTR</div>
                              <div className="data-value w-80">
                                <Form.Item
                                  name="transactionNumber"
                                  label={undefined}
                                  className="w-100 paddingLeft10 p-0 m-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter UTR",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Enter UTR"
                                    className="w-100"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ""
                                      )
                                    }
                                    parser={(value: any) =>
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^[0-9]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : ["upi"].includes(paymentMode) ? (
                            <div className="data-col p-0 pt-1">
                              <div className="data-label w-20">
                                Transaction ID
                              </div>
                              <div className="data-value w-80">
                                <Form.Item
                                  name="transactionNumber"
                                  label={undefined}
                                  className="w-100 paddingLeft10 p-0 m-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Transaction ID",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Enter Transaction ID"
                                    className="w-100"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ""
                                      )
                                    }
                                    parser={(value: any) =>
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^[0-9]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : ["cheque"].includes(paymentMode) ? (
                            <div className="data-col p-0 pt-1">
                              <div className="data-label w-20">Cheque #</div>
                              <div className="data-value w-80">
                                <Form.Item
                                  name="transactionNumber"
                                  label={undefined}
                                  className="w-100 paddingLeft10 p-0 m-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Cheque Number",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Enter Cheque Number"
                                    className="w-100"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ""
                                      )
                                    }
                                    parser={(value: any) =>
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^[0-9]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    </Col>
                  </div>
                </div>
              </div>
            </Form>
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
      </Drawer>
    </>
  );
};

export default RunPayments;
