import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  AlertOutlined,
} from "@ant-design/icons";

const statusMapper: any = {
  "Pending Confirmation": {
    buyer: {
      title: "Pending",
      color: "gold", 
      icon: <HourglassOutlined />, 
    },
    seller: {
      title: "Pending",
      color: "gold",
      icon: <HourglassOutlined />,
    },
  },
  "Accepted By Supplier": {
    buyer: {
      title: "Accepted",
      color: "green", 
      icon: <CheckCircleOutlined />, 
    },
    seller: {
      title: "Accepted",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "Auto Confirmed": {
    buyer: {
      title: "Auto Confirmed",
      color: "purple",
      icon: <SyncOutlined spin />,
    },
    seller: {
      title: "Auto Confirmed",
      color: "purple",
      icon: <SyncOutlined spin />,
    },
  }
};

export default statusMapper;
