import makeApiCall from "./axiosIndex";

const deliveryAddressApis = {
  createDeliveryAddress: (data: any): Promise<any> =>
    makeApiCall<any>({ method: "post", url: "/buyer/createAddress", data }),
  fetchDeliveryAddress: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchAddress" }),
  fetchDeliveryAddressById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchAddressById?id=${id}`,
    }),
  updateDeliveryAddressById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateAddressById`,
      params: { id },
      data,
    }),
  deleteDeliveryAddressById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteAddressById`,
      params: { id },
    }),
};
export default deliveryAddressApis;
