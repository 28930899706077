import React, { useEffect, useState } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
  RSelect,
} from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { useFormContext } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import sites from "../../../api/master/sites";
import FormatDate from "../DateFormater";
import moment from "moment";
import misc from "../../../api/misc/dropDowns";

interface SelectOptions {
  label: string;
  value: string;
}
const PreferredSuppliersInformation: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "suppliers",
  });
  const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptions[]>([]);

  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status, message } = res;
      if (status) {
        // setAllCurrencyData(data);
        let currencyOptions = data.map((item: any) => {
          return {
            label: item.currency_code,
            value: item.currency_code,
          };
        });
        setCurrencyOptions(currencyOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSuppliersDropdown = async () => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplier();
      if (status) {
        // Assuming `data` is an array of suppliers
        const formattedData = data.map((supplier: any) => ({
          label: supplier.supplierName,
          value: supplier.supplierID,
        }));
        setSupplierOptions(formattedData);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to fetch suppliers", "error");
    }
  };

  useEffect(() => {
    console.log("fields", fields);
    fetchSuppliersDropdown();
    fetchCurrencyData(); // Call the function here
  }, []);

  const defaultCurrencyValue = currencyOptions.find(
    (option) => option.value === "INR"
  );

  useEffect(() => {
    if (fields.length > 0) {
      fields.forEach((field, index) => {
        setValue(`suppliers[${index}].currency`, defaultCurrencyValue?.value || "");
      });
    }
  }, [currencyOptions, fields]);

  const submitForm = (data: any) => {
    console.log("data", data);
    props.next();
  };

  const handleSupplierChange = (value: any, index: number) => {
    const supplierName = value ? value.label : "";
    setValue(`suppliers[${index}].supplierName`, supplierName);
    setValue(`suppliers[${index}].supplierID`, value.value);
  };

  return (
    <>
     <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          {fields.map((field, index) => (
            <div key={field.id} className="row mb-3">
              <div className="col">
                <FormSelect
                  name={`suppliers[${index}].supplierID`}
                  label="Select Supplier"
                //   required={true}
                  options={supplierOptions}
                  placeholder="Select Supplier"
                  // onChange={(value: any) => handleSupplierChange(value, index)}
                />
              </div>
              <div className="col">
                <FormInput
                  name={`suppliers[${index}].averageLeadDays`}
                  label="Delivery Lead Days"
                //   required={true}
                  type="number"
                  placeholder="Delivery Lead Days"
                />
              </div>
              <div className="col">
                <FormSelect
                  name={`suppliers[${index}].currency`}
                  label="Currency"
                //   required={true}
                  options={currencyOptions}
                  placeholder="-Select-"
                  defaultValue={defaultCurrencyValue} // Set default value
                />
              </div>
              <div className="col-md-2">
                <div style={{ marginTop: 30 }}>
                  <button
                    type="button"
                    className="btn btn-success ms-2"
                    onClick={() =>
                      append({
                        supplierID: "",
                        supplierName: "",
                        averageLeadDays: "",
                        currency: defaultCurrencyValue?.value || "",
                      })
                    }
                  >
                    +
                  </button>
                  &nbsp;
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => remove(index)}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
            <Button color="primary" type="submit">
              Next
            </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default PreferredSuppliersInformation;
