import React, { useEffect } from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import { TooltipComponent } from "../../components/Component";
import { Select } from "antd";

interface SelectOption {
  value: any;
  label: string;
}

interface FormSelectProps {
  name: string;
  label: string;
  options: SelectOption[];
  required?: any;
  placeholder?: string;
  customValidation?: (value: string) => boolean | string;
  maxLength?: number;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (value: string) => void;
  dropdownRender?: (props: any) => JSX.Element;
  tooltipText?: string;
  popOverOptions?: any;
  showSearch?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  options,
  required = false,
  placeholder = "-Select-",
  customValidation,
  maxLength,
  defaultValue,
  disabled,
  onChange,
  tooltipText,
  popOverOptions,
  dropdownRender,
  showSearch = true,
}) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : undefined,
      maxLength: maxLength
        ? {
            value: maxLength,
            message: `Maximum length is ${maxLength} characters`,
          }
        : undefined,
      validate: customValidation
        ? (value: string) => customValidation(value) || "Invalid value"
        : undefined,
    });
  }, [register, name, required, maxLength, customValidation]);

  const errorMessage = getErrorMessage(errors, name);

  return (
    <div className="form-group">
      <div className="form-label-wrapper">
        <label className="form-label" htmlFor={name}>
          {label}
          {required && <span className="mandatory">*</span>}
        </label>
        {popOverOptions}
        {tooltipText && (
          <TooltipComponent
            iconClass="card-hint"
            icon="help-fill"
            direction="left"
            id={`${name}-tooltip`}
            text={tooltipText}
            containerClassName={""}
          />
        )}
      </div>
      <div className="form-control-wrap">
        <div className="form-control-select">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                value={
                  options?.find((option) => option?.value == field?.value)
                }
                onChange={(selectedOption: any) => {
                  field.onChange(selectedOption);
                  if (onChange) {
                    onChange(selectedOption);
                  }
                }}
                style={{ width: "100%", height: "38px" }}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                showSearch={showSearch}
                dropdownRender={dropdownRender}
                filterOption={(input: any, option: any) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } 
              />
            )}
          />
          {errorMessage && <p className="mandatory">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean);
  let currentError: any = errors;

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default FormSelect;
