import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button, Alert } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import axios from "../../../axios";
import Swal from "sweetalert2";
import usersApis from "../../../api/master/users";
import rolesApi from "../../../api/master/roles";
import { Icon } from "../../../components/Component";
import departments from "../../../api/master/departments";
interface SelectOptions {
  label: string;
  value: string;
}
const HierarchySetup: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [roleData, setRoleData] = useState<SelectOptions[]>([]);
  const [departmentData, setDepartmentData] = useState<SelectOptions[]>([]);
  const [organisationData, setOrganisationData] = useState<SelectOptions[]>([]);
  const [allRolesData, setAllRolesData] = useState<any[]>([]);
  const [reportingManagerDropDownData, setReportingManagerDropDownData] =
    useState<SelectOptions[]>([]);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [showreporting, setShowreporting] = useState(false);
  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    console.log("33333", {
      ...data,
      department: allRolesData.find(
        (obj: any) => obj.roleID.toString() == data.role
      )?.deptID,
    });
    props.next();
  };

  const selectRole = watch("role");
  useEffect(() => {
    if (selectRole) {
      console.log("selectRole", selectRole);
      fetchReportingManagerRole(selectRole);
    }
  }, [selectRole]);

  const fetchRoles = async () => {
    try {
      const { data, status, message } = await usersApis.fetchRolesData();
      if (status) {
        setRoleData(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );

        setAllRolesData(data);
        // setValue("department", data.deptName);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  // const fetchDept = async (selectRole: any) => {
  //   console.log("dataDEP", selectRole);
  //   try {
  //     const { data, status, message } = await rolesApi.fetchRoleById(
  //       selectRole
  //     );
  //     if (status) {
  //       setValue("department", data.deptName);
  //     }
  //   } catch (err: any) {}
  // };

  const fetchOrgs = async () => {
    const contractorData = await usersApis.fetchContractor();
    const consultantData = await usersApis.fetchConsultant();
    console.log("usersApis", contractorData);
    setOrganisationData(() => {
      const newContractorData = contractorData["data"].map((obj: any) => ({
        label: obj.companyName,
        value: obj.contractorID.toString(),
      }));
      const newConsultantData = consultantData["data"].map((obj: any) => ({
        label: obj.companyName,
        value: obj.consultantID.toString(),
      }));

      return [...newContractorData, ...newConsultantData];
    });
  };

  const fetchUsersByRoleId = async (id: any) => {
    const { data, status, message } = await usersApis.fetchUsersByRoleId(id);
    if (status) {
      console.log("data", data);
      if (data !== null) {
        setReportingManagerDropDownData(
          data.map((obj: any) => ({
            label: obj.name,
            value: obj.userID.toString(),
          }))
        );
      } else {
        setAlertInfo({
          type: "error",
          message:
            "USER CANNOT BE CREATED AS THERE ARE No ACTIVE USER FOR THE SELECTED REPOTING MANAGER ROLE",
          errors: [],
        });
      }
    }
  };

  const fetchReportingManagerRole = async (selectRole: any) => {
    let role: any;
    let AllRoles: any;
    try {
      const { data, status, message } = await usersApis.fetchRolesData();
      AllRoles = data;
      role = data.find((obj: any) => obj.roleID == selectRole);
      console.log("role", role);
      setValue("department", role?.deptID);
      setValue("departmentName", role?.deptName);
    } catch (err: any) {
      console.log("err", err);
    }
    // console.log("selectRole", selectRole);
    try {
      const { data, status, message } =
        await rolesApi.fetchReportingHierarchy();
      if (status) {
        console.log("data000111", data);
        // let findData = data.find((obj: any) => obj.reportingRoleID == selectRole && obj.leader == true);
        // console.log("findData",findData),
        // console.lo
        if (
          data.find(
            (obj: any) =>
              obj.reportingRoleID == selectRole &&
              obj.reportingRoleID == obj.reportingToRoleID
          )
        ) {
          setShowreporting(false);
        } else if (
          data.find(
            (obj: any) =>
              obj.reportingRoleID == selectRole && obj.leader == false
          )
        ) {
          let Data = data.find(
            (obj: any) =>
              obj.reportingRoleID == selectRole && obj.leader == false
          ).reportingToRoleID;
          console.log("Data6666", Data, AllRoles);
          setShowreporting(true);
          if (AllRoles.find((obj: any) => obj.roleID == Data)) {
            console.log("75");
            let value = AllRoles.find(
              (obj: any) => obj.roleID == Data
            )?.roleName;
            let valueID = AllRoles.find(
              (obj: any) => obj.roleID == Data
            )?.roleID;
            console.log("value", value);
            setValue("reportingManagerRole", valueID);
            setValue("reportingManagerRoleName", value);

            fetchUsersByRoleId(Data);
          }
        }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  useEffect(() => {
    fetchRoles();
    // fetchDept();
    // fetchOrgs();
  }, []);

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          {/* <Col lg='4'>
                        <FormSelect
                            name="userOrganisation"
                            label="User Organisation"
                            options={organisationData}
                        />
                    </Col> */}

          <Col lg="4">
            <FormSelect
              name="role"
              label="Role"
              options={roleData}
              required={true}
              placeholder="Select Role"
              // onChange={(e) => {
              //   console.log("selectRole111", e);
              //   fetchReportingManagerRole(e);
              // }}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="departmentName"
              label="Department"
              // options={departmentData}
              required={true}
              placeholder="Select Department"
            />
          </Col>
          {showreporting && (
            <>
              <Col lg="4">
                <FormInput
                  name="reportingManagerRoleName"
                  label="Reporting Manager Role"
                  // options={reportingManagerRoleDropDownData}
                  required={true}
                  placeholder="Select"
                />
              </Col>
              <Col lg="4">
                <FormSelect
                  name="reportingManager"
                  label="Reporting Manager"
                  options={reportingManagerDropDownData}
                  required={true}
                  placeholder="Select"
                />
              </Col>
            </>
          )}
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default React.memo(HierarchySetup);
