import React, { FC } from 'react';
import { Drawer, Table, Tag } from 'antd';
import {InfoCircleFilled,InfoCircleOutlined} from '@ant-design/icons';
const ChangeToBuyerDrawer: FC<any> = ({
  changeToBuyerData,
  needByDateType,
  drawerVisible,
  closeDrawer,
  FormatCurrency
}) => {
  const dataSource = [
    {
      key: '1',
      parameter: 'Quantity',
      existingValue: `${Number(changeToBuyerData.quantity)} ${changeToBuyerData.uom}`,
      changedValue: `${Number(changeToBuyerData.changeRequestQuantity || changeToBuyerData.quantity)} ${changeToBuyerData.uom}`,
      isChanged: Number(changeToBuyerData.quantity) != Number(changeToBuyerData.changeRequestQuantity || changeToBuyerData.quantity)
    },
    {
      key: '2',
      parameter: 'Need By Date',
      existingValue: changeToBuyerData.needByDate,
      changedValue: changeToBuyerData.changeRequestNeedByDate || changeToBuyerData.needByDate,
      isChanged: changeToBuyerData.needByDate !== (changeToBuyerData.changeRequestNeedByDate ?? changeToBuyerData.needByDate)
    },
    {
      key: '3',
      parameter: 'Unit Price',
      existingValue: FormatCurrency(Number(changeToBuyerData.unitPrice)),
      changedValue: FormatCurrency(Number(changeToBuyerData.changeRequestUnitPrice || changeToBuyerData.unitPrice)),
      isChanged: Number(changeToBuyerData.unitPrice) != (Number(changeToBuyerData.changeRequestUnitPrice || changeToBuyerData.unitPrice))
    }
  ];

  return (
    <Drawer
      title={null}
      placement="right"
      onClose={closeDrawer}
      visible={drawerVisible}
      width={640}
    >
      <h5 style={{ marginBottom: 20 }}>Change Request - Line {changeToBuyerData.lineNo} | <Tag color='#108ee9'>{changeToBuyerData.materialName}</Tag></h5>
      <Table dataSource={dataSource} pagination={false}>
        <Table.Column title="Parameter" dataIndex="parameter" key="parameter" />
        <Table.Column title="Existing Value" dataIndex="existingValue" key="existingValue" />
        <Table.Column
          title="Changed Value"
          dataIndex="changedValue"
          key="changedValue"
          render={(text, record: any) => (
            <span
              style={{
                color: record.isChanged ? 'green' : 'inherit',
                fontWeight: record.isChanged ? 'bold' : 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {record.isChanged && (
                <span
                  style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: 'green',
                    borderRadius: '50%',
                    marginRight: '5px',
                  }}
                ></span>
              )}
              {text}
            </span> 
          )}
        />
      </Table>
    </Drawer>
  );
};

export default ChangeToBuyerDrawer;
