import React, { FC, useEffect, useState } from "react";
import { Col, Row, Card } from "reactstrap";
import QuotationFunnel from "./QuotationFunnel";
import { Funnel } from "@ant-design/charts";
import QuotationComparisonLine from "./snapshot/QuotaitonComparisonLine";
import ApprovalsTimeline from "./snapshot/ApprovalTimeLine";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
import QuotationTimeLine from "./snapshot/QuotationTimeLine";
import TimelineComponent from "./snapshot/TimelineComponent";
import quotationApis from "../../../api/master/quotations";
import moment from "moment";
interface SnapshotProps {
  rfq: any;
}
const Snapshot: FC<SnapshotProps> = ({ rfq }) => {
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [approvalsDetailsData, setApprovalsDetails]:any = useState([]);


  useEffect(() => {
    // Additional initialization if necessary
    if (rfq) {
      summaryData();
      versionGraphData();
      approvalDetails();
    }
  }, []);

  const versionGraphData = async () => {
    try {
      const { response, status } = await quotationApis.versionGraph(
        rfq.quotationId
      );
      if (status) {
        const data = response.data;
        // const graphData = data.map((item: any) => {
        //   return {
        //     supplierName: item.supplierName,
        //     supplierID: item.supplierID,
        //     currency: "INR",
        //     priceTrends: item.priceTrends,
        //     initialQuote: item.initialQuote,
        //     finalQuote: item.finalQuote,
        //     savings: item.savings,
        //     creditDays: item.creditDays,
        //     creditPercentage: item.creditPercentage + "%",
        //     quotationDate: moment(item.quotationDate).format("DD-MMM-YYYY"),
        //   };
        // });
        setGraphData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const summaryData = async () => {
    console.log(rfq.quotationId);
    try {
      const { response, status } = await quotationApis.Summary(rfq.quotationId);
      if (status) {
        const data = response.data;
        const formattedData = data.map((item: any) => {
          let icon;
          switch (item.status) {
            case "IN_PROGRESS":
              icon = <FileTextOutlined />;
              break;
            case "QUOTED":
              icon = <FileDoneOutlined />;
              break;
            case "REGRETTED":
              icon = <DownCircleOutlined />;
              break;
            case "NEGOTIATED":
              icon = <ClockCircleOutlined />;
              break;
            case "QC_APPROVAL":
              icon = <ClockCircleOutlined />;
              break;
            case "CONVERTED":
              icon = <CheckCircleOutlined />;
              break;
            case "REJECTED":
              icon = <CloseCircleOutlined />;
              break;
            default:
              icon = null;
          }

          return {
            stage: item.stage,
            count: item.count,
            icon: icon,
          };
        });
        console.log("formattedData", formattedData);
        //   const data = response.map((item: any) => {
        //     item.statusColor = widgetData[item % widgetData.length]?.color;
        //     return item.quotationHeader;
        //   });

        //   console.log("data", data);
        //   setTableData(data);
        //   setFilteredData(data);
        setData(formattedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const approvalDetails = async () => { 
    try {
        const { response, status } = await quotationApis.approvalDetilsEventChain(
          rfq.quotationId
        );
        if (status) {
          const data = response.data;
          const formatData = data.map((item: any) => {
            return {
                level: item.level,
                name: item.userName,
                roleName: item.roleName,
                roleID:item.roleId,
                approvalStatus: item.status,
                date:item.approvalDate,
                rejectionReason: item.reason
            };
          })
          setApprovalsDetails(formatData);
        }
      } catch (error) {
        console.log(error);
      }
  };

  // const data = [
  //     { stage: 'Request to Suppplier', count: 100, icon: <FileTextOutlined /> },
  //     { stage: 'Quotaiton Responses', count: 60, icon: <FileDoneOutlined /> },
  //     { stage: 'Regret from Supplliers', count: 20, icon: <DownCircleOutlined />},
  //     { stage: 'Under Negotiation', count: 40, icon: <ClockCircleOutlined /> },
  //     { stage: 'Quotation Finalised', count: 80, icon: <CheckCircleOutlined /> },
  //     { stage: 'Quotation Rejected', count: 80, icon: <CloseCircleOutlined /> },
  // ];
  const config = {
    data,
    xField: "stage",
    yField: "count",
    conversionTag: {},
  };
  return (
    <div className="tabWrapper">
      <Row>
        <Col lg="3">
          <Card
            className="card-bordered"
            style={{ height: "440px", overflowY: "auto" }}
          >
            <div className="card-inner">
              <h6 className="mb-2">Summary</h6>
              <div>
                {data.map((item: any, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",

                      backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                      padding: 8,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 22,
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#333",
                      }}
                    >
                      {item?.icon}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                        flex: 1,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        {item.stage}
                      </span>
                      <div
                        style={{
                          flex: 1,
                          borderBottom: "1px solid #dbdfea",
                          marginRight: 10,
                        }}
                      ></div>
                      <span style={{ fontSize: "20px" }}>{item.count}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card
            className="card-bordered"
            style={{ height: "440px", overflowY: "auto" }}
          >
            <div className="card-inner">
            {/* <h6 className="mb-4">Best Savings</h6> */}
              {graphData && (
                <QuotationComparisonLine
                  graphData={graphData}
                ></QuotationComparisonLine>
              )}
            </div>
          </Card>
        </Col>
        <Col lg="3">
          <Card
            className="card-bordered"
            style={{ height: "440px", overflowY: "auto" }}
          >
            <div className="card-inner">
              <h6 className="mb-4">Approval Details</h6>
              <TimelineComponent dataApprovalDetails={approvalsDetailsData}></TimelineComponent>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card className="card-bordered">
            <div className="card-inner">
              <h6 className="mb-4">Timeline</h6>
              <QuotationTimeLine rfq={rfq.quotationId}></QuotationTimeLine>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Snapshot;
