import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";
import Header_PurchaseRequest from "./NewIndent/Header_PR";
import PurchaseRequest_GeneralDetails from "./NewIndent/PurchaseRequest_GeneralDetails";
import PurchaseRequest_Materails from "./NewIndent/PurchaseRequest_Materails";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PurchaseRequest_Preview from "./NewIndent/PurchaseRequest_Preview";
import { Link } from "react-router-dom";
import PurchaseRequestAside from "./PurchaseRequestAside";
import { Steps, Step } from "react-step-builder";

const config = {
  before: Header_PurchaseRequest as React.ComponentType<{}> as () => React.ReactElement,
};

const NewPurchaseRequestMain: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const formMethods = useForm({
    mode: "onChange",
  });
  const {watch,register,reset} = formMethods;
  const prData=watch();


  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };


  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  return (
    <>
      <Head title="Purchase Request - Create New" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
          <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <PurchaseRequestAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">New Purchase Request</BlockTitle>
                    {/* <BlockDes>
                      <p>Approval/Reject Purchase Request</p>
                    </BlockDes> */}
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
              <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                    <Steps config={config}>
                      <Step component={PurchaseRequest_GeneralDetails} />
                      <Step component={PurchaseRequest_Materails} />
                      <Step component={PurchaseRequest_Preview} />
                    </Steps>
                  </FormProvider>
                </div>
              </Block>
              </div>
            </div>
          {/* </div> */}
        </Card>
      </Content>
    </>
  );
};

export default NewPurchaseRequestMain;
