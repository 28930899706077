import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Col,
  RSelect,
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Accessibility from "./Accessibility";
import HierarchySetup from "./HierarchySetup";
import PersonalInformation from "./PersonalInformation";

const config = {
  before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const CreateUsers: React.FC = (props: any) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });
  const [personalInfoFormData, setPersonalInfoFormData] = useState<any>({});

  const backList = () => {
    navigate(`/users`);
  };

  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Create Users</BlockTitle>
              <BlockDes>
                Onboard Users on platform, collecting essential information to
                create users profiles.
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => backList()}
              >
                <Icon name="menu-alt-r"></Icon>
                <span>List of Users</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                <Steps config={config}>
                  <Step component={PersonalInformation} />
                  <Step component={HierarchySetup} />
                  <Step component={Accessibility} />
                </Steps>
              </FormProvider>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default CreateUsers;
