import React, { useEffect, FC } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";

const EditPoModal: FC<any> = ({
  status,
  modal,
  closeModal,
  onSubmit,
  formData,
  categoryOptions,
  materialOptions,
  uomOptions,
  TAX_SLAP_DROPDOWN,
  deliveryAddressOptions,
}) => {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  const materialCategory= watch("materialCategory");

  useEffect(() => {
    if (modal) {
        setValue("materialCategory",formData?.materialCategory);
        setValue("materialName",formData?.materialName);
        setValue("hsnCode",formData?.hsnCode);
        setValue("quantity",formData?.quantity);
        setValue("uomId",formData?.uomId);
        setValue("unitPrice",formData?.unitPrice);
        setValue("cgst",formData?.cgst);
        setValue("deliveryAddressId",formData?.deliveryAddressId);
        setValue("description",formData?.description);
        setValue("quantity",5);
        setValue("needByDate", new Date(formData?.needByDate));
    }
  }, [modal]);

  const submitForm = (data: any) => {
    onSubmit(data);
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                closeModal();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <h5 className="title">
              {status === "edit" ?"Edit Purchase Order":"Purchase Order Details"}
            </h5>
            <div style={{ marginTop: "15px" }}></div>

            <Row className="mt-12">
              <div className="col-md-4">
                <FormSelect
                  name="materialCategory"
                  label="Material Category"
                  disabled={true}
                  options={categoryOptions}
                  placeholder="Select Material Category"
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="materialName"
                  label="Material Name"
                  disabled={true}
                  options={materialOptions?.filter(
                    (item: any) => item.category == materialCategory 
                  )?.map((item: any) => ({
                    label: item.productName,
                    value: item.materialID.toString(),
                  }))}
                  placeholder="Select Material Name"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  disabled={true}
                  name="hsnCode"
                  label="HSN/SAC"
                  pattern={/^[0-9]{6,8}$/}
                  placeholder="Enter HSN"
                />
              </div>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <div className="col-md-2">
                <FormInput
                  type="number"
                  required={true}
                  min={1}
                  disabled={status === "view"}
                  name="quantity"
                  label="Quantity"
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="col-md-2">
                <FormSelect
                  name="uomId"
                  label="UOM"
                  disabled={true}
                  options={uomOptions}
                  placeholder="Units"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  required={true}
                  disabled={status === "view"}
                  min={1}
                  name="unitPrice"
                  label="Price per Unit"
                  placeholder="Enter Price per Unit"
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="cgst"
                  label="CGST/SGST(%)"
                  disabled={true}
                  options={TAX_SLAP_DROPDOWN}
                  placeholder="Enter CGST"
                />
              </div>
              <div style={{ marginTop: "15px" }}></div>
              <div className="col-md-4">
                <FormDatePicker
                  name="needByDate"
                  disabled={status === "view"}
                  label="Need By Date"
                  minDate={new Date()}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="file"
                  name="attachment"
                  disabled={status === "view"}
                  label="Attachment (If any) "
                  placeholder="Enter Attachment"
                  tooltipText="e.g: Design specificaitons"
                />
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <FormSelect
                    name="deliveryAddressId"
                    options={deliveryAddressOptions}
                    label="Delivery Address"
                    disabled={true}
                    placeholder="Select Delivery Address"
                  />
                </div>
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-md-12">
                <FormTextArea
                  name="description"
                  label="Description (if any)"
                  placeholder="Enter Description"
                  disabled={true}
                  minLength={5}
                  maxLength={200}
                />
              </div>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {status === "edit" &&<li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      Update
                    </Button>
                  </li>}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default EditPoModal;
