import makeApiCall from "./axiosIndex";
const purchaseOrderApis = {
  fetchPurchaseOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllOrders",
    }),
  fetchOrder: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/order-vs-billing/fetchOrder?orderNumber=${id}`,
    }),
  fetchAllUserApprovalOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllUserApprovalOrders",
    }),
  purchaseOrderUpdate: (orderNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/order-vs-billing/amendmentOrder/${orderNumber}`,
      data,
    }),

  approvePo: (orderNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/approveOrder?orderNumber=${orderNumber}`,
      data,
    }),
  disapprovePo: (orderNumber: string, rejectedReason: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/disapproveOrder?orderNumber=${orderNumber}&rejectedReason=${rejectedReason}`,
    }),
  rejectPo: (orderNumber: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/rejectOrder?orderNumber=${orderNumber}`,
    }),
  fetchAllUserPendingApprovalOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllUserPendingApprovalOrders",
    }),
  rejectChangeRequestOrder: (orderNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/rejectChangeRequestOrder?orderNumber=${orderNumber}&rejectedReason=${data}`,
    }),
  acceptChangeRequestOrder: (orderNumber: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/acceptChangeRequestOrder?orderNumber=${orderNumber}`,
    }),
};

export default purchaseOrderApis;
