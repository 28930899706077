import React, { useState } from 'react';
import { Table, Avatar, Radio, Empty } from 'antd';
import { getUserLabel } from '../../../utils/Utils'; // Assuming you have this utility for creating user labels
import { QuotationAnalyticsProps } from './interfaces';

const QuotationResponseTime: React.FC<any> = ({ quotationResponseTimeData }) => {
    const [viewType, setViewType] = useState<'top' | 'bottom'>('top');

    const topSuppliers = Array.isArray(quotationResponseTimeData?.quotationResponseTime?.topSuppliers)
        ? quotationResponseTimeData.quotationResponseTime.topSuppliers
        : [];

    const bottomSuppliers = Array.isArray(quotationResponseTimeData?.quotationResponseTime?.bottomSuppliers)
        ? quotationResponseTimeData.quotationResponseTime.bottomSuppliers
        : [];




    const dataSource = viewType === 'top'
        ? topSuppliers?.sort((a: any, b: any) => a.responseTime - b.responseTime)
        : bottomSuppliers?.sort((a: any, b: any) => b.responseTime - a.responseTime);

    const columns = [
        {
            title: 'Supplier',
            dataIndex: 'supplierName',
            key: 'supplierName',
            render: (text: string) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ backgroundColor: '#E5E9F2', color: '#526484', marginRight: 8 }}>
                        {getUserLabel(text)}
                    </Avatar>
                    {text}
                </div>
            ),
        },
        {
            title: 'Avg. Response Time',
            dataIndex: 'responseTime',
            key: 'responseTime',
        },
    ];

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Quotation Response Time</h6>
                    <p>Top 5 and Bottom 5 Supplier Response Times.</p>
                </div>
                <div>
                    <Radio.Group
                        onChange={e => setViewType(e.target.value)}
                        value={viewType}
                        buttonStyle="outline"
                    // className="custom-radio"
                    >
                        <Radio.Button value="top">Top</Radio.Button>
                        <Radio.Button value="bottom">Bottom</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            {dataSource?.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="supplierID"
                    pagination={false}
                    size='small'
                    rowClassName={(record, index) => index % 2 === 0 ? '' : 'striped-row'}
                />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No Data found for the selected category" />
                </div>
            )}
        </div>
    );
};

export default QuotationResponseTime;
