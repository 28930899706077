import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  AlertOutlined,
} from "@ant-design/icons";

const statusMapper: any = {
  "outstanding": {
    buyer: {
      title: "Outstanding",
      color: "gold", 
      icon: <HourglassOutlined />, 
    },
    seller: {
      title: "Outstanding",
      color: "gold",
      icon: <HourglassOutlined />,
    },
  },
  "overdue7": {
    buyer: {
      title: "Overdue By 7 Days",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Overdue By 7 Days",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "overdue15": {
    buyer: {
      title: "Overdue By 15 Days",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Overdue By 15 Days",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "overdue30": {
    buyer: {
      title: "Overdue By Month",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Overdue By Month",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "overdueMoreThan30": {
    buyer: {
      title: "Overdue By >1 Month",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Overdue By >1 Month",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
};

export default statusMapper;
