import React, { FC, useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  message,
  Alert,
  Popover,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Spinner } from "reactstrap";
import { init } from "echarts";

const { Option } = Select;

interface AllocationDrawerProps {
  visible: boolean;
  onClose: () => void;
  materials: any[]; // Array of selected materials
  supplierQuotations: SupplierQuotation[]; // Array of supplier quotations
  onSubmit: (values: any) => void; // Function to handle form submission
  submittedData?: SubmittedDataItem[]; // Optional: Previously submitted data for repopulation
}

type SupplierQuotation = {
  supplierID: string;
  supplierName: string;
  quotation: {
    materialID: string;
    unitPrice: number;
    supplyQty: number;
    minQty: number;
    awardedQty: number;
    remainingQty: number;
  }[];
  subTotal: number;
  totalTaxes: number;
  paymentTerms: string;
  leadTime: string;
  grandTotal: number;
};

type SubmittedDataItem = {
  quotationLineId: number;
  materialID: string;
  materialName: string;
  supplierID: string;
  awardQty: string;
  reason: string;
};

const AllocationDrawer: FC<AllocationDrawerProps> = ({
  visible,
  onClose,
  materials,
  supplierQuotations,
  onSubmit,
  submittedData = [], // Default to empty array if not provided
}) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [form] = Form.useForm();
  const [moqs, setMoqs] = useState<{ [key: string]: number | "N/A" }>({});
  const [alreadyAwardedQty, setAlreadyAwardedQty] = useState<{
    [key: string]: number;
  }>({});
  const [mainRemainingQty, setMainRemainingQty] = useState<{
    [key: string]: number;
  }>({});
  const [remainingQty, setRemainingQty] = useState<{ [key: string]: number }>(
    {}
  );
  const [showError, setShowError] = useState<boolean>(false);
  const [previousValues, setPreviousValues] = useState<{
    [key: string]: number;
  }>({});
  const [selectedSupplier, setSelectedSupplier]: any = useState(null);

  const [popoverData, setPopoverData] = useState<{
    [key: string]: {
      awardedQty: number;
      supplyQty: number;
      reservedQty: number;
      remainingQty: number;
    };
  }>({});

  const [awardQtys, setAwardQtys] = useState<{ [key: string]: number[] }>({});
  const [localSubmittedData, setLocalSubmittedData] = useState(submittedData);

  const resetAllState = () => {
    setDisableSubmit(false);
    setMoqs({});
    setRemainingQty({});
    setMainRemainingQty({});
    setPreviousValues({});
    setAwardQtys({});
    setPopoverData({});
    setSelectedSupplier(null);
  };

  const getSuppliersForMaterial = (materialID: string) => {
    console.log("supplierQuotations", supplierQuotations, materialID);
    return supplierQuotations?.filter((supplier) =>
      supplier.quotation.some(
        (q: any) =>
          q.quotationLineId === materialID &&
          q.unitPrice > 0 &&
          q.remainingQty > 0
      )
    );
  };

  const getSuppliersForMultipleMaterials = (materials: any) => {
    const materialIDs = materials.map(
      (material: any) => material.quotationLineId
    );
    return supplierQuotations.filter((supplier) =>
      materialIDs.every((num: any) =>
        supplier.quotation.some(
          (line: any) =>
            line.quotationLineId === num &&
            line.unitPrice > 0 &&
            line.remainingQty > 0
        )
      )
    );
  };

  useEffect(() => {
    setMoqs({});
    setPreviousValues({});
    setRemainingQty({});
  }, [materials, supplierQuotations]);

  const handleSupplierChange = (
    supplierID: string,
    materialID: string,
    materialKey: string,
    name: string | number,
    index: number
  ) => {
    console.log("Reserved", materialID, supplierQuotations, supplierID);
    const supplier = supplierQuotations?.find(
      (s) => s.supplierID == supplierID
    );
    console.log("supplierID", supplier);
    const quotation = supplier?.quotation.find(
      (q: any) => q.quotationLineId == materialID
    );

    const fieldKey =
      materials?.length > 1
        ? `${materialKey}_${index}`
        : `${materialKey}_${name}`;
    console.log("fieldKey", fieldKey);
    if (quotation) {
      console.log("11111");
      setMoqs((prevMoqs) => ({
        ...prevMoqs,
        awardedQty: quotation.awardedQty,
        supplyQty: quotation.supplyQty,
        remainingQty: quotation.remainingQty,
        [fieldKey]: quotation.minQty,
      }));
      console.log("moqs", moqs);
      setPopoverData((prev) => ({
        ...prev,
        [fieldKey]: {
          awardedQty: quotation.awardedQty,
          supplyQty: quotation.supplyQty,
          reservedQty: 0,
          remainingQty: quotation.remainingQty,
        },
      }));
      setSelectedSupplier(supplierID);
      console.log("popverDAT", popoverData);
    } else {
      setMoqs((prevMoqs) => ({
        ...prevMoqs,
        [fieldKey]: "N/A",
      }));
      setPopoverData((prev) => ({
        ...prev,
        [fieldKey]: {
          awardedQty: 0,
          supplyQty: 0,
          reservedQty: 0,
          remainingQty: 0,
        },
      }));
    }
  };
  const handleGlobalSupplierChange = (supplierID: string) => {
    console.log("globalSuppliers::", materials);
    materials.forEach((material, index) => {
      handleSupplierChange(
        supplierID,
        material.quotationLineId,
        material.key,
        index,
        index
      );
    });
  };

  const handleAwardQtyChange = (
    materialID: string,
    newValue: number,
    supplierIndex: number,
    key: any
  ) => {
    console.log("key", key);
    setPreviousValues((prev: any) => {
      console.log(
        "prev",
        prev,
        "materialID",
        materialID,
        "newValue",
        newValue,
        "supplierIndex",
        supplierIndex
      );
      const updatedAwardQtys = [...(prev[materialID] || [])];
      updatedAwardQtys[key] = newValue; // Update the award qty for this supplier

      // Update the remaining quantity based on the updated award quantities
      updateRemainingQty(materialID, updatedAwardQtys);

      return {
        ...prev,
        [materialID]: updatedAwardQtys,
      };
    });
  };

  // Update remaining quantity based on award quantities
  const updateRemainingQty = (
    materialID: string,
    updatedAwardQtys: number[]
  ) => {
    setAwardQtys((prevAwardQtys) => {
      console.log(
        "prevAwardQtys",
        prevAwardQtys,
        "materialID",
        materialID,
        "updatedAwardQtys",
        updatedAwardQtys
      );
      const updatedQtys = {
        ...prevAwardQtys,
        [materialID]: updatedAwardQtys, // Store the updated award quantities for the material
      };
      console.log("updatedQtys", updatedQtys);
      // Calculate total award quantity for the material
      const totalAwardQty = updatedAwardQtys.reduce(
        (total, qty) => total + (qty || 0),
        0
      );
      console.log("remain", mainRemainingQty, mainRemainingQty[materialID]);
      const mainValue = mainRemainingQty[materialID];
      console.log("mainValue", mainValue);
      // const mainRem = remainingQty

      // Update the remaining quantity for the material
      setRemainingQty((prevRemaining) => {
        const initialRemainingQty =
          prevRemaining[materialID] ||
          materials.find((mat) => mat.materialID === materialID)?.qty ||
          0;
        const updatedRemainingQty =
          mainRemainingQty[materialID] - totalAwardQty;

        console.log("Material ID:", materialID);
        console.log("Total Award Qty:", totalAwardQty);
        console.log("Updated Remaining Qty:", updatedRemainingQty);

        return {
          ...prevRemaining,
          [materialID]: Math.max(updatedRemainingQty, 0), // Ensure remaining qty doesn't go negative
        };
      });

      return updatedQtys;
    });
  };

  const handleRemoveItem = (materialID: string, awardQty: number) => {
    setRemainingQty((prev) => {
      const updatedQty = (prev[materialID] || 0) + awardQty;
      return {
        ...prev,
        [materialID]: Math.max(updatedQty, 0), // Ensure no negative values
      };
    });
  };

  const handleFormSubmit = (values: any) => {
    setDisableSubmit(true);
    const submittedData: SubmittedDataItem[] = [];

    const globalSupplier = values.globalSupplier;
    let isMaterialSelected = false;
    materials.forEach((material) => {
      const materialKey = `material_${material.key}`;
      const materialData = values[materialKey];

      if (Array.isArray(materialData)) {
        materialData.forEach((entry: any) => {
          const supplierID = globalSupplier || entry.supplier;

          if (supplierID && entry.awardQty) {
            isMaterialSelected = true;
            submittedData.push({
              quotationLineId: material.quotationLineId,
              materialID: material.materialID,
              materialName: material.materialName,
              supplierID: supplierID,
              awardQty: entry.awardQty,
              reason: entry.reason,
            });
          }
        });
      }
    });

    // Check if global supplier is selected but no material is allocated
    if (globalSupplier && !isMaterialSelected) {
      setShowError(true);
      setDisableSubmit(false);
      return; // Stop form submission
    }

    console.log("Submitted Data:", JSON.stringify(submittedData, null, 2));

    onSubmit(submittedData);
    setLocalSubmittedData(submittedData);
    resetAllState();
    onClose();
  };

  useEffect(() => {
    console.log("visible", remainingQty, mainRemainingQty);
    if (visible) {
      resetAllState();
      console.log("materials", materials);
      setDisableSubmit(false);
      // Calculate total awarded quantities based on submitted data
      // const awardedQuantities = materials.reduce((acc, material) => {
      //     const totalAwarded = localSubmittedData
      //         .filter(item => item.materialID === material.materialID)
      //         .reduce((sum, item) => sum + parseFloat(item.awardQty || '0'), 0);

      //     acc[material.materialID] = totalAwarded;
      //     return acc;
      // }, {} as { [key: string]: number });
      // console.log("awardedQuantities", awardedQuantities);
      // // Calculate initial remaining quantities (based on materials and awarded quantities)
      // const initialRemainingQty: {} = materials.reduce((acc, material) => {
      //     acc[material.materialID] = material.remainingQty - (awardedQuantities[material.materialID] || 0);
      //     return acc;
      // }, {} as { [key: string]: number });

      // console.log("initialRemainingQty", initialRemainingQty);

      const awardedQuantities = materials.reduce((acc, material) => {
        acc[material.materialID] = 0; // No awarded quantities initially
        return acc;
      }, {} as { [key: string]: number });

      const initialRemainingQty = materials.reduce((acc, material) => {
        acc[material.materialID] = material.remainingQty; // Use original remaining quantity
        return acc;
      }, {} as { [key: string]: number });

      // Set initial remaining quantities for both main and current remaining
      setMainRemainingQty(initialRemainingQty);
      setRemainingQty(initialRemainingQty);

      // Initialize form fields with default values
      form.setFieldsValue(
        materials.reduce((acc, material) => {
          acc[`material_${material.key}`] = [{ awardQty: "", reason: "" }];
          return acc;
        }, {} as any)
      );
    } else {
      // Reset state when the drawer is closed
      setRemainingQty({});
      setPreviousValues({});
      resetAllState();
      setMainRemainingQty({});
      form.resetFields();
      setLocalSubmittedData([]);
    }

    // Reset selected supplier when drawer is closed
    setSelectedSupplier(null);
  }, [visible, materials, form, localSubmittedData]);

  useEffect(() => {
    console.log("Popover data updated:", popoverData);
  }, [popoverData]);

  return (
    <Drawer
      title="Allocate Award"
      width={"80vw"}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          {disableSubmit ? (
            <Button color="primary" type="primary">
              <Spinner size="sm" color="light" />
            </Button>
          ) : (
            <Button onClick={() => form.submit()} type="primary">
              Submit
            </Button>
          )}
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        {showError && (
          <>
            <Alert
              message="Please allocate at least one material."
              description=""
              type="error"
              showIcon
              closable
            />
            <br />
          </>
        )}
        {materials?.length > 1 && (
          <Form.Item
            name="globalSupplier"
            label={<span>Select Supplier for All Materials</span>}
            rules={[{ required: true, message: "Please select a supplier" }]}
            style={{ width: "300px", marginBottom: "20px" }}
          >
            <Select
              placeholder="Select Supplier"
              onChange={handleGlobalSupplierChange}
              allowClear
              // getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
              // dropdownStyle={{ zIndex: 10500 }}
              // onChange={(supplierID) =>
              //     handleSupplierChange(supplierID, material.quotationLineId, material.key, name, index)
              // }
            >
              <Option value="">-Select-</Option>
              {getSuppliersForMultipleMaterials(materials).map((supplier) => (
                <Option key={supplier.supplierID} value={supplier.supplierID}>
                  {supplier.supplierName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {materials.map((material, index) => (
          <div key={material.key}>
            <Row gutter={16} className="mb-4">
              <Col span={12}>
                <h6>Material: {material.materialName}</h6>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <h6>
                  Asked Quantity: {material.qty}
                  <br />
                  Remaining Quantity:{" "}
                  {remainingQty[material.materialID] !== undefined
                    ? remainingQty[material.materialID]
                    : material.qty}
                </h6>
              </Col>
            </Row>

            <Form.List name={`material_${material.key}`}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const currentAwardQty = Number(
                      form.getFieldValue([
                        `material_${material.key}`,
                        name,
                        "awardQty",
                      ]) || 0
                    );

                    return (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          minWidth: "120px",
                        }}
                        align="baseline"
                      >
                        {materials?.length === 1 && (
                          <Form.Item
                            {...restField}
                            name={[name, "supplier"]}
                            label={
                              <span>
                                Supplier
                                {/* {`${material.key}_${name}`} */}
                                {/* {selectedSupplier && (
                                                                    <Popover
                                                                        key={`${material.key}_${name}`}
                                                                        content={
                                                                            <div>
                                                                                <p>Supply Quantity: {popoverData[`${material.key}_${name}`]?.supplyQty || 'N/A'}</p>
                                                                                <p>Reserved Quantity: {popoverData[`${material.key}_${name}`]?.awardedQty || 'N/A'}</p>
                                                                                <p>Remaining Quantity: {popoverData[`${material.key}_${name}`]?.remainingQty || 'N/A'}</p>
                                                                            </div>
                                                                        }
                                                                        title="Details"
                                                                    >
                                                                        <InfoCircleOutlined style={{ marginLeft: 8, verticalAlign: 'middle' }} />
                                                                    </Popover>
                                                                )} */}
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please select a supplier",
                              },
                            ]}
                            style={{ width: "200px" }}
                          >
                            <Select
                              placeholder="Select Supplier"
                              allowClear
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode as HTMLElement
                              }
                              dropdownStyle={{ zIndex: 10500 }}
                              onChange={(supplierID) =>
                                handleSupplierChange(
                                  supplierID,
                                  material.quotationLineId,
                                  material.key,
                                  name,
                                  index
                                )
                              }
                            >
                              <Option value="">-Select-</Option>
                              {getSuppliersForMaterial(
                                material.quotationLineId
                              ).map((supplier) => (
                                <Option
                                  key={supplier.supplierID}
                                  value={supplier.supplierID}
                                >
                                  {supplier.supplierName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        <div style={{ width: "200px", marginTop: "5px" }}>
                          <strong>
                            Min Order Qty:
                            {selectedSupplier &&
                              (materials?.length === 1 ? (
                                <Popover
                                  key={`${material.key}_${name}`} // Force re-render on key change
                                  content={
                                    <div>
                                      <p>
                                        Supply Quantity:{" "}
                                        {popoverData[`${material.key}_${name}`]
                                          ?.supplyQty || "N/A"}
                                      </p>
                                      <p>
                                        Reserved Quantity:{" "}
                                        {popoverData[`${material.key}_${name}`]
                                          ?.awardedQty || "N/A"}
                                      </p>
                                      <p>
                                        Remaining Quantity:{" "}
                                        {popoverData[`${material.key}_${name}`]
                                          ?.remainingQty || "N/A"}
                                      </p>
                                    </div>
                                  }
                                  title="Details"
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      marginLeft: 8,
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Popover>
                              ) : (
                                <Popover
                                  key={`${material.key}_${index}`} // Force re-render on key change
                                  content={
                                    <div>
                                      <p>
                                        Supply Quantity:{" "}
                                        {popoverData[`${material.key}_${index}`]
                                          ?.supplyQty || "N/A"}
                                      </p>
                                      <p>
                                        Reserved Quantity:{" "}
                                        {popoverData[`${material.key}_${index}`]
                                          ?.awardedQty || "N/A"}
                                      </p>
                                      <p>
                                        Remaining Quantity:{" "}
                                        {popoverData[`${material.key}_${index}`]
                                          ?.remainingQty || "N/A"}
                                      </p>
                                    </div>
                                  }
                                  title="Details"
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      marginLeft: 8,
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Popover>
                              ))}
                          </strong>
                          <div
                            style={{
                              marginTop: 18,
                              background: "#f1f1f1",
                              borderRadius: 5,
                              padding: "5px 10px",
                            }}
                          >
                            {/* {moqs[materials?.length > 1 ? `${material.key}_${index}` : `${material.key}_${name}`] ?? 'N/A'} */}
                            {moqs[
                              materials?.length > 1
                                ? `${material.key}_${index}`
                                : `${material.key}_${name}`
                            ]
                              ? Number(
                                  moqs[
                                    materials?.length > 1
                                      ? `${material.key}_${index}`
                                      : `${material.key}_${name}`
                                  ]
                                ).toFixed(2)
                              : "N/A"}
                          </div>
                        </div>

                        <Form.Item
                          {...restField}
                          name={[name, "awardQty"]}
                          label="Award Quantity"
                          rules={[
                            {
                              required: true,
                              message: "Please input award quantity.",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                value = Number(value); // Convert to number
                                if (value <= 0) {
                                    return Promise.reject(
                                      new Error("Award Quantity should be greater than 0")
                                    );
                                  }
                          
                                const minOrderQty = Number(
                                  moqs[
                                    materials.length > 1
                                      ? `${material.key}_${index}`
                                      : `${material.key}_${name}`
                                  ]
                                );

                                if (value > material.qty) {
                                  return Promise.reject(
                                    new Error(
                                      "Award Quantity should not exceed asked quantity"
                                    )
                                  );
                                }
                                if (
                                  typeof minOrderQty === "number" &&
                                  value < minOrderQty
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      "Award Quantity should not be less than Min Order Qty"
                                    )
                                  );
                                }
                                const currentRemainingQty =
                                  remainingQty[material.materialID] ||
                                  material.qty;
                                const adjustedRemainingQty =
                                  currentRemainingQty + currentAwardQty;
                                console.log(
                                  "Adjusted remaining quantity:",
                                  adjustedRemainingQty,
                                  remainingQty
                                );

                                if (value > adjustedRemainingQty) {
                                  return Promise.reject(
                                    new Error(
                                      "Award Quantity should not exceed remaining quantity"
                                    )
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          style={{ width: "200px" }}
                        >
                          <Input
                            placeholder="Award Quantity"
                            onBlur={(e) => {
                              const newValue = e.target.value
                                ? Number(e.target.value)
                                : 0;
                              handleAwardQtyChange(
                                material.materialID,
                                newValue,
                                index,
                                key
                              );
                              form.setFieldsValue({
                                [`awardQty_${material.key}`]: newValue,
                              });
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "reason"]}
                          label="Reason for Award"
                          style={{ width: "200px" }}
                          rules={[
                            { required: true, message: "Please Enter reason" },
                          ]}
                        >
                          <Input placeholder="Reason for Award" />
                        </Form.Item>
                        {materials?.length === 1 && fields?.length > 1 ? (
                          <MinusCircleOutlined
                            onClick={() => {
                              // Remove the awarded quantity when an item is removed
                              const awardQty = form.getFieldValue([
                                `material_${material.key}`,
                                name,
                                "awardQty",
                              ]);
                              handleRemoveItem(
                                material.materialID,
                                Number(awardQty)
                              );
                              remove(name);
                            }}
                          />
                        ) : null}
                      </Space>
                    );
                  })}
                  {materials.length === 1 && (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Supplier
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </div>
        ))}
      </Form>
    </Drawer>
  );
};

export default AllocationDrawer;
