import React from 'react';
import { Drawer, Form, InputNumber, Space } from 'antd';
import { Card, Row, Col, Button } from "reactstrap";
interface StatusIndicatorDrawerProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: any) => void;
}

const StatusIndicatorDrawer: React.FC<StatusIndicatorDrawerProps> = ({ visible, onClose, onSubmit }) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        onSubmit(values); // Pass the form values to the parent component
        onClose(); // Close the drawer
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <Drawer
            title="Set Comparison Indicators"
            width={360}
            onClose={onClose}
            open={visible}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
               

                <Form.Item
                    label="Slightly High Range"
                    required
                >
                    <Space>
                        <Form.Item
                            name={['slightlyHighRange', 'from']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="From %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                        <Form.Item
                            name={['slightlyHighRange', 'to']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="To %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                    </Space>
                </Form.Item>

                <Form.Item
                    label="High Range"
                    required
                >
                    <Space>
                        <Form.Item
                            name={['highRange', 'from']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="From %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                        <Form.Item
                            name={['highRange', 'to']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="To %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                    </Space>
                </Form.Item>

                <Form.Item
                    label="Very High Range"
                    required
                >
                    <Space>
                        <Form.Item
                            name={['veryHighRange', 'from']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="From %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                        <Form.Item
                            name={['veryHighRange', 'to']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="To %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                    </Space>
                </Form.Item>

                <Form.Item
                    label="Outlier Range"
                    required
                >
                    <Space>
                        <Form.Item
                            name={['outlierRange', 'from']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="From %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item>
                        {/* <Form.Item
                            name={['outlierRange', 'to']}
                            noStyle
                            rules={[{ required: true }]}
                        >
                            <InputNumber placeholder="To %" min={0} max={100} style={{ width: '150px' }} />
                        </Form.Item> */}
                    </Space>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                        <Button color="secondary" onClick={onReset}>
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default StatusIndicatorDrawer;
