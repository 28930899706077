import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { Icon } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import FormRadio from "../form-components/RadioComponent";
import Nouislider from "nouislider-react";

interface TaxConfigurationModalProps {
  data: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (taxConfig: any) => void;
}
interface SelectOptions {
  label: string;
  value: string;
}
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: {
    cessName: string;
    cessPercentage: string;
  }[];
}

const TaxConfigurationModal: React.FC<TaxConfigurationModalProps> = ({
  data,
  isOpen,
  setIsOpen,
  onSave,
}) => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      taxType: "",
      gstType: "CGST+SGST",
      cess: [
        {
          cessName: "",
          cessType: "",
        },
      ],
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = methods;
  const { fields, append, remove } = useFieldArray({ control, name: "cess" });
  const [cessTypeOptions, setCessTypeOptions] = useState<SelectOptions[]>([
    {
      label: "State Cess",
      value: "stateCess",
    },
    {
      label: "Central Cess",
      value: "centralCess",
    },
  ]);
  const [taxTypeOptions, setTaxTypeOptions] = useState<SelectOptions[]>([
    {
      label: "GST",
      value: "GST",
    },
    {
      label: "PVT",
      value: "PVT",
    },
    {
      label: "ABC",
      value: "ABC",
    },
    {
      label: "DEF",
      value: "DEF",
    },
  ]);
  const [showCess, setShowCess] = useState(true);
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setValue("taxType", "GST");
    setValue("gstType", data?.gstType);
    setValue("cess", data?.cess);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="modal-dialog-centered"
      size="xl"
      backdrop="static"
    >
      <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          setIsOpen(false);
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <ModalBody>
        <h5 className="title">Add Tax Configuration</h5>
        <div style={{ marginTop: "15px" }}></div>
        {showAlert?.status && (
          <Alert
            color={showAlert.type}
            isOpen={showAlert.status}
            toggle={() => setShowAlert({ ...showAlert, status: false })}
          >
            {showAlert.message}
          </Alert>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSave)}>
            <Row className="mt-12">
              <div className="col-md-5">
                <FormSelect
                  name="taxType"
                  label="Tax Type"
                  disabled
                  required={true}
                  options={taxTypeOptions}
                  defaultValue={taxTypeOptions[0]}
                  onChange={(e) => {
                    if (e == "GST") {
                      setShowCess(true);
                    } else {
                      setShowCess(false);
                    }
                  }}
                />
              </div>

              <div
                className="col-md-4"
                style={{ display: "flex", marginTop: "18px" }}
              >
                <FormRadio
                  name="gstType"
                  optionLabel="IGST"
                  value="IGST"
                  required={true}
                  radioSize="sm"
                />
                <div style={{ marginLeft: "38px" }}></div>
                <FormRadio
                  name="gstType"
                  optionLabel="CGST+SGST"
                  value="CGST+SGST"
                  required={true}
                  radioSize="sm"
                />
              </div>
              <div style={{ marginTop: "15px" }}></div>
              {showCess && (
                <Col lg="12">
                  {fields.map((field, index) => (
                    <div className="row mb-3" key={field.id}>
                      <div className="col">
                        <FormSelect
                          name={`cess[${index}].cessType`}
                          label="Cess Type"
                          options={cessTypeOptions}
                        />
                      </div>
                      <div className="col">
                        <FormInput
                          name={`cess[${index}].cessName`}
                          label="Cess Name"
                          type="text"
                          placeholder="Cess Name"
                        />
                      </div>
                      <div className="col-md-2">
                        <div style={{ marginTop: 30 }}>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              if (fields.length > 2) {
                                setShowAlert({
                                  status: true,
                                  message: "Maximum 3 cess can be added",
                                  type: "danger",
                                });
                                return;
                              }
                              append({
                                cessName: "",
                                cessType: "",
                              });
                            }}
                          >
                            <Icon name="plus"></Icon>
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => remove(index)}
                            >
                              <Icon name="trash"></Icon>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setIsOpen(false);
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default TaxConfigurationModal;
