import makeApiCall from "./axiosIndex";

const sites = {
  fetchSites: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/fetchSites" }),
  fetchSiteWithBlockById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/buyer/fetchSitesById`,
      params: { id },
    }),
  getCountrys: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/buyer/countries" }),
  createSite: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/buyer/createSite",
      data,
    }),
  updateSitesById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateSitesById`,
      params: { id },
      data,
    }),
  updateBlockById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/buyer/updateBlockById`,
      params: { id },
      data,
    }),

  getDistrictsByState: (stateId: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/districtByState`,
      params: { stateId },
    }),
  deleteSiteByID: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteSiteById`,
      params: { id },
    }),
  deleteBlockById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/buyer/deleteBlockById`,
      params: { id },
    }),
};

export default sites;
