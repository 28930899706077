import React, { useState } from "react";
import { Empty, Progress } from "antd";
import { formatCurrency, colors } from "../../utils/Utils";
import * as echarts from "echarts";
import TopPurchaseCategoriesDetailsDrawer from "./TopPurchaseCategoriesDetailsDrawer";
interface CategoryData {
  categoryID: any;
  categoryName: any;
  amountSpent: any;
  categoryPercentage: any;
}

interface DataState {
  data: CategoryData[];
}

const TopPurchaseCategoriesWidget: React.FC<any> = ({
  topPurchaseCategoriesData,
  site,
  siteOptions,
}) => {
  console.log("topPurchaseCategoriesData", topPurchaseCategoriesData);
  const [data, setData] = React.useState<DataState>({
    data: topPurchaseCategoriesData,
  });

  const [
    topPurchaseCategoriesDetailDrawerVisible,
    setTopPurchaseCategoriesDetailDrawerVisible,
  ] = useState<boolean>(false);

  const closeTopPurchaseCategoriesDetailDrawer = () => {
    setTopPurchaseCategoriesDetailDrawerVisible(false);
  };

  React.useEffect(() => {
    setData({ data: topPurchaseCategoriesData });
  }, [topPurchaseCategoriesData]);

  const totalSpent = data?.data?.reduce(
    (sum: any, item: any) => sum + item.amountSpent,
    0
  );
  console.log("data.data", data.data);
  const top5CategoryIDs = data?.data
    ?.slice(0, 5)
    ?.map((item: any) => item.categoryID);
  console.log("top5CategoryIDs", top5CategoryIDs);

  const topCategories = data?.data
    ?.filter((item: any) => top5CategoryIDs?.includes(item.categoryID))
    ?.map((item, index) => ({
      ...item,
      color: colors[index], // Dynamically assign colors from the colors array
    }));

  // Filter out the top 5 categories to get the remaining ones for the bar graph
  const remainingCategories = data?.data?.filter(
    (item) => !top5CategoryIDs.includes(item.categoryID)
  );

  React.useEffect(() => {
    const chartDom = document.getElementById(
      "remaining-bar-chart"
    ) as HTMLElement;

    if (chartDom && remainingCategories?.length > 0) {
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            console.log("params", totalSpent);
            const category = params.name;
            const amountSpent = params.value;
            const formattedAmount = formatCurrency(amountSpent, "INR");
            const categoryData = remainingCategories.find(
              (item) => item.categoryName === category
            );
            const categoryPercentage = categoryData
              ? Number(categoryData.categoryPercentage).toFixed(2)
              : "0.00";

            return `${category}: ${formattedAmount} (${categoryPercentage}%)`;
          },
        },
        xAxis: {
          type: "category",
          data: remainingCategories?.map((item) => item.categoryName),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          show: false,
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        series: [
          {
            data: remainingCategories?.map((item) => item.amountSpent),
            type: "bar",
            itemStyle: {
              color: "#E0E4FF",
            },
            barWidth: "40%",
          },
        ],
      };

      myChart.setOption(option);

      return () => {
        myChart.dispose();
      };
    }
  }, [remainingCategories, "INR"]);
  return (
    <div>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <h6 className="title"> Top Purchase Categories</h6>
          <p>
            Insights for Major Purchase Categories.
            {/* <a
              onClick={() => setTopPurchaseCategoriesDetailDrawerVisible(true)}
            >
              View
            </a> */}
          </p>
        </div>
      </div>
      {data?.data?.length > 0 ? (
        <>
          {topCategories?.map((category) => (
            <div key={category.categoryName} style={{ marginBottom: "5px" }}>
              <div style={{}} className="progress-label">
                {category.categoryName} -{" "}
                {formatCurrency(category.amountSpent, "INR")}
              </div>
              <Progress
                percent={parseFloat(
                  parseFloat(category?.categoryPercentage).toFixed(2)
                )}
                status="active"
                strokeColor={category.color}
                trailColor="#f1f1f1"
                strokeWidth={12}
              />
            </div>
          ))}
          <div
            id="remaining-bar-chart"
            style={{ width: "100%", height: "100px" }}
          />
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Data Available" />
          </div>
        </>
      )}
      <TopPurchaseCategoriesDetailsDrawer
        visible={topPurchaseCategoriesDetailDrawerVisible}
        onClose={closeTopPurchaseCategoriesDetailDrawer}
        selectedSite={site}
        siteOptions={siteOptions}
      />
      ;
    </div>
  );
};

export default TopPurchaseCategoriesWidget;
