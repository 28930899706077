import React, { useEffect } from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import { TooltipComponent } from "../../components/Component";
import GooglePlacesAutocomplete from "react-google-autocomplete";
const APIKEY = process.env.REACT_APP_GOOGLE_API;
// interface FormAutoCompleteProps {
//   name: string;
//   label: string;
//   required?: any;
//   placeholder?: string;
//   customValidation?: (value: string) => boolean | string;
//   defaultValue?: any;
//   disabled?: boolean;
//   onPlaceSelected?: (place: google.maps.places.PlaceResult) => void;
//   tooltipText?: string;
//   popOverOptions?: any;
// }

const FormAutoComplete= ({
  name,
  label,
  required = false,
  placeholder = "Enter a location",
  // customValidation,
  // defaultValue,
  // disabled,
  onPlaceSelected=(value) => {},
  latitude=null,
  longitude=null,
  tooltipText,
  // popOverOptions,
}) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : undefined,
    });
  }, [register, name, required]);

   const fetchAddressFromLatLng = async (lat, lng) => {
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIKEY}`;

    try {
      const response = await fetch(geocodingApiUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        setValue(name, formattedAddress); // Update the form field with the address
      } else {
        console.error("Geocoding API error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching address: ", error);
    }
  };

  // Call fetchAddressFromLatLng when latitude and longitude are provided
  useEffect(() => {
    if (latitude && longitude) {
      fetchAddressFromLatLng(latitude, longitude);
    }
  }, [latitude, longitude]);

  const errorMessage = getErrorMessage(errors, name);

  return (
    <div className="form-group">
      <div className="form-label-wrapper">
        <label className="form-label" htmlFor={name}>
          {label}
          {required && <span className="mandatory">*</span>}
        </label>
        {tooltipText && (
          <TooltipComponent
            iconClass="card-hint"
            icon="help-fill"
            direction="left"
            id={`${name}-tooltip`}
            text={tooltipText}
            containerClassName={""}
          />
        )}
      </div>
      <div className="form-control-wrap">
        <div className="form-control-select">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <GooglePlacesAutocomplete
                {...field}
                apiKey={APIKEY} // replace with your actual Google API key
                placeholder={placeholder}
                className="form-control"
                style={{ width: "100%", height: "38px"}}
                onPlaceSelected={(place) => {
                  field.onChange(place.formatted_address); // Updates form field with selected address
                  if (onPlaceSelected) {
                    onPlaceSelected(place); // Callback with the place data
                  }
                }}
                options={{
                  componentRestrictions: { country: "IN" }, // Restrict results to India, modify as needed
                  types: [
                    // "address",
                    // "accounting",
                    // "airport",
                    // "amusement_park",
                    // "aquarium",
                    // "art_gallery",
                    // "atm",
                    // "bakery",
                    // "bank",
                    // "bar",
                    // "beauty_salon",
                    // "bicycle_store",
                    // "book_store",
                    // "bowling_alley",
                    // "bus_station",
                    // "cafe",
                    // "campground",
                    // "car_dealer",
                    // "car_rental",
                    // "car_repair",
                    // "car_wash",
                    // "casino",
                    // "cemetery",
                    // "church",
                    // "city_hall",
                    // "clothing_store",
                    // "convenience_store",
                    // "courthouse",
                    // "dentist",
                    // "department_store",
                    // "doctor",
                    // "drugstore",
                    // "electrician",
                    // "electronics_store",
                    // "embassy",
                    // "fire_station",
                    // "florist",
                    // "funeral_home",
                    // "furniture_store",
                    // "gas_station",
                    // "gym",
                    // "hair_care",
                    // "hardware_store",
                    // "hindu_temple",
                    // "home_goods_store",
                    // "hospital",
                    // "insurance_agency",
                    // "jewelry_store",
                    // "laundry",
                    // "lawyer",
                    // "library",
                    // "light_rail_station",
                    // "liquor_store",
                    // "local_government_office",
                    // "locksmith",
                    // "lodging",
                    // "meal_delivery",
                    // "meal_takeaway",
                    // "mosque",
                    // "movie_rental",
                    // "movie_theater",
                    // "moving_company",
                    // "museum",
                    // "night_club",
                    // "painter",
                    // "park",
                    // "parking",
                    // "pet_store",
                    // "pharmacy",
                    // "physiotherapist",
                    // "plumber",
                    // "police",
                    // "post_office",
                    // "primary_school",
                    // "real_estate_agency",
                    // "restaurant",
                    // "roofing_contractor",
                    // "rv_park",
                    // "school",
                    // "secondary_school",
                    // "shoe_store",
                    // "shopping_mall",
                    // "spa",
                    // "stadium",
                    // "storage",
                    // "store",
                    // "subway_station",
                    // "supermarket",
                    // "synagogue",
                    // "taxi_stand",
                    // "tourist_attraction",
                    // "train_station",
                    // "transit_station",
                    // "travel_agency",
                    // "university",
                    // "veterinary_care",
                    // "zoo",
                  ],
                }}
              />
            )}
          />
          {errorMessage && <p className="mandatory">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

function getErrorMessage(
  errors,
  fieldName
){
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean);
  let currentError= errors;

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default FormAutoComplete;
