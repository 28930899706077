import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import UserProfileAside from "./UserProfileAside";
import { FormProvider, useForm } from "react-hook-form";
import FormInputSwitch from "../../form-components/FormInputSwitch";
import miscService from "../../../api/misc/misc";

const UserProfileNotificationPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({
    unusualActivity: false,
    browserSignin: false,
    latestNews: false,
    newFeatures: false,
    tips: false,
  });
  const methods = useForm({
    mode: "onChange",
  });

  const { watch } = methods;
  const unusualActivity = watch("unusualActivity");
  useEffect(() => {
    handleSwitchChange("unusualActivity", unusualActivity);
  }, [unusualActivity]);
  const browserSignin = watch("browserSignin");
  useEffect(() => {
    handleSwitchChange("browserSignin", browserSignin);
  }, [browserSignin]);
  const latestNews = watch("latestNews");
  useEffect(() => {
    handleSwitchChange("latestNews", latestNews);
  }, [latestNews]);
  const newFeatures = watch("newFeatures");
  useEffect(() => {
    handleSwitchChange("newFeatures", newFeatures);
  }, [newFeatures]);
  const tips = watch("tips");
  useEffect(() => {
    handleSwitchChange("tips", tips);
  }, [tips]);

  // Function to handle API call
  const handleSwitchChange = async (settingName, value) => {
    if (value == undefined || notificationSettings[settingName] == value)
      return;
    console.log(settingName, " ", value);
    
    try {
      const response = await miscService.updateNotificationSettings({
        ...notificationSettings,
        [settingName]: value,
      })

      if (response) {
        setNotificationSettings((prevSettings) => ({
          ...prevSettings,
          [settingName]: value,
        }));
      }
    } catch (error) {
      console.error("Error updating notification settings", error);
    }
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    fetchNotificationSettings() 
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  const fetchNotificationSettings = async () => {
   try {
    const res=await miscService.fetchNotificationSettings();
    const { data, status } = res;
    if(status){
      const _data=data[0];
      setNotificationSettings((pre)=>{
        return {
          ...pre,
          unusualActivity:_data?.unusualActivity,
          browserSignin:_data?.browserSignin,
          latestNews:_data?.latestNews,
          newFeatures:_data?.newFeatures,
          tips:_data?.tips
        }
      }) 
    } 
   } catch (error) {
    console.log(error); 
   } 
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <UserProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Notification Settings</BlockTitle>
                    <BlockDes>
                      <p>You will get only notification what have enabled.</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <FormProvider {...methods}>
                <form onSubmit={() => {}}>
                  <BlockContent>
                    <div className="gy-3">
                      <div className="g-item">
                        <div className="custom-control p-0">
                          <FormInputSwitch
                            name="unusualActivity"
                            defaultChecked={
                              notificationSettings.unusualActivity
                            }
                            optionLabel="Email me whenever encounter unusual activity"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control p-0">
                          <FormInputSwitch
                            name="browserSignin"
                            defaultChecked={notificationSettings.browserSignin}
                            optionLabel="Email me if new browser is used to sign in"
                          />
                        </div>
                      </div>
                    </div>
                  </BlockContent>

                  <BlockContent>
                    <div className="gy-3">
                      <div className="g-item">
                        <div className="custom-control p-0">
                          <FormInputSwitch
                            name="latestNews"
                            defaultChecked={notificationSettings.latestNews}
                            optionLabel="Notify me by email about sales and latest news"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control p-0">
                          <FormInputSwitch
                            name="newFeatures"
                            defaultChecked={notificationSettings.newFeatures}
                            optionLabel="Email me about new features and updates"
                          />
                        </div>
                      </div>
                      <div className="g-item">
                        <div className="custom-control p-0">
                          <FormInputSwitch
                            name="tips"
                            defaultChecked={notificationSettings.tips}
                            optionLabel="Email me about tips on using account"
                          />
                        </div>
                      </div>
                    </div>
                  </BlockContent>
                </form>
              </FormProvider>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileNotificationPage;
