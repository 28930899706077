import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormTextArea from "../../form-components/TextAreaComponent";
import { Icon, Button } from "../../../components/Component";
import moment from "moment";
import { SelectOptions } from "./types"; // Import your types
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import supplierApis from "../../../api/master/supplier";
import materialsApis from "../../../api/master/materials";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import adminOrgApis from "../../../api/master/adminOrg";

interface bomLine {
  categoryId: string;
  categoryName: string;
  materialId: string;
  materialName: string;
  quantity: string;
  uomId: string;
  uomName: string;
  allocated: string;
}

interface FormData {
  budgetLineName: string;
  budgetValue: string;
  startDate: any;
  endDate: any;
  bom: string;
  allocation: string;
  lineDescription: string;
  currencyCode: string;
  bomLines: bomLine[];
}

function parseValue(value: any) {
  const [id, name] = value.split("/");
  return {
    id,
    name,
  };
}

const AddNewMaterial: FC<any> = ({
  modal,
  closeModal,
  addRow,
  currentMaterial,
  formData,
  budgetStartDate,
  budgetEndDate
}) => {
  const methods = useForm<FormData>({
    defaultValues: {
      budgetLineName: "",
      budgetValue: "",
      startDate: "",
      endDate: "",
      bom: "",
      allocation: "",
      lineDescription: "",
      currencyCode: "",
      bomLines: [],
    },
    mode: "onChange",
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = methods;
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bomLines",
  });

  const billOfMaterialWatch = watch("bom");
  const budgetValueWatch = watch("budgetValue");
  const bomLinesWatch = watch("bomLines");

  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);


  const addNewDocument = () => {
    if (fields.length < 10) {
      append({
        categoryId: "",
        categoryName: "",
        materialId: "",
        materialName: "",
        quantity: "",
        uomId: "",
        uomName: "",
        allocated: "",
      });
    }
  };

  useEffect(() => {
    if (currentMaterial) {
      reset({
        budgetLineName: currentMaterial.budgetLineName,
        budgetValue: currentMaterial.budgetValue,
        startDate: dayjs(currentMaterial.startDate),
        endDate: dayjs(currentMaterial.endDate),
        bom: currentMaterial.bom,
        currencyCode: currentMaterial.currencyCode,
        allocation: currentMaterial.allocation,
        lineDescription: currentMaterial.lineDescription,
        bomLines: currentMaterial.bomLines,
      });
      setFileName(currentMaterial.fileName); // Set the file name in the state
    } else {
      reset({
        budgetLineName: "",
        budgetValue: "",
        startDate: "",
        endDate: "",
        bom: "",
        allocation: "",
        lineDescription: "",
        currencyCode: "",
        bomLines: [],
      });
      setFileName(undefined); // Clear the file name in the state
    }
  }, [currentMaterial, reset]);

  useEffect(() => {
    console.log("billOfMaterialWatch", billOfMaterialWatch);
    if (billOfMaterialWatch === "Y" && fields.length === 0) {
      append({
        categoryId: "",
        categoryName: "",
        materialId: "",
        materialName: "",
        quantity: "",
        uomId: "",
        uomName: "",
        allocated: "",
      });
    }
  }, [billOfMaterialWatch, append, fields.length]);

  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        setMaterialCategoryOptions(data);
        const materialOption = data.map((item: any) => ({
          label: item.productName,
          value: item.materialID.toString(),
        }));
        setMaterialOptions(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (index: number, value: string) => {
    const selectedCategory = categoryOptions.find(
      (option) => option.value === value
    );
    if (selectedCategory) {
      setValue(`bomLines.${index}.categoryId`, value);
      setValue(`bomLines.${index}.categoryName`, selectedCategory.label);
    }
  };

  const handleMaterialChange = (index: number, value: string) => {
    const selectedMaterial = materialOptions.find(
      (option) => option.value === value
    );
    if (selectedMaterial) {
      setValue(`bomLines.${index}.materialId`, value);
      setValue(`bomLines.${index}.materialName`, selectedMaterial.label);
    }
  };


  const handleUOMChange = (index: number, value: string) => {
    const selectedUom = uomOptions.find(
      (option) => option.value === value
    );
    if (selectedUom) {
      setValue(`bomLines.${index}.uomId`, value);
      setValue(`bomLines.${index}.uomName`, selectedUom.label);
    }
  }

  const submitAction = (data: FormData) => {
    // const material = parseValue(data.material);
    // const category = parseValue(data.category);
    // data.bomLines = data.bomLines.map((item) => {

    // })
    const totalAllocated = data.bomLines.reduce((sum, item) => {
      return sum + parseFloat(item.allocated || "0");
    }, 0);

    const budgetValue = parseFloat(data.budgetValue);
    if (data.bom === "Y" && totalAllocated > budgetValue) {
      // Show validation message if allocated value exceeds budget
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Total Allocated value in BOM Lines cannot exceed the Budget Value.",
      });
      return;
    }
    data.startDate = dayjs(data.startDate).format("YYYY-MM-DD");
    data.endDate = dayjs(data.endDate).format("YYYY-MM-DD");
    data.currencyCode = "INR"
    console.log("data", data);
    addRow(data);
    reset({
      budgetLineName: "",
      budgetValue: "",
      startDate: "",
      endDate: "",
      bom: "",
      allocation: "",
      lineDescription: "",
      currencyCode: "",
      bomLines: [],
    });
    setFileName(undefined); // Clear the file name in the state
  };

  const fetchUOMOPtions = async () => {
    try {
        const { data, status, message } = await adminOrgApis.fetchUom();
        if (status) {
            setUomOptions(
                data.map((item: any) => {
                    return {
                        label: item.uom_name,
                        value: item.id.toString(),
                    };
                })
            );
        } else {
            console.log(message);
        }
    } catch (error) {
        console.log(error);
    }
};

  useEffect(() => {
    // fetchAddresses();
    fetchMaterials();
    fetchCategorys();
    fetchUOMOPtions();
  }, []);

  const startDate = watch('startDate');



  return (
    <Modal
      isOpen={modal}
      toggle={closeModal}
      className="modal-dialog-centered"
      size="xl"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {currentMaterial ? "Update Line" : "Add New Line"}
          </h5>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitAction)}>
              <Row className="gy-4 mt-2">
                <Col lg="6">
                  <FormInput
                    name="budgetLineName"
                    label="Budget Line Name"
                    required={true}
                    placeholder="Enter Budget Line Name"
                  />
                </Col>

                <Col lg="6">
                  <FormInput
                    min={1}
                    name="budgetValue"
                    type="number"
                    label="Budget Value"
                    required={true}
                    placeholder="Enter Budget Value"
                  />
                </Col>
                <Col lg="6">
                  <FormDatePicker
                    name="startDate"
                    label="Planned Start Date"
                    placeholder="Select Start Date"
                    required={true}
                    minDate={new Date(budgetStartDate)}
                    maxDate={new Date(budgetEndDate)}
                  />
                </Col>
                <Col lg="6">
                  <FormDatePicker
                    name="endDate"
                    label="Planned End Date"
                    placeholder="Select End Date"
                    required={true}
                    minDate={startDate}
                    maxDate={new Date(budgetEndDate)}
                  />
                </Col>
                <Col lg="6">
                  <FormSelect
                    name="bom"
                    label="Bill Of Material (BOM)"
                    options={[
                      { label: "Yes", value: "Y" },
                      { label: "No", value: "N" },
                    ]}
                    required={true}
                    placeholder="Select BOM"
                    onChange={(e) => {
                      console.log("e.target.value", e);
                      if (e === "Y" && fields.length === 0) {
                        append({
                          categoryId: "",
                          categoryName: "",
                          materialId: "",
                          materialName: "",
                          quantity: "",
                          allocated: "",
                          uomName: "",
                          uomId: "",
                        });
                      } else if (e === "N") {
                        reset({
                          ...methods.getValues(),
                          bomLines: [],
                        });
                      }
                    }}
                  />
                </Col>
                {billOfMaterialWatch === "Y" && (
                  <Col lg="6">
                    <FormSelect
                      name="allocation"
                      label="BOM Allocation"
                      options={[
                        { label: "Value", value: "value" },
                        { label: "%", value: "percentage" },
                      ]}
                      required={true}
                      placeholder="Select BOM Allocation"
                    />
                  </Col>
                )}
                <Col lg="12">
                  <FormTextArea
                    name="lineDescription"
                    label="Budget Line Description"
                    required={true}
                    placeholder="Enter Budget Line Description"
                  />
                </Col>
              </Row>

              {billOfMaterialWatch === "Y" && (
                <Row className="gy-4 mt-2">
                  {fields.map((field, index) => {
                    const materialCategory = watch(`bomLines.${index}.categoryId`);
                    return (
                      <Row className="mt-2" key={field.id}>
                        <Col lg="2">
                          <FormSelect
                            name={`bomLines.${index}.categoryId`}
                            label="Category"
                            options={categoryOptions}
                            required={true}
                            onChange={(value) =>
                              handleCategoryChange(index, value)
                            }
                          />
                        </Col>
                        <Col lg="2">
                          <FormSelect
                            name={`bomLines.${index}.materialId`}
                            label="Material Name"
                            options={materialCategoryOptions?.filter(
                              (item: any) => item.category == materialCategory
                            )?.map((item: any) => ({
                              label: item.productName,
                              value: item.materialID,
                            }))}
                            required={true}
                            onChange={(value) =>
                              handleMaterialChange(index, value)
                            }
                          />
                        </Col>
                        <Col lg="2">
                          <FormInput
                            min={1}
                            name={`bomLines.${index}.quantity`}
                            label="Qty"
                            type="number"
                            required={true}
                          />
                        </Col>
                        <Col lg="2">
                        <FormSelect
                            name={`bomLines.${index}.uomId`}
                            label="UOM"
                            options={uomOptions}
                            required={true}
                            onChange={(value) =>
                              handleUOMChange(index, value)
                            }
                          />
                        </Col>
                        <Col lg="2">
                          <FormInput
                            min={1}
                            name={`bomLines.${index}.allocated`}
                            label="Allocation"
                            type="number"
                            required={true}
                          />
                        </Col>

                        <Col lg="2" className="d-flex align-items-center">
                          <a
                            onClick={() => remove(index)}
                            className="danger"
                            style={{ fontSize: 16, color: "red", marginTop: 14 }}
                          >
                            <MinusCircleOutlined /> Remove
                          </a>
                        </Col>
                      </Row>
                    )
                  }
                  )}
                  <Row className="mt-2">
                    <Col lg="12">
                      <a
                        onClick={addNewDocument}
                        className="primary"
                        style={{ fontSize: 16 }}
                      >
                        <PlusCircleOutlined /> Add More
                      </a>
                    </Col>
                  </Row>
                </Row>
              )}

              <Row className="mt-4">
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        <span>Submit</span>
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          closeModal();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddNewMaterial;