import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  RSelect,
  UserAvatar,
  TooltipComponent,
} from "../../../components/Component";
import axios from "../../../axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { getDateStructured } from "../../../utils/Utils";
import supplierApis from "../../../api/master/supplier";
import { useNavigate } from "react-router-dom";
import contractorsApis from "../../../api/master/contractors";

import FormInput from "../../form-components/InputComponent";
import consultantsApis from "../../../api/master/consultants";
import FormatDate from "../DateFormater";
import { useForm } from "react-hook-form";

interface SelectOptions {
  label: string;
  value: string;
}
const ViewConsultantDetails: FC = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { consultantId } = useParams();
  const [data, setData]: any = useState();
  const [modalGI, setGIModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [submitEditData, setSubmitEditData] = useState(false);
  const navigate = useNavigate();
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [formData, setFormData]: any = useState({
    companyName: "",
    contactPerson: "",
    emailAddress: "",
    phoneNumber: "",
    mailingAddress: "",
    businessDescription: "",
    yearsInBusiness: "",
    otherReferences: "",
    regulatoryCompliance: "",
    licensesAndPermits: "",
    insuranceCoverage: "",
    servicesOffered: "",
    expertiseAreas: "",
    pastProjects: "",
    clientTestimonials: "",
    paymentTerms: "",
    billingRates: "",
    contractTerms: "",
    insuranceRequirements: "",
    siteAccessProcedures: "",
    coordinationWithOtherContractors: "",
    environmentalCompliance: "",
    safetyProtocols: "",
    emergencyContactPerson: "",
    emergencyContactNumber: "",
    emergencyProcedures: "",
    disputeResolutionProcess: "",
  });

  const submitForm = () => {
    console.log("Final form data:", formData);
    let submitData = {
      ...formData,
    };
    setData(submitData);
    setSubmitEditData(true);
    setGIModal(false);
  };

  const onInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = consultantId;
    if (id !== undefined || null || "") {
      fetchConsultantById(id);
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [consultantId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };

  const submitUpdates = async () => {
    console.log("submitData", JSON.stringify(data));

    const { status, message } = await consultantsApis.updateConsultantById(
      consultantId,
      data
    );
    if (status) {
      Swal.fire("Updated!", "Consultant has been updated", "success");
      navigate(`/consultants`);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchConsultantById = async (id: any) => {
    try {
      const { data, status, message } =
        await consultantsApis.fetchConsultantById(id);
      if (status) {
        const newData = data;
        console.log(newData);
        setData(newData); // Update data with fetched response
        setFormData({
          companyName: newData.companyName || "",
          contactPerson: newData.contactPerson || "",
          emailAddress: newData.emailAddress || "",
          phoneNumber: newData.phoneNumber || "",
          mailingAddress: newData.mailingAddress || "",
          businessDescription: newData.businessDescription || "",
          yearsInBusiness: newData.yearsInBusiness || "",
          otherReferences: newData.otherReferences || "",
          regulatoryCompliance: newData.regulatoryCompliance || "",
          licensesAndPermits: newData.licensesAndPermits || "",
          insuranceCoverage: newData.insuranceCoverage || "",
          servicesOffered: newData.servicesOffered || "",
          expertiseAreas: newData.expertiseAreas || "",
          pastProjects: newData.pastProjects || "",
          clientTestimonials: newData.clientTestimonials || "",
          paymentTerms: newData.paymentTerms || "",
          billingRates: newData.billingRates || "",
          contractTerms: newData.contractTerms || "",
          insuranceRequirements: newData.insuranceRequirements || "",
          siteAccessProcedures: newData.siteAccessProcedures || "",
          coordinationWithOtherContractors:
            newData.coordinationWithOtherContractors || "",
          environmentalCompliance: newData.environmentalCompliance || "",
          safetyProtocols: newData.safetyProtocols || "",
          emergencyContactPerson: newData.emergencyContactPerson || "",
          emergencyContactNumber: newData.emergencyContactNumber || "",
          emergencyProcedures: newData.emergencyProcedures || "",
          disputeResolutionProcess: newData.disputeResolutionProcess || "",
        });
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="View Consultant"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Consultant Details -{" "}
                    <strong className="text-primary small">
                      {data["contactPerson"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* {submitEditData && (
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => submitUpdates()}
                    >
                      <Icon name="check-round-cut"></Icon>
                      <span>Submit Updates</span>
                    </Button>
                  )} */}

                  <Link to={`${process.env.PUBLIC_URL}/consultants`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Consultant Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("consultantInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Company Name</div>
                          <div className="data-value">{data.companyName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">{data.contactPerson}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email Address</div>
                          <div className="data-value">{data.emailAddress}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Mailing Address</div>
                          <div className="data-value">
                            {data.mailingAddress}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Consultant Profile</BlockTitle>
                        <p>PAN, GSTIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("consultantProfile")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Business Description</div>
                          <div className="data-value">
                            {data.businessDescription}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Years in Business</div>
                          <div className="data-value">
                            {data.yearsInBusiness}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">References</div>
                          <div className="data-value">
                            {data.otherReferences}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Compliance and Certifications
                        </BlockTitle>
                        <p>Some more Other details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("complianceCertifications")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Regulatory Compliance
                          </div>
                          <div className="data-value">
                            {data.regulatoryCompliance ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Licenses and Permits</div>
                          <div className="data-value">
                            {data.licensesAndPermits ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Insurance Coverage</div>
                          <div className="data-value">
                            {data.insuranceCoverage ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Scope of Works</BlockTitle>
                        <p>Basic Contact Information Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ScopeWors")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Services Offered</div>
                          <div className="data-value">
                            {data.servicesOffered}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Expertise Areas</div>
                          <div className="data-value">
                            {data.expertiseAreas}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Project Experience</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("projectExperience")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Past Projects</div>
                          <div className="data-value">{data.pastProjects}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Client Testimonials</div>
                          <div className="data-value">
                            {data.clientTestimonials}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contractual Agreements</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ContractualAgreements")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contract Terms</div>
                          <div className="data-value">{data.contractTerms}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Insurance Requirements
                          </div>
                          <div className="data-value">
                            {data.insuranceRequirements}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Project Access & Coordination
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("projectAccessCoordination")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Site Access Procedures
                          </div>
                          <div className="data-value">
                            {data.siteAccessProcedures}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Coordination With Other Contractors
                          </div>
                          <div className="data-value">
                            {data.coordinationWithOtherContractors}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload4}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Billing and Payment Terms
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("BillingPaymentTerms")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Payment Terms</div>
                          <div className="data-value">{data.paymentTerms}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Billing Rates</div>
                          <div className="data-value">{data.billingRates}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Environmental and Safety Considerations
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal(
                              "EnvironmentalSustainabilityConsiderations"
                            )
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Environmental Compliance
                          </div>
                          <div className="data-value">
                            {data.environmentalCompliance ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Safety Protocols</div>
                          <div className="data-value">
                            {data.safetyProtocols ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload2}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Emergency Response and Contingency Plans
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("EmergencyResponseContingencyPlans")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">
                            {data.emergencyContactPerson}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Emergency Contact Number
                          </div>
                          <div className="data-value">
                            {data.emergencyContactNumber}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Emergency Procedures</div>
                          <div className="data-value">
                            {data.emergencyProcedures}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload3}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Conflict Resolution</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ConflictResolution")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Dispute Resolution Process
                          </div>
                          <div className="data-value">
                            {data.disputeResolutionProcess}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload5}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "consultantInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Consultant Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      {/* <form noValidate> */}
                        <Row className="gy-4">
                          <Col
                            md="6"
                            sm={undefined}
                            lg={undefined}
                            xxl={undefined}
                            size={undefined}
                            className={undefined}
                          >
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="companyName"
                              >
                                Company Name<span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="companyName"
                                {...register("companyName", {
                                  required: "This field is required",
                                })}
                                className="form-control"
                                name="companyName"
                                required={true}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.companyName}
                                placeholder="Enter Company Name"
                              />
                              {errors.companyName &&
                                typeof errors.companyName.message ===
                                  "string" && (
                                  <span className="invalid">
                                    {errors.companyName.message}
                                  </span>
                                )}
                            </div>
                          </Col>
                          <Col
                            md="6"
                            sm={undefined}
                            lg={undefined}
                            xxl={undefined}
                            size={undefined}
                            className={undefined}
                          >
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="contactPerson"
                              >
                                Contact Person
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="contactPerson"
                                {...register("contactPerson", {
                                  required: "This field is required",
                                })}
                                className="form-control"
                                name="contactPerson"
                                required={true}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.contactPerson}
                                placeholder="Enter Contact Person"
                              />
                              {errors.contactPerson &&
                                typeof errors.contactPerson.message ===
                                  "string" && (
                                  <span className="invalid">
                                    {errors.contactPerson.message}
                                  </span>
                                )}
                            </div>
                          </Col>
                          <Col
                            md="6"
                            sm={undefined}
                            lg={undefined}
                            xxl={undefined}
                            size={undefined}
                            className={undefined}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="email">
                                Email<span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="email"
                                {...register("email", {
                                  required: "This field is required",
                                })}
                                className="form-control"
                                name="email"
                                required={true}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.emailAddress}
                                placeholder="Enter Email"
                              />
                              {errors.email &&
                                typeof errors.email.message === "string" && (
                                  <span className="invalid">
                                    {errors.email.message}
                                  </span>
                                )}
                            </div>
                          </Col>
                          <Col
                            md="6"
                            sm={undefined}
                            lg={undefined}
                            xxl={undefined}
                            size={undefined}
                            className={undefined}
                          >
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                Phone Number<span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="phoneNumber"
                                {...register("phoneNumber", {
                                  required: "This field is required",
                                })}
                                className="form-control"
                                name="phoneNumber"
                                required={true}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.phoneNumber}
                                placeholder="Enter Phone Number"
                              />
                              {errors.phoneNumber &&
                                typeof errors.phoneNumber.message ===
                                  "string" && (
                                  <span className="invalid">
                                    {errors.phoneNumber.message}
                                  </span>
                                )}
                            </div>
                          </Col>
                          <Col
                            md="6"
                            sm={undefined}
                            lg={undefined}
                            xxl={undefined}
                            size={undefined}
                            className={undefined}
                          >
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="mailingAddress"
                              >
                                Mailing Address
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="mailingAddress"
                                {...register("mailingAddress", {
                                  required: "This field is required",
                                })}
                                className="form-control"
                                name="mailingAddress"
                                required={true}
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.mailingAddress}
                                placeholder="Enter Mailing Address"
                              />
                              {errors.mailingAddress &&
                                typeof errors.mailingAddress.message ===
                                  "string" && (
                                  <span className="invalid">
                                    {errors.mailingAddress.message}
                                  </span>
                                )}
                            </div>
                          </Col>
                          <Col
                            size="12"
                            sm={undefined}
                            lg={undefined}
                            md={undefined}
                            xxl={undefined}
                            className={undefined}
                          >
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button
                                  color="primary"
                                  size="lg"
                                  onClick={() => submitUpdates()}
                                >
                                  Update
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setGIModal(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancel
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      {/* </form> */}
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "consultantProfile" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Consultant Profile </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="businessDescription"
                            >
                              Business Description
                            </label>
                            <input
                              type="text"
                              id="businessDescription"
                              className="form-control"
                              name="businessDescription"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.businessDescription}
                              placeholder="Enter Business Description"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="yearsInBusiness"
                            >
                              Years in Business
                            </label>
                            <input
                              type="text"
                              id="yearsInBusiness"
                              className="form-control"
                              name="yearsInBusiness"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.yearsInBusiness}
                              placeholder="Enter Years in Business"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="references">
                              References
                            </label>
                            <input
                              type="text"
                              id="otherReferences"
                              className="form-control"
                              name="otherReferences"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.otherReferences}
                              placeholder="Enter References"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "complianceCertifications" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Compliance and Certifications{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="regulatoryCompliance"
                              className="form-check-input"
                              name="regulatoryCompliance"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.regulatoryCompliance}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="regulatoryCompliance"
                            >
                              Regulatory Compliance
                            </label>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="licensesAndPermits"
                              className="form-check-input"
                              name="licensesAndPermits"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.licensesAndPermits}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="licensesAndPermits"
                            >
                              Licenses and Permits
                            </label>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="insuranceCoverage"
                              className="form-check-input"
                              name="insuranceCoverage"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.insuranceCoverage}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="insuranceCoverage"
                            >
                              Insurance Coverage
                            </label>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="mailingAddressLine1"
                            >
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ScopeWors" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Scope of Work </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="servicesOffered"
                            >
                              Services Offered
                            </label>
                            <input
                              type="text"
                              id="servicesOffered"
                              className="form-control"
                              name="servicesOffered"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.projectRequirements}
                              placeholder="Enter Services Offered"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="expertiseAreas"
                            >
                              Expertise Areas
                            </label>
                            <input
                              type="text"
                              id="expertiseAreas"
                              className="form-control"
                              name="expertiseAreas"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.expertiseAreas}
                              placeholder="Enter Expertise Areas"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}
              {modalEditBlockName === "projectExperience" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Project Experience</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="pastProjects"
                            >
                              Past Projects
                            </label>
                            <textarea
                              id="pastProjects"
                              className="form-control"
                              name="pastProjects"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.pastProjects}
                              placeholder="Enter Past Projects"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="clientTestimonials"
                            >
                              Client Testimonials
                            </label>
                            <textarea
                              id="clientTestimonials"
                              className="form-control"
                              name="clientTestimonials"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.clientTestimonials}
                              placeholder="Enter Client Testimonials"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ContractualAgreements" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contractual Agreements </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="contractTerms"
                            >
                              Contract Terms
                            </label>
                            <input
                              type="text"
                              id="contractTerms"
                              className="form-control"
                              name="contractTerms"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.contractTerms}
                              placeholder="Enter Contract Terms"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="insuranceRequirements"
                            >
                              Insurance Requirements
                            </label>
                            <input
                              type="textarea"
                              id="insuranceRequirements"
                              className="form-control"
                              name="insuranceRequirements"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.insuranceRequirements}
                              placeholder="Enter Insurance Requirements"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "projectAccessCoordination" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Project Access & Coordination</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="siteAccessProcedures"
                            >
                              Site Access Procedures
                            </label>
                            <input
                              type="text"
                              id="siteAccessProcedures"
                              className="form-control"
                              name="siteAccessProcedures"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.siteAccessProcedures}
                              placeholder="Enter Site Access Procedures"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="coordinationWithOtherContractors"
                            >
                              Coordination with Other Contractors
                            </label>
                            <input
                              type="text"
                              id="coordinationWithOtherContractors"
                              className="form-control"
                              name="coordinationWithOtherContractors"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={
                                formData.coordinationWithOtherContractors
                              }
                              placeholder="Enter Coordination with Other Contractors"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "BillingPaymentTerms" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Billing and Payment Terms</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="paymentTerms"
                            >
                              Payment Terms
                            </label>
                            <input
                              type="text"
                              id="paymentTerms"
                              className="form-control"
                              name="paymentTerms"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.paymentTerms}
                              placeholder="Enter Payment Terms"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="billingRates"
                            >
                              Billing Rates
                            </label>
                            <input
                              type="textarea"
                              id="billingRates"
                              className="form-control"
                              name="billingRates"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.billingRates}
                              placeholder="Enter Billing Rates"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName ===
                "EnvironmentalSustainabilityConsiderations" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Environmental and Sustainability Considerations{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="environmentalCompliance"
                              className="form-check-input"
                              name="environmentalCompliance"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.environmentalCompliance}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="environmentalCompliance"
                            >
                              Environmental Compliance
                            </label>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="safetyProtocols"
                              className="form-check-input"
                              name="safetyProtocols"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.safetyProtocols}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="safetyProtocols"
                            >
                              Safety Protocols
                            </label>
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload2">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload2"
                              className="form-control"
                              name="docsUpload2"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload2}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "EmergencyResponseContingencyPlans" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Emergency Response and Contingency Plans{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergencyContactPerson"
                            >
                              Contact Person
                            </label>
                            <input
                              type="text"
                              id="emergencyContactPerson"
                              className="form-control"
                              name="emergencyContactPerson"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.emergencyContactPerson}
                              placeholder="Enter Contact Person"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergencyContactNumber"
                            >
                              Emergency Contact Number
                            </label>
                            <input
                              type="text"
                              id="emergencyContactNumber"
                              className="form-control"
                              name="emergencyContactNumber"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.emergencyContactNumber}
                              placeholder="Enter Emergency Contact Number"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergencyProcedures"
                            >
                              Emergency Procedures
                            </label>
                            <input
                              type="text"
                              id="emergencyProcedures"
                              className="form-control"
                              name="emergencyProcedures"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.emergencyProcedures}
                              placeholder="Enter Emergency Procedures"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload3">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload3"
                              className="form-control"
                              name="docsUpload3"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload3}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ConflictResolution" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Conflict Resolution</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="disputeResolutionProcess"
                            >
                              Dispute Resolution Process
                            </label>
                            <input
                              type="text"
                              id="disputeResolutionProcess"
                              className="form-control"
                              name="disputeResolutionProcess"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.disputeResolutionProcess}
                              placeholder="Enter Dispute Resolution Process"
                            />
                          </div>
                        </Col>
                        <Col
                          md="6"
                          sm={undefined}
                          lg={undefined}
                          xxl={undefined}
                          size={undefined}
                          className={undefined}
                        >
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload5">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload5"
                              className="form-control"
                              name="docsUpload5"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload5}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col
                          size="12"
                          sm={undefined}
                          lg={undefined}
                          md={undefined}
                          xxl={undefined}
                          className={undefined}
                        >
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewConsultantDetails;
