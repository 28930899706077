import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Badge, ModalBody, Spinner } from "reactstrap";
import Head from "../../layout/head/Head";
import { Block, Icon } from "../../components/Component";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  TableColumnsType,
  Descriptions,
  List,
  Modal,
  Timeline,
  Popover,
  Empty,
  Tooltip,
  Card,
} from "antd";
import FormatDate from "../masters/DateFormater";
import supplierApis from "../../api/master/supplier";
import costCenterApis from "../../api/master/costCenter";
import sites from "../../api/master/sites";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import adminOrgApis from "../../api/master/adminOrg";
import {
  PaperClipOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderCreation from "../../api/purchase-orders/pruchaseOrderCreation";
import moment from "moment";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router";
import rolesApi from "../../api/master/roles";
import dayjs from "dayjs";
import usersApis from "../../api/master/users";
import budgetApis from "../../api/master/budget";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import creditDebitNoteApis from "../../api/credit-debit-note/credit-debit-note";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = AntCollapse;

const Preview: React.FC = (props: any) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const navigate = useNavigate();
  const [tab, setTab] = useState("1");
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const { watch, register } = useFormContext();

  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { control, handleSubmit } = useForm();
  const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
  const [currency_code, setCurrency_code] = useState<any>("INR");
  const [fields, setFields] = useState<any>([]);
  const [notes, setNotes] = useState<any>([]);
  const [noteIds, setNoteIds] = useState<any>([]);
  const [signature, setSignature] = useState<any>([]);
  const [totalCNAmount, setTotalCNAmount] = useState<any>(0);
  const [supplierAddress, setSupplierAddress] = useState<any>({});
  const [billingAddress, setBillingAddress] = useState<any>({});
  const [deliveryAddress, setDeliveryAddress] = useState<any>({});
  const previousFormData = watch();

  const columns: any = [
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
      render: (material: any) => <div className="align-left">{material}</div>,
    },
    {
      title: "Invoiced Qty",
      dataIndex: "quantityInvoiced",
      key: "quantityInvoiced",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = false;
        return (
          <div
            className="align-left"
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // light red color for highlighting
              color: isHighlighted ? "white" : "inherit", // optional: change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {Number(text) + " " + record?.uom}
          </div>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      editable: true,
      render: (text: any, record: any) =>
        text ? (
          <div className="align-left">{Number(text) + " " + record?.uom}</div>
        ) : (
          <></>
        ),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = Number(record?.orderPrice) < Number(text);
        return (
          <div
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // Highlight if orderPrice < unitPrice
              color: isHighlighted ? "white" : "inherit", // Optional: change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {formatCurrency2(Number(text), "INR")}
            <span>
              <Tooltip title={formatCurrency(Number(text), "INR")}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
  ];

  const onSubmit = async (data: any) => {
    setDisableSubmit(true);
    try {
      const formData: any = new FormData();
      let _filesData: any = [];

      const { signatureObj, instructions, termsAndConditions, attachments } =
        previousFormData;

      const signature = JSON.parse(signatureObj);
      const creditDebitNoteData: any = {
        signatureLabel: signature?.label,
        invoiceNumber:
          previousFormData?.againstInvoice == "Yes"
            ? previousFormData?.invoiceNumber
            : null,
        referenceNumber: previousFormData?.referenceNumber,
        noteType: previousFormData?.noteType,
        supplierId: Number(previousFormData?.supplierId),
        description: previousFormData?.description,
        remarks: previousFormData?.remarks,
        currencyId: 3,
        noteDate: dayjs(previousFormData?.noteDate)?.format("YYYY-MM-DD"),
        billingAddressDetails: [
          billingAddress?.deliveryLocationName,
          billingAddress?.addressLine1,
          billingAddress?.addressLine2,
          billingAddress?.city,
          billingAddress?.district,
          billingAddress?.state,
          "India",
          billingAddress?.pincode,
        ].join(", "),
        deliveryAddressDetails: [
          deliveryAddress?.deliveryLocationName,
          deliveryAddress?.addressLine1,
          deliveryAddress?.addressLine2,
          deliveryAddress?.city,
          deliveryAddress?.district,
          deliveryAddress?.state,
          "India",
          deliveryAddress?.pincode,
        ].join(", "),
        // billingAddressId:
        //   previousFormData?.againstInvoice == "Yes"
        //     ? Number(previousFormData?.invoiceBillingAddressId)
        //     : Number(previousFormData?.billingAddressId),
        autoConfirmed:
          previousFormData?.approvalRequired == "true" ? false : true,
        lineItems:
          previousFormData?.againstInvoice == "Yes"
            ? fields?.map((item: any) => {
                return {
                  invoiceLineNo: item?.lineNo,
                  quantity: item?.quantity,
                };
              })
            : [],
        jsonLineData:
          previousFormData?.againstInvoice == "Yes"
            ? []
            : noteIds?.map((id: any) => {
                return {
                  name: notes[`AdditionalCharges.${id}.name`],
                  noteAmount: Number(notes[`AdditionalCharges.${id}.price`]),
                };
              }),
      };
      if (previousFormData?.noteId) {
        creditDebitNoteData.noteId = previousFormData?.noteId;
      }
      if (signature?.image) {
        const image = base64ToFile(signature?.image, "signature.png");
        formData.append("files", image);
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "credit-debit-note",
          purpose: "signature",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      if (attachments) {
        attachments.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          const file = new File([attachment], attachment.name, {
            type: attachment.type,
            lastModified: attachment.lastModified,
          });
          formData.append(`files`, file);
          _filesData.push({
            indexOfFileLocated: _filesData.length,
            module: "credit-debit-note",
            purpose: "attachments",
            level: "Header Level",
            uniqueId: nanoid(4),
            action: "Create",
          });
        });
      }
      if (instructions) {
        const instructionsFile = new Blob([instructions], {
          type: "text/html",
        });
        formData.append(
          `files`,
          new File([instructionsFile], "instructions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "credit-debit-note",
          purpose: "instructions",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      if (termsAndConditions) {
        const termsAndConditionsFile = new Blob([termsAndConditions], {
          type: "text/html",
        });
        formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsandconditions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "credit-debit-note",
          purpose: "termsAndConditions",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      formData.append(
        "creditDebitNoteData",
        JSON.stringify(creditDebitNoteData)
      );
      formData.append("filesData", JSON.stringify(_filesData));
      postCN(formData);
    } catch (error) {
      console.log(error);
      setDisableSubmit(true);
    }
  };
  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }
  const postCN = async (_data: any) => {
    try {
      const response: any =
        await creditDebitNoteApis.creditDebitNoteCreationMethod(_data);
      const { status, message, data } = response;
      if (status) {
        Swal.fire("Created!", data, "success");
        navigate(`/all-credit-debit-notes`);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
    setDisableSubmit(true);
  };
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchSupplier = async () => {
    try {
      const res: any =
        await purchaseOrderCreation.fetchBuyerLinkedSupplierListMethod();
      const { data, status } = res;
      if (status) {
        let supplierOptions = data.map((item: any) => ({
          label: item?.sellerOrg?.name,
          value: item?.sellerOrg?.orgId?.toString(),
        }));
        setSupplierOptions(supplierOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("previousFormData", JSON.stringify(previousFormData));
    const {
      currencyCode,
      signatureObj,
      fields,
      notes,
      supplierAddress,
      billingAddress,
      deliveryAddress,
    } = previousFormData;
    if (currencyCode) {
      const _currency = currencyCode;
      setCurrency_code(_currency);
    }
    if (signatureObj) {
      const _signature = JSON.parse(signatureObj);
      setSignature(_signature);
    }
    if (fields) {
      const _fields = JSON.parse(fields);
      setFields(_fields);
    }
    if (notes) {
      const _notes = JSON.parse(notes);
      let _totalCNAmount = 0;
      const uniqueIds: any = [];
      Object.keys(_notes).forEach((key) => {
        const _key = key?.split(".")[1];
        const label = key?.split(".")[2];
        if (!uniqueIds.includes(_key)) {
          uniqueIds.push(_key);
        }
        if (label == "price") {
          _totalCNAmount += Number(_notes[key]);
        }
      });
      setTotalCNAmount(_totalCNAmount);
      setNotes(_notes);
      setNoteIds(uniqueIds);
    }
    if (supplierAddress) {
      const _supplierAddress = JSON.parse(supplierAddress);
      setSupplierAddress(_supplierAddress);
    }
    if (billingAddress) {
      const _billingAddress = JSON.parse(billingAddress);
      setBillingAddress(_billingAddress);
    }
    if (deliveryAddress) {
      const _deliveryAddress = JSON.parse(deliveryAddress);
      setDeliveryAddress(_deliveryAddress);
    }
    fetchSupplier();
  }, []);

  return (
    <>
      <Head title="New - Purchase Order " />
      <div className="content clearfix">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AntCollapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="General Information" key="1">
              <div className={`tab-pane active}`}>
                <Col className="justify-start w-100  flex-wrap mt-0 p-0">
                  <div className="w-30 border-end ">
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Reference #</div>
                      <div className="data-value w-70">
                        {previousFormData?.referenceNumber || "--"}
                      </div>
                    </div>
                    <div className="data-col align-center pt-1 ">
                      <div className="data-label w-30">Supplier</div>
                      <div className="data-value w-70 ">
                        {
                          supplierOptions.find(
                            (item) => item.value == previousFormData.supplierId
                          )?.label
                        }
                      </div>
                    </div>
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Description</div>
                      <div className="data-value w-70">
                        {previousFormData?.description || "--"}
                      </div>
                    </div>
                  </div>
                  <div className="w-30 border-end paddingLeft10  ">
                    <div className="data-col align-center pt-1 ">
                      <div className="data-label w-30">Note Type</div>
                      <div className="data-value w-70 ">
                        <Badge color="outline-success">
                          {previousFormData?.noteType || "--"}
                        </Badge>
                      </div>
                    </div>
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Note Date</div>
                      <div className="data-value w-70">
                        {previousFormData?.noteDate
                          ? dayjs(previousFormData?.noteDate).format(
                              "DD-MMM-YYYY"
                            )
                          : "--"}
                      </div>
                    </div>

                    <div className="data-col align-center pt-1">
                      <div className="data-label w-30">Approval Required</div>
                      <div className="data-value w-70">
                        {previousFormData?.approvalRequired == "true" ? (
                          <Badge color="outline-success">Yes</Badge>
                        ) : (
                          <Badge color="outline-danger">No</Badge>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-30 paddingLeft10">
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-35">Supplier Address</div>
                      <div className="data-value w-65">
                        <Popover
                          content={[
                            supplierAddress?.addressLine1,
                            supplierAddress?.addressLine2,
                            supplierAddress?.city,
                            supplierAddress?.districtName,
                            supplierAddress?.stateName,
                            supplierAddress?.countryName,
                            supplierAddress?.pincode,
                          ]
                            ?.filter((val) => (val ? true : false))
                            ?.join(", ")}
                          title="Supplier Address"
                        >
                          <span
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </span>
                        </Popover>
                      </div>
                    </div>
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-35">Billing Address</div>
                      <div className="data-value w-65">
                        <Popover
                          content={[
                            billingAddress?.deliveryLocationName,
                            billingAddress?.addressLine1,
                            billingAddress?.addressLine2,
                            billingAddress?.city,
                            billingAddress?.district,
                            billingAddress?.state,
                            "India",
                            billingAddress?.pincode,
                          ].join(", ")}
                          title={"Billing Address"}
                        >
                          <span
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </span>
                        </Popover>
                      </div>
                    </div>
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-35">Delivery Address</div>
                      <div className="data-value w-65">
                        <Popover
                          content={[
                            deliveryAddress?.deliveryLocationName,
                            deliveryAddress?.addressLine1,
                            deliveryAddress?.addressLine2,
                            deliveryAddress?.city,
                            deliveryAddress?.district,
                            deliveryAddress?.state,
                            "India",
                            deliveryAddress?.pincode,
                          ].join(", ")}
                          title={"Delivery Address"}
                        >
                          <span
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            View
                          </span>
                        </Popover>
                      </div>
                    </div>
                    <div className="data-col align-center pt-1">
                      <div className="data-label w-35">Remarks</div>
                      <div className="data-value w-65">
                        {previousFormData?.remarks || "--"}
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Panel>
            <Panel
              header={
                previousFormData?.againstInvoice == "Yes"
                  ? "Material Details"
                  : "Credit Note Details"
              }
              key="2"
            >
              <div className="table-responsive poTable">
                {previousFormData?.againstInvoice == "Yes" ? (
                  <div
                    style={{
                      width: screenSize - 170 + "px",
                      margin: "auto",
                    }}
                  >
                    <Table
                      className="customTable"
                      columns={columns?.map((col: any) => {
                        if (
                          [
                            "unitPrice",
                            "basicAmount",
                            "netAmount",
                            "grossTotal",
                          ].includes(col?.dataIndex)
                        ) {
                          return {
                            ...col,
                            render: (text: any, record: any) => {
                              return (
                                <div className="justify-end">
                                  {formatCurrency(Number(text), currency_code)}
                                </div>
                              );
                            },
                          };
                        }
                        if (col?.children?.length > 0) {
                          return {
                            ...col,
                            children: col?.children?.map((_col: any) => {
                              if (_col?.title == "Amount") {
                                return {
                                  ..._col,
                                  render: (text: any, record: any) => {
                                    return (
                                      <div className="justify-end">
                                        {formatCurrency(
                                          Number(text),
                                          currency_code
                                        )}
                                      </div>
                                    );
                                  },
                                };
                              }
                              return _col;
                            }),
                          };
                        }
                        if (!col.editable) {
                          return col;
                        }
                        return {
                          ...col,
                          onCell: (record: any) => ({
                            record,
                            inputType:
                              col.dataIndex === "quantity" ? "number" : "text",
                            dataIndex: col.dataIndex,
                            title: col.title,
                            editing: true,
                          }),
                        };
                      })}
                      dataSource={fields?.map((field: any) => {
                        return {
                          ...field,
                          key: field.lineNo,
                        };
                      })}
                      scroll={{ x: "max-content" }}
                      bordered
                      size="small"
                      pagination={false}
                    />
                  </div>
                ) : (
                  <Row className="m-2 mb-0 w-50">
                    <Descriptions
                      bordered
                      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      size="small"
                      labelStyle={{ fontWeight: "bold" }}
                      contentStyle={{ fontWeight: "normal" }}
                    >
                      <Descriptions.Item label="Line Name">
                        <strong> Amount</strong>
                      </Descriptions.Item>
                      {noteIds?.map((id: any) => {
                        return (
                          <>
                            <Descriptions.Item
                              label={notes[`AdditionalCharges.${id}.name`]}
                            >
                              {formatCurrency(
                                Number(notes[`AdditionalCharges.${id}.price`]),
                                currency_code
                              )}
                            </Descriptions.Item>
                          </>
                        );
                      })}
                      <Descriptions.Item label="Total Amount">
                        <strong>
                          {formatCurrency(Number(totalCNAmount), currency_code)}
                        </strong>
                      </Descriptions.Item>
                    </Descriptions>
                  </Row>
                )}

                <Row className="m-4 justify-content-end ">
                  {signature?.isActive && (
                    <div className="signatureBtnWrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>Signature</strong>
                      </div>
                      <div
                        style={{
                          minHeight: signature?.image ? "0px" : "120px",
                          display: signature?.image ? "block" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {signature?.image && (
                          <>
                            <div className="signatueImgWrapper border">
                              <img
                                src={signature?.image}
                                alt="Signed"
                                style={{ width: "160px" }}
                              />
                            </div>

                            <div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="signatureLabel"
                                >
                                  Signature Label
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="signatureLabel"
                                  onChange={(e) => {}}
                                  disabled
                                  defaultValue={
                                    signature?.label || "Authorised Signatory"
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </Panel>
            <Panel header="Other Information" key="3">
              <Descriptions title="" className="custom-description-title">
                <Descriptions.Item label="Instructions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setInstructionsVisible(true)}
                  >
                    View
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Terms and Conditions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setTermsVisible(true)}
                  >
                    View
                  </span>
                </Descriptions.Item>
                {previousFormData?.attachments?.length > 0 && (
                  <Descriptions.Item label="">
                    <div>
                      <div className="">
                        Attachments &nbsp;
                        <PaperClipOutlined />
                      </div>
                      <div>
                        {previousFormData.attachments?.map(
                          (attachment: any, index: number) => (
                            <div
                              key={index}
                              className="w-100 d-flex justify-content-between"
                            >
                              <div>
                                <strong>{index + 1}.</strong>
                                &nbsp;
                                {attachment.name}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </div>
                              <div>
                                <a
                                  href={URL.createObjectURL(
                                    attachment.originFileObj
                                  )}
                                  download={attachment.name}
                                  style={{ color: "black", fontSize: "18px" }}
                                >
                                  <DownloadOutlined />
                                </a>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
          </AntCollapse>
          <Modal
            title="Instructions"
            visible={instructionsVisible}
            footer={null}
            onCancel={() => setInstructionsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.instructions ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: previousFormData?.instructions,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No instructions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <Modal
            title="Terms and Conditions"
            visible={termsVisible}
            footer={null}
            onCancel={() => setTermsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.termsAndConditions ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: previousFormData?.termsAndConditions,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No terms and conditions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <div className="actions clearfix">
            <ul>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                )}
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default Preview;
