import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface PurchaseRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const BudgetAside: FC<PurchaseRequestAsideProps> = ({ updateSm, sm }) => {
    const location = useLocation();
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "analytics-budget":
                return (
                    <Link to={"/analytics-budget"} className={isActive(`${process.env.PUBLIC_URL}/analytics-budget`)}>
                        <Icon name="growth-fill" />
                        <span>Analytics</span>
                    </Link>
                );
            case "new-budget":
                return (
                    <Link to="/new-budget" className={isActive(`${process.env.PUBLIC_URL}/new-budget`)}>
                        <Icon name="plus-fill-c" />
                        <span>Create Budget</span>
                    </Link>
                );
            case "pendingApproval-budget":
                return (
                    <Link to="/pendingApproval-budget" className={isActive(`${process.env.PUBLIC_URL}/pendingApproval-budget`)}>
                        <Icon name="check-fill-c" />
                        <span>Pending Approvals</span>
                    </Link>
                );
            case "view-budget":
                return (
                    <Link to="/view-budget" className={isActive(`${process.env.PUBLIC_URL}/view-budget`)}>
                        <Icon name="eye-fill" />
                        <span>View</span>
                    </Link>
                );
            // Add more cases if needed for other routes
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>Budget</h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "analytics-budget",
                        "new-budget",
                        "pendingApproval-budget",
                        "view-budget",
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default BudgetAside;
