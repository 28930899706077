import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const menuDataSlice = createSlice({
  name: "menuData",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateMenuData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    clearMenu: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
});


export const { updateMenuData, clearMenu} = menuDataSlice.actions;

export default menuDataSlice.reducer;
