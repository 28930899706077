import React, { useState } from "react";
import { Table, Button, Drawer, Avatar, Tooltip, Empty } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getUserLabel,
  formatCurrency,
  formatCurrency2,
} from "../../../utils/Utils"; // Assuming these utilities are available
import { render } from "@testing-library/react";

interface PaymentRecord {
  supplierID: string;
  supplierName: string;
  amount: number;
  dueInDays: number;
  currency: string;
}

const paymentData: PaymentRecord[] = [
  {
    supplierID: "Sup001",
    supplierName: "Alpha Supplies",
    amount: 150000,
    dueInDays: 1,
    currency: "INR",
  },
  {
    supplierID: "Sup002",
    supplierName: "Beta Traders",
    amount: 250000,
    dueInDays: 2,
    currency: "INR",
  },
  {
    supplierID: "Sup003",
    supplierName: "Gamma Corp",
    amount: 50000,
    dueInDays: 1,
    currency: "INR",
  },
  {
    supplierID: "Sup004",
    supplierName: "Delta Industries",
    amount: 100000,
    dueInDays: 2,
    currency: "INR",
  },
  {
    supplierID: "Sup005",
    supplierName: "Epsilon Enterprises",
    amount: 75000,
    dueInDays: 1,
    currency: "INR",
  },
  {
    supplierID: "Sup006",
    supplierName: "Zeta Supplies",
    amount: 180000,
    dueInDays: 2,
    currency: "INR",
  },
  {
    supplierID: "Sup007",
    supplierName: "Eta Traders",
    amount: 120000,
    dueInDays: 1,
    currency: "INR",
  },
  {
    supplierID: "Sup008",
    supplierName: "Theta Corp",
    amount: 95000,
    dueInDays: 2,
    currency: "INR",
  },
  {
    supplierID: "Sup009",
    supplierName: "Iota Industries",
    amount: 65000,
    dueInDays: 1,
    currency: "INR",
  },
  {
    supplierID: "Sup010",
    supplierName: "Kappa Enterprises",
    amount: 130000,
    dueInDays: 2,
    currency: "INR",
  },
];

const ImmediatePayments: React.FC<any> = ({ paymentData }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      render: (text: string, record: PaymentRecord) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{
              backgroundColor: "#E5E9F2",
              color: "#526484",
              marginRight: 8,
            }}
          >
            {getUserLabel(record.supplierName)}
          </Avatar>
          {text}
        </div>
      ),
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber"
    },
    {
      title: "Amount",
      dataIndex: "dueInNext2DaysAmount",
      key: "dueInNext2DaysAmount",
      render: (value: number, record: PaymentRecord) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {formatCurrency2(Number(value), record.currency||"INR")}
          <Tooltip title={formatCurrency(Number(value), record.currency||"INR")}>
            <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Due In (Days)",
      dataIndex: "dueDays",
      key: "dueDays",
      render: (value: number) => <div>{Number(value)||"Today"}</div>,
    },
  ];

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Immediate Payments</h6>
          <p>Payments to be made in the next 2 days.</p>
        </div>
        {paymentData?.length > 0 ? (
          <Button type="link" onClick={showDrawer}>
            View More
          </Button>
        ) : (
          <></>
        )}
      </div>

      {paymentData?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={paymentData.slice(0, 5)}
          rowKey="supplierID"
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "" : "striped-row"
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Empty description="No Immediate Payments" />
        </div>
      )}
      <Drawer
        title="All Immediate Payments"
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        width={"60%"}
      >
        {paymentData?.length > 0 ? (
          <Table
            columns={columns}
            dataSource={paymentData}
            rowKey="supplierID"
            pagination={{ pageSize: 10 }}
            size="small"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "striped-row"
            }
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Immediate Payments" />
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default ImmediatePayments;
