import React from 'react'
import { useParams } from 'react-router';
import ViewOrderDetails from './ViewOrderDetails';

function PurchaseOrderApprovalsWrapper() {
    let { orderNumber }: any = useParams();
  return (
    <ViewOrderDetails orderNumber={orderNumber} previousPath="/approvals-purchase-order" />
  )
}

export default PurchaseOrderApprovalsWrapper