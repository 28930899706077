import React from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
  TooltipComponent,
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { useFormContext } from "react-hook-form";
const SiteStatus: React.FC = (props: any) => {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const siteDocuments = watch("siteDocuments");

  const CurrentStatusOptions = [
    { label: "Planned", value: "planned" },
    { label: "In Progress", value: "inprogress" },
    { label: "On Hold", value: "onhold" },
    { label: "Completed", value: "completed" },
  ];
  const submitForm = (data: any) => {
    console.log("General Info Data:", props);
    console.log("Site setUP:", data);
    props.next();
  };
  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          <Col lg="4">
            <FormDatePicker
              name="plannedStartDate"
              label="Planned Start Date"
              // minDate={new Date()} // Today's date as the minimum date
              // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Maximum date set to one year from today
              required={true}
              showYearDropdown={true}
              scrollableYearDropdown={true}
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name="plannedEndDate"
              label="Planned End Date"
              // minDate={new Date()} // Today's date as the minimum date
              // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Maximum date set to one year from today
              required={true}
              customValidation={{
                validate: (selectedDate: any) => {
                  const startDate = watch("plannedStartDate");
                  return (
                    (startDate && selectedDate > startDate) ||
                    "End date must be later than start date"
                  );
                },
              }}
              showYearDropdown={true}
              scrollableYearDropdown={true}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="currentStatus"
              label="Current Status"
              options={CurrentStatusOptions}
              required={true}
              placeholder="-Select-"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          {/* <Col lg="4">
            <FormInput
              name="siteDocuments"
              label="Site Document (if any)"
              type="file"
              placeholder="Enter Site Document (if any)"
            />
            {siteDocuments && <div>{siteDocuments[0]?.name}</div>}
          </Col> */}
           <Col lg="4">
            {siteDocuments && siteDocuments[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"siteDocuments"}>
                  Enter Site Document (if any)
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`siteDocuments-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{siteDocuments[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("siteDocuments", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${
                  siteDocuments && siteDocuments[0]?.name ? "none" : "block"
                }`,
              }}
            >
              <FormInput
                name="siteDocuments"
                label="Site Document"
                type="file"
                placeholder="site Document Upload"
              />
            </div>
          </Col>
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default SiteStatus;
