import React, { useContext, useMemo } from "react";
import { HolderOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key: string;
  MaterialCategory: string;
  materialName: string;
  hsnCode: string;
  quantity: string;
  uomId: string;
  unitPrice: string;
  amount: string;
  cgstPercentage: string;
  cgstAmount: string;
  sgstPercentage: string;
  sgstAmount: string;
  igstPercentage: string;
  igstAmount: string;
  needByDate?: string;
  attachment: string;
  shippingAddress: string;
  state?: string;
  address?: string;
  district?: string;
  city?: string;
  pincode?: string;
  contactPerson?: string;
  contactMobile?: string;
  contactMail?: string;
  cess?: string;
  description: string;
  lineTotal: string;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const columns: TableColumnsType<any> = [
  { key: "sort", align: "center", width: 80, render: () => <DragHandle /> },
{ key: "id", title: "id", dataIndex: "id", width: 80, align: "center" },
  {
    key: "MaterialCategory",
    title: "Material Category",
    dataIndex: "MaterialCategory",
    width: 200,
    align: "center",
  },
  { key: "materialName", title: "Material Name", dataIndex: "materialName", width: 200, align: "center" },
  { key: "hsnCode", title: "hsnCode", dataIndex: "hsnCode", width: 150, align: "center" },
  { key: "quantity", title: "quantity", dataIndex: "quantity", width: 150, align: "center" },
  { key: "uomId", title: "uomId", dataIndex: "uomId", width: 150, align: "center" },
  { key: "unitPrice", title: "unitPrice", dataIndex: "unitPrice", width: 150, align: "center" },
  { key: "amount", title: "amount", dataIndex: "amount", width: 150, align: "center" },
  // {
  //   key: "cgstPercentage",
  //   title: "cgstPercentage",
  //   width: 150,
  //   align: "center",
  // },
  // { key: "cgstAmount", title: "cgstAmount", width: 150, align: "center" },
  // {
  //   key: "sgstPercentage",
  //   title: "sgstPercentage",
  //   width: 150,
  //   align: "center",
  // },
  // { key: "sgstAmount", title: "sgstAmount", width: 150, align: "center" },
  // {
  //   key: "igstPercentage",
  //   title: "igstPercentage",
  //   width: 150,
  //   align: "center",
  // },
  // { key: "igstAmount", title: "igstAmount", width: 150, align: "center" },
  // { key: "needByDate", title: "needByDate", width: 150, align: "center" },
  // { key: "attachment", title: "attachment", width: 150, align: "center" },
  // {
  //   key: "shippingAddress",
  //   title: "shippingAddress",
  //   width: 200,
  //   align: "center",
  // },
  // { key: "state", title: "state", width: 150, align: "center" },
  // { key: "address", title: "address", width: 200, align: "center" },
  // { key: "district", title: "district", width: 150, align: "center" },
  // { key: "city", title: "city", width: 150, align: "center" },
  // { key: "pincode", title: "pincode", width: 150, align: "center" },
  // { key: "contactPerson", title: "contactPerson", width: 150, align: "center" },
  // { key: "contactMobile", title: "contactMobile", width: 150, align: "center" },
  // { key: "contactMail", title: "contactMail", width: 150, align: "center" },
  // { key: "cess", title: "cess", width: 150, align: "center" },
  // { key: "description", title: "description", width: 200, align: "center" },
  // { key: "lineTotal", title: "lineTotal", width: 150, align: "center" },
];

const initialData: DataType[] = [
  {
    key: "1",
    MaterialCategory: "Material Category",
    materialName: "Material Name",
    hsnCode: "28938",
    quantity: "290",
    uomId: "3",
    unitPrice: "50000",
    amount: "606060606",
    cgstPercentage: "1",
    cgstAmount: "3920",
    sgstPercentage: "3",
    sgstAmount: "943340",
    igstPercentage: "0",
    igstAmount: "0",
    needByDate: "2022-02-02",
    attachment: "true",
    shippingAddress: "hyderabad, telangana, India",
    state: "Telangana",
    address: "ss reddy mens pg no 1",
    district: "ranga reddy",
    city: "Hyderabad",
    pincode: "5000081",
    contactPerson: "Diva",
    contactMobile: "75690093030",
    contactMail: "diva@h.com",
    cess: "Cess A",
    description: "kljs kasjj kalsjd ",
    lineTotal: "8893939",
  },
  {
    key: "2",
    MaterialCategory: "Material Category",
    materialName: "Material Name",
    hsnCode: "28938",
    quantity: "290",
    uomId: "3",
    unitPrice: "50000",
    amount: "606060606",
    cgstPercentage: "1",
    cgstAmount: "3920",
    sgstPercentage: "3",
    sgstAmount: "943340",
    igstPercentage: "0",
    igstAmount: "0",
    needByDate: "2022-02-02",
    attachment: "true",
    shippingAddress: "hyderabad, telangana, India",
    state: "Telangana",
    address: "ss reddy mens pg no 1",
    district: "ranga reddy",
    city: "Hyderabad",
    pincode: "5000081",
    contactPerson: "Diva",
    contactMobile: "75690093030",
    contactMail: "diva@h.com",
    cess: "Cess A",
    description: "kljs kasjj kalsjd ",
    lineTotal: "8893939",
  },
  {
    key: "3",
    MaterialCategory: "Material Category",
    materialName: "Material Name",
    hsnCode: "28938",
    quantity: "290",
    uomId: "3",
    unitPrice: "50000",
    amount: "606060606",
    cgstPercentage: "1",
    cgstAmount: "3920",
    sgstPercentage: "3",
    sgstAmount: "943340",
    igstPercentage: "0",
    igstAmount: "0",
    needByDate: "2022-02-02",
    attachment: "true",
    shippingAddress: "hyderabad, telangana, India",
    state: "Telangana",
    address: "ss reddy mens pg no 1",
    district: "ranga reddy",
    city: "Hyderabad",
    pincode: "5000081",
    contactPerson: "Diva",
    contactMobile: "75690093030",
    contactMail: "diva@h.com",
    cess: "Cess A",
    description: "kljs kasjj kalsjd ",
    lineTotal: "8893939",
  },
  {
    key: "4",
    MaterialCategory: "Material Category",
    materialName: "Material Name",
    hsnCode: "28938",
    quantity: "290",
    uomId: "3",
    unitPrice: "50000",
    amount: "606060606",
    cgstPercentage: "1",
    cgstAmount: "3920",
    sgstPercentage: "3",
    sgstAmount: "943340",
    igstPercentage: "0",
    igstAmount: "0",
    needByDate: "2022-02-02",
    attachment: "true",
    shippingAddress: "hyderabad, telangana, India",
    state: "Telangana",
    address: "ss reddy mens pg no 1",
    district: "ranga reddy",
    city: "Hyderabad",
    pincode: "5000081",
    contactPerson: "Diva",
    contactMobile: "75690093030",
    contactMail: "diva@h.com",
    cess: "Cess A",
    description: "kljs kasjj kalsjd ",
    lineTotal: "8893939",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const PurchaseOrderAnalytics: React.FC = () => {
  const [dataSource, setDataSource] = React.useState<DataType[]>(initialData);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    console.log(active, over);
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key == active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key == over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  return (
    <div
      style={{
        marginTop: "100px",
      }}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="key"
            components={{ body: { row: Row } }}
            columns={columns}
            dataSource={dataSource}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default PurchaseOrderAnalytics;
