import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Button } from 'antd';
import SupplierOverdueDrawer from './SupplierOverdueDrawer';
const overdueData = [
    { month: 'January', year: '2023', percentage: 5 },
    { month: 'February', year: '2023', percentage: 8 },
    { month: 'March', year: '2023', percentage: 12 },
    { month: 'April', year: '2023', percentage: 6 },
    { month: 'May', year: '2023', percentage: 9 },
    { month: 'June', year: '2023', percentage: 11 },
    { month: 'July', year: '2023', percentage: 15 },
    { month: 'August', year: '2023', percentage: 10 },
    { month: 'September', year: '2023', percentage: 13 },
    { month: 'October', year: '2023', percentage: 7 },
    { month: 'November', year: '2023', percentage: 14 },
    { month: 'December', year: '2023', percentage: 9 },
];

const MonthOnMonthOverdue: React.FC<any> = ({overdueData,monthOnMonthOverDueAgingSupplierWishList}:any) => {
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };
    useEffect(() => {
        const chartDom = document.getElementById('month-on-month-overdue-chart') as HTMLElement;
        if (chartDom) {
            const myChart = echarts.init(chartDom);

            const option = {
                grid: {
                    left: '100px',
                    right: '10px',

                    bottom: '70px'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}: {c}%',
                },
                xAxis: {
                    type: 'category',
                    name: "Month",
                    data: overdueData?.map((item: any) => `${item.month_name}\n${item.year}`),
                    axisLabel: {
                        interval: 0,
                    },
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 40,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: 'Overdue Percentage',
                    axisLabel: {
                        formatter: '{value}%',
                    },
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 60,
                    },
                    position: 'left',
                },
                series: [
                    {
                        name: 'Overdue Percentage',
                        type: 'line',
                        data: overdueData?.map((item: any)=> item.percentage),
                        markPoint: {
                            data: overdueData?.map((item:any)=> ({
                                name: `${item.month_name} ${item.year}`,
                                value: `${item.percentage}%`,
                                xAxis: `${item.month_name}\n${item.year}`,
                                yAxis: item.percentage,
                            })),
                            label: {
                                color: '#fff', // Set pointer font color to white
                            },
                        },
                        lineStyle: {
                            color: '#6675FF',
                        },
                        itemStyle: {
                            color: '#6675FF',
                        },
                        symbolSize: 8,
                        symbol: 'circle',
                        label: {
                            color: '#fff', // Ensure all labels (markers) have white text
                        },
                    },
                ],
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    }, [overdueData]);

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Month on Month Overdue Analysis</h6>
                    <p>Analysis of overdue percentages for the last 12 months.</p>
                </div>
                <Button type="link" onClick={showDrawer}>
                    Click here to see overdues supplier-wise
                </Button>

            </div>
            <div id="month-on-month-overdue-chart" style={{ width: '100%', height: '400px' }} />
            <SupplierOverdueDrawer visible={drawerVisible} onClose={closeDrawer} supplierOverdueData={monthOnMonthOverDueAgingSupplierWishList} />
        </div>
    );
};

export default MonthOnMonthOverdue;
