import React, { useEffect, useState } from 'react';
import { Descriptions, Drawer, Popover, Table, TableColumnsType } from 'antd';
import quotationApis from '../../../../api/master/quotations';
import dayjs from 'dayjs';
import { Row } from 'reactstrap';
import { formatCurrency } from '../../../../utils/Utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';

interface DrawerComponentProps {
  visible: boolean;
  onClose: () => void;
  data: {
    supplierQuoteId: string;
    supplierID: string;
    supplierName: string;
    quotationId:any;
    siteName:any
  };
}

const BafoDrawerComponent: React.FC<DrawerComponentProps> = ({ visible, onClose, data }) => {

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    console.log("11111")
    getShortListBySupplierId()
  }, [])

  const getShortListBySupplierId = async () => {
    try {
      const { response, status } = await quotationApis.fetchShortListBySupplierId(data.quotationId, data.supplierID);
      console.log(status)
      if (status == true) {
        console.log("respon.data.suppliers", response)
        setTableData(response.data.suppliers)
      }
    }
    catch {

    }
  }

  const columns = [
    {
      title: 'Material Name',
      dataIndex: 'materialName',
      key: 'materialName',
    },
    {
      title: 'Asked Quantity',
      dataIndex: 'requiredQty',
      key: 'requiredQty',
      render: (text: any) => `${Number(text.toFixed(2))}`,
    },
    {
      title: 'Awarded Quantity',
      dataIndex: 'awardedQty',
      key: 'awardedQty',
    },
    {
      title: 'Reason',
      dataIndex: 'shortListReason',
      key: 'shortListReason',
    },
    {
      title: 'Awarded Qty %',
      dataIndex: 'awardedPercentage',
      key: 'awardedPercentage',
      render: (text: any) => `${text.toFixed(0)}%`,
    },
    {
      title: 'Initial Quotation',
      dataIndex: 'initialPrice',
      key: 'initialPrice',
      render: (text: any) => formatCurrency(text, 'INR'),
    },
    {
      title: 'Best Quotation',
      dataIndex: 'price',
      key: 'price',
      render: (text: any) => formatCurrency(text, 'INR'),
    },
    {
      title: 'Taxes',
      dataIndex: 'tax',
      key: 'tax',
      render: (text: any, record: any) => (
        <>
          {formatCurrency(text, 'INR')}
          <Popover
            content={
              <div>
                <p>CGST: {record.cgst || 0}%</p>
                <p>SGST: {record.sgst || 0}%</p>
                <p>IGST: {record.igst || 0}%</p>
              </div>
            }
            title="Tax Breakdown"
          >
            <InfoCircleOutlined style={{ marginLeft: 8 }} />
          </Popover>
        </>
      ),
    },
    {
      title: 'Line Total',
      dataIndex: 'lineTotal',
      key: 'lineTotal',
      render: (text: any) => formatCurrency(text, 'INR'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <Drawer
      title={`${data.supplierName} - Awarded Materials`}
      placement="right"
      width="80%"
      onClose={onClose}
      open={visible}
    >
      {/* Drawer content goes here */}

      <Descriptions column={5} style={{ flex: 1, marginLeft: ' 10px' }}>
        <Descriptions.Item label="RFQ ID">{data.supplierQuoteId}</Descriptions.Item>
        <Descriptions.Item label="Site Name">{data.siteName}</Descriptions.Item>
      </Descriptions>


      <div>
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "auto" }}
          bordered
          size="small"
          pagination={false}

        />
      </div>

    </Drawer>
  );
};

export default BafoDrawerComponent;
