import React, { useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Col,
    RSelect,
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    BlockBetween,
    Button,
    Icon
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Header from "./Header";
import ProductInformation from "./ProductInformation";
import InventoryInformation from "./InventoryInformation";
import ImagesDocuments from "./ImagesDocuments";
import PreferredSuppliersInformation from "./PreferredSuppliersInformation";

const config = {
    before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const AddMaterial: React.FC = (props: any) => {
    const navigate = useNavigate();
    const formMethods = useForm({
        mode: "onChange",
        defaultValues: {
            suppliers: [
              {
                supplierID: "",
                supplierName: "",
                currency: null,
                averageLeadDays: "",
              },
            ],
          },
    });
    useEffect(() => {
    }, []);

    const backList = () => {
        navigate(`/materails`);

    }

    return (

        <React.Fragment>
            <Head title="Regular Page v-1" />
            <Content>
                <BlockHead size="lg">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h4">Create Materials</BlockTitle>
                            <BlockDes>Onboard materials platform, collecting essential information to create materials profiles.</BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button color="primary" className="btn-dim" onClick={() => backList()}>
                                <Icon name="menu-alt-r"></Icon>
                                <span>List of materials</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <PreviewCard className={'cardBox'} bodyClass="card-inner-xl">
                        {/* <div className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix"> */}
                            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix"> 
                            <FormProvider {...formMethods}>
                                <Steps config={config}>
                                    <Step component={ProductInformation} />
                                    <Step component={PreferredSuppliersInformation} />
                                    <Step component={InventoryInformation} /> 
                                    <Step component={ImagesDocuments} /> 
                                </Steps>
                            </FormProvider>
                        </div>
                    </PreviewCard>
                </Block>
            </Content>
        </React.Fragment>
    );
};
export default AddMaterial;
