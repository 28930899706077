import React from "react";
const RFQHeader: React.FC = (props: any) => {
    return (
        <>
           
           <div className="steps clearfix">
                <ul>
                    <li className={props.current >= 1 ? "first done" : "first"}>
                        <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                            <span className="number">01</span> <h5>General Information</h5>
                        </a>
                    </li>
                    <li className={props.current >= 2 ? "done" : ""}>
                        <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">02</span> <h5>Materials/Service Details</h5>
                        </a>
                    </li>
                    <li className={props.current >= 3 ? "done" : ""}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">03</span> <h5>Other Details</h5>
                        </a>
                    </li>
                    <li className={props.current >= 4 ? "done" : ""}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">04</span> <h5>Preview &amp; Submit</h5>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};
export default RFQHeader;
